/* =====================================
My Account Style */

.signup-sec {
	@extend .overly;

	&::before {
		width: 45%;

		@include media-breakpoint-down(md) {
			width: 100%;
			height: 46%;
		}

		@include media-breakpoint-down(sm) {
			height: 37%;
		}
	}

	.anim-img {
		@include position('center');
		width: 6.5625rem;
		height: 4.5rem;

	}

	.signup-thumb {
		@include media-breakpoint-up(xl) {
			margin-left: -20%;
		}
	}
}

.login-form {
	max-width: 32.5rem;
	margin-left: auto;
	margin-right: 0;
	@extend .overly;

	&::before {
		background: url('../images/award-1.png')no-repeat left top;
		left: -25%;
		top: -1.25rem;
		@include opacity(.5);
	}

	&::after {
		position: absolute;
		content: "";
		background: url('../images/award-2.png')no-repeat right bottom;
		width: 7.875rem;
		height: 9.6875rem;
		bottom: -8%;
		right: -21%;
		z-index: -1;
		@include opacity(.5);

	}

	.form-group {
		margin-bottom: 0.9375rem;

		input {
			padding-left: 3.125rem;
		}

		span {
			@include position('vertical');
			left: 1.5625rem;
			color: $mute;
		}

		@include media-breakpoint-down(sm) {
			input {
				padding-left: 2.5rem;
			}

			span {
				left: 0.9375rem;
			}
		}
	}

	.form-footer {
		.btn {
			margin-bottom: 0.9375rem;
			&:hover::before {
				display: none;
			}
			img {
				margin-right: 0.625rem;
			}
		}

		>p {
			margin-top: 1.25rem;
		}
	}

	@include media-breakpoint-only(xl) {

		&::before {
			left: -15%;
		}
	}

	@include media-breakpoint-only(lg) {
		max-width: 26.25rem;

		&::before {
			left: -22%;
		}
	}

	@include media-breakpoint-only(md) {
		max-width: 21.25rem;

		&::before {
			left: -11%;
			top: -6.25rem;
		}
	}

	@include media-breakpoint-down(md) {
		margin-top: 5rem;
	}
}

.signup-form {
	@extend .login-form;

	&::before {
		background: url('../images/shape-5.png')no-repeat left top;
	}

	&::after {
		position: absolute;
		content: "";
		background: url('../images/shape-3.png')no-repeat right bottom / contain;
		width: 9.5625rem;
	}

}

.alter.overly {
	text-align: center;
	padding: 0.3125rem 0;

	&::before {
		height: 0.0625rem;
		top: 1.125rem;
		background-color: $border-color;
	}

	p {
		display: inline-block;
		background: #fff;
		padding: 0 0.9375rem;
	}
}

.error-txt {
	max-width: 39.375rem;
	margin: 1.875rem auto 0;
}