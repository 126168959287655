/*********************************************************************************

	Template Name: Eduxo || Online Course and Education HTML Template
	Description: A perfect Template For Online Courses, LMS, and education startups. It comes with nice and clean design.

	Note: This is style css (Sass).

**********************************************************************************/
/**************************************************************

	STYLESHEET INDEXING
	| 
	|___ Default Styles
	|	|___ bs-custom
	|	|___ mixins
	|	|___ typography
	|	|___ theme
	|	|___ header
	|	|___ footer
	|	|___ swiper slider
	|	|___ widget
	|
	|
	|___Sections Styles
	|	|___ anner
	|	|___ testimonial
	|	|___ instructor
	|	|___ choose
	|	|___ blog
	|	|___ brand
	|	|___ blog
	|	|___ counter
	|	|___ pricing
	|	|___ feature
	|	|___ process
	|	|___ contact
	|	|___ accordion
	|	|___ shop
	|	|___ cart
	|	|___ checkout
	|	|___ my-account
	|	|___ lesson
	|	|___ dashboard
	|	|___ event
	|	|___ inner

	|___ END STYLESHEET INDEXING

***************************************************************/
/*=================================
Bootstrap Cusotmization
*/
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600&family=Lexend:wght@500;600;700&display=swap");
:root {
  --bs-light-rgb: #6045FF;
  --bs-bg-opacity: 0.1; }

.bg-light {
  --bs-bg-opacity: 0.1 !important;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

h1 a, .h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
.product-details .product-price a,
h5 a,
.h5 a,
.course-entry-2 .card-body .course-footer .price a,
h6 a,
.h6 a,
.payment-option .accordion-button a,
.course-tab ul.nav li button a {
  color: #040210;
  text-decoration: none; }
  h1 a:hover, .h1 a:hover,
  h2 a:hover,
  .h2 a:hover,
  h3 a:hover,
  .h3 a:hover,
  h4 a:hover,
  .h4 a:hover,
  .product-details .product-price a:hover,
  h5 a:hover,
  .h5 a:hover,
  .course-entry-2 .card-body .course-footer .price a:hover,
  h6 a:hover,
  .h6 a:hover,
  .payment-option .accordion-button a:hover,
  .course-tab ul.nav li button a:hover {
    color: #6045FF; }

/*!
   * Bootstrap  v5.3.1 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #1D1354;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #6045FF;
  --bs-secondary: #FFA03A;
  --bs-success: #35CC6C;
  --bs-info: #fff;
  --bs-shade: #F4F5F8;
  --bs-light: rgba(96, 69, 255, 0.1);
  --bs-danger: #f30d0d;
  --bs-dark: #040210;
  --bs-mute: rgba(114, 114, 114, 0.5);
  --bs-tertiary: #FE5900;
  --bs-blue: #1D1354;
  --bs-body: #727272;
  --bs-alt-blue: #040210;
  --bs-primary-rgb: 96, 69, 255;
  --bs-secondary-rgb: 255, 160, 58;
  --bs-success-rgb: 53, 204, 108;
  --bs-info-rgb: 255, 255, 255;
  --bs-shade-rgb: 244, 245, 248;
  --bs-light-rgb: 96, 69, 255;
  --bs-danger-rgb: 243, 13, 13;
  --bs-dark-rgb: 4, 2, 16;
  --bs-mute-rgb: 114, 114, 114;
  --bs-tertiary-rgb: 254, 89, 0;
  --bs-blue-rgb: 29, 19, 84;
  --bs-body-rgb: 114, 114, 114;
  --bs-alt-blue-rgb: 4, 2, 16;
  --bs-primary-text-emphasis: #261c66;
  --bs-secondary-text-emphasis: #664017;
  --bs-success-text-emphasis: #15522b;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #dfdaff;
  --bs-secondary-bg-subtle: #ffecd8;
  --bs-success-bg-subtle: #d7f5e2;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #bfb5ff;
  --bs-secondary-border-subtle: #ffd9b0;
  --bs-success-border-subtle: #aeebc4;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Hind", sans-serif;
  --bs-body-font-size: 1.125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #727272;
  --bs-body-color-rgb: 114, 114, 114;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(114, 114, 114, 0.75);
  --bs-secondary-color-rgb: 114, 114, 114;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(114, 114, 114, 0.5);
  --bs-tertiary-color-rgb: 114, 114, 114;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #040210;
  --bs-link-color: #6045FF;
  --bs-link-color-rgb: 96, 69, 255;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #4d37cc;
  --bs-link-hover-color-rgb: 77, 55, 204;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E5E7ED;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 20px 20px 70px -8px rgba(4, 2, 16, 0.1);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(96, 69, 255, 0.25);
  --bs-form-valid-color: #35CC6C;
  --bs-form-valid-border-color: #35CC6C;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545; }

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #a08fff;
  --bs-secondary-text-emphasis: #ffc689;
  --bs-success-text-emphasis: #86e0a7;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #130e33;
  --bs-secondary-bg-subtle: #33200c;
  --bs-success-bg-subtle: #0b2916;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #3a2999;
  --bs-secondary-border-subtle: #996023;
  --bs-success-border-subtle: #207a41;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #a08fff;
  --bs-link-hover-color: #b3a5ff;
  --bs-link-color-rgb: 160, 143, 255;
  --bs-link-hover-color-rgb: 179, 165, 255;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, .product-details .product-price, h5, .h5, .course-entry-2 .card-body .course-footer .price, h6, .h6, .payment-option .accordion-button, .course-tab ul.nav li button {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Lexend", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  color: var(--bs-heading-color); }

h1, .h1 {
  font-size: calc(1.40625rem + 1.875vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.8125rem; } }

h2, .h2 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2.25rem; } }

h3, .h3 {
  font-size: calc(1.32188rem + 0.8625vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.96875rem; } }

h4, .h4, .product-details .product-price {
  font-size: calc(1.29375rem + 0.525vw); }
  @media (min-width: 1200px) {
    h4, .h4, .product-details .product-price {
      font-size: 1.6875rem; } }

h5, .h5, .course-entry-2 .card-body .course-footer .price {
  font-size: calc(1.26563rem + 0.1875vw); }
  @media (min-width: 1200px) {
    h5, .h5, .course-entry-2 .card-body .course-footer .price {
      font-size: 1.40625rem; } }

h6, .h6, .payment-option .accordion-button, .course-tab ul.nav li button {
  font-size: 1.125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small, .course-entry-3 .course-meta, .instructor-sec-2 .instructor-card p, .author-awards .award-stat p, .dashboard-sidebar .grettings {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline; }
  a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead, .lead-sm {
  font-size: calc(1.26563rem + 0.1875vw);
  font-weight: 300; }
  @media (min-width: 1200px) {
    .lead, .lead-sm {
      font-size: 1.40625rem; } }

.display-1 {
  font-size: calc(1.675rem + 5.1vw);
  font-weight: 500;
  line-height: 1.5; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5.5rem; } }

.display-2, .sec-title {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 500;
  line-height: 1.5; }
  @media (min-width: 1200px) {
    .display-2, .sec-title {
      font-size: 3.5rem; } }

.display-3 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  line-height: 1.5; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 2.5rem; } }

.display-4 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 500;
  line-height: 1.5; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 2rem; } }

.display-5, .sub-title, .widget-title, .product-details-tab ul.nav-tabs li .nav-link {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
  line-height: 1.5; }
  @media (min-width: 1200px) {
    .display-5, .sub-title, .widget-title, .product-details-tab ul.nav-tabs li .nav-link {
      font-size: 1.5rem; } }

.display-6, .ct-tab .nav-link {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.26563rem + 0.1875vw); }
  @media (min-width: 1200px) {
    .blockquote {
      font-size: 1.40625rem; } }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg); }

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dfdaff;
  --bs-table-border-color: #c9c4e6;
  --bs-table-striped-bg: #d4cff2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9c4e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cecaec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ffecd8;
  --bs-table-border-color: #e6d4c2;
  --bs-table-striped-bg: #f2e0cd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d4c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdac8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d7f5e2;
  --bs-table-border-color: #c2ddcb;
  --bs-table-striped-bg: #cce9d7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2ddcb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7e3d1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: rgba(96, 69, 255, 0.1);
  --bs-table-border-color: rgba(31, 22, 82, 0.19);
  --bs-table-striped-bg: rgba(48, 35, 128, 0.145);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(31, 22, 82, 0.19);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(38, 27, 100, 0.1675);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: calc(1.26563rem + 0.1875vw); }
  @media (min-width: 1200px) {
    .col-form-label-lg {
      font-size: 1.40625rem; } }

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.98438rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #b0a2ff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(96, 69, 255, 0.25); }
  .form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0; }
  .form-control::-webkit-datetime-edit {
    display: block;
    padding: 0; }
  .form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1; }
  .form-control:disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg); }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  border-radius: var(--bs-border-radius-sm); }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.26563rem + 0.1875vw);
  border-radius: var(--bs-border-radius-lg); }
  @media (min-width: 1200px) {
    .form-control-lg {
      font-size: 1.40625rem; } }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #b0a2ff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(96, 69, 255, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: var(--bs-secondary-bg); }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color); }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.98438rem;
  border-radius: var(--bs-border-radius-sm); }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.26563rem + 0.1875vw);
  border-radius: var(--bs-border-radius-lg); }
  @media (min-width: 1200px) {
    .form-select-lg {
      font-size: 1.40625rem; } }

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); }

.form-check {
  display: block;
  min-height: 1.6875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #b0a2ff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(96, 69, 255, 0.25); }
  .form-check-input:checked {
    background-color: #6045FF;
    border-color: #6045FF; }
    .form-check-input:checked[type="checkbox"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #6045FF;
    border-color: #6045FF;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b0a2ff'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e"); }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(96, 69, 255, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(96, 69, 255, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    appearance: none;
    background-color: #6045FF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #cfc7ff; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    appearance: none;
    background-color: #6045FF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #cfc7ff; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: var(--bs-secondary-color); }
    .form-range:disabled::-moz-range-thumb {
      background-color: var(--bs-secondary-color); }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
      position: absolute;
      inset: 1rem 0.375rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg);
      border-radius: var(--bs-border-radius); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: var(--bs-border-width) 0; }
  .form-floating > :disabled ~ label,
  .form-floating > .form-control:disabled ~ label {
    color: #6c757d; }
    .form-floating > :disabled ~ label::after,
    .form-floating > .form-control:disabled ~ label::after {
      background-color: var(--bs-secondary-bg); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.26563rem + 0.1875vw);
  border-radius: var(--bs-border-radius-lg); }
  @media (min-width: 1200px) {
    .input-group-lg > .form-control,
    .input-group-lg > .form-select,
    .input-group-lg > .input-group-text,
    .input-group-lg > .btn {
      font-size: 1.40625rem; } }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  border-radius: var(--bs-border-radius-sm); }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color); }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.98438rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2335CC6C' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2335CC6C' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: var(--bs-form-valid-color); }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--bs-form-valid-color); }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color); }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.98438rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: var(--bs-form-invalid-color); }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color); }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 2rem;
  --bs-btn-padding-y: 1.0625rem;
  --bs-btn-font-family: Hind, sans-serif;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6045FF;
  --bs-btn-border-color: #6045FF;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #523bd9;
  --bs-btn-hover-border-color: #4d37cc;
  --bs-btn-focus-shadow-rgb: 120, 97, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d37cc;
  --bs-btn-active-border-color: #4834bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6045FF;
  --bs-btn-disabled-border-color: #6045FF; }

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFA03A;
  --bs-btn-border-color: #FFA03A;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffae58;
  --bs-btn-hover-border-color: #ffaa4e;
  --bs-btn-focus-shadow-rgb: 217, 136, 49;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffb361;
  --bs-btn-active-border-color: #ffaa4e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFA03A;
  --bs-btn-disabled-border-color: #FFA03A; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #35CC6C;
  --bs-btn-border-color: #35CC6C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #53d482;
  --bs-btn-hover-border-color: #49d17b;
  --bs-btn-focus-shadow-rgb: 45, 173, 92;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #5dd689;
  --bs-btn-active-border-color: #49d17b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #35CC6C;
  --bs-btn-disabled-border-color: #35CC6C; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff; }

.btn-shade {
  --bs-btn-color: #000;
  --bs-btn-bg: #F4F5F8;
  --bs-btn-border-color: #F4F5F8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f6f7f9;
  --bs-btn-hover-border-color: #f5f6f9;
  --bs-btn-focus-shadow-rgb: 207, 208, 211;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6f7f9;
  --bs-btn-active-border-color: #f5f6f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F4F5F8;
  --bs-btn-disabled-border-color: #F4F5F8; }

.btn-light {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(96, 69, 255, 0.1);
  --bs-btn-border-color: rgba(96, 69, 255, 0.1);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(22, 16, 59, 0.235);
  --bs-btn-hover-border-color: rgba(17, 12, 44, 0.28);
  --bs-btn-focus-shadow-rgb: 218, 212, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(17, 12, 44, 0.28);
  --bs-btn-active-border-color: rgba(13, 9, 35, 0.325);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgba(96, 69, 255, 0.1);
  --bs-btn-disabled-border-color: rgba(96, 69, 255, 0.1); }

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #f30d0d;
  --bs-btn-border-color: #f30d0d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f53131;
  --bs-btn-hover-border-color: #f42525;
  --bs-btn-focus-shadow-rgb: 207, 11, 11;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f53d3d;
  --bs-btn-active-border-color: #f42525;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f30d0d;
  --bs-btn-disabled-border-color: #f30d0d; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #040210;
  --bs-btn-border-color: #040210;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2a2834;
  --bs-btn-hover-border-color: #1d1b28;
  --bs-btn-focus-shadow-rgb: 42, 40, 52;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #363540;
  --bs-btn-active-border-color: #1d1b28;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #040210;
  --bs-btn-disabled-border-color: #040210; }

.btn-mute {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(114, 114, 114, 0.5);
  --bs-btn-border-color: rgba(114, 114, 114, 0.5);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(75, 75, 75, 0.575);
  --bs-btn-hover-border-color: rgba(65, 65, 65, 0.6);
  --bs-btn-focus-shadow-rgb: 163, 163, 163;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(65, 65, 65, 0.6);
  --bs-btn-active-border-color: rgba(57, 57, 57, 0.625);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgba(114, 114, 114, 0.5);
  --bs-btn-disabled-border-color: rgba(114, 114, 114, 0.5); }

.btn-tertiary {
  --bs-btn-color: #000;
  --bs-btn-bg: #FE5900;
  --bs-btn-border-color: #FE5900;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fe7226;
  --bs-btn-hover-border-color: #fe6a1a;
  --bs-btn-focus-shadow-rgb: 216, 76, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fe7a33;
  --bs-btn-active-border-color: #fe6a1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FE5900;
  --bs-btn-disabled-border-color: #FE5900; }

.btn-blue {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1D1354;
  --bs-btn-border-color: #1D1354;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #191047;
  --bs-btn-hover-border-color: #170f43;
  --bs-btn-focus-shadow-rgb: 63, 54, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #170f43;
  --bs-btn-active-border-color: #160e3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1D1354;
  --bs-btn-disabled-border-color: #1D1354; }

.btn-body {
  --bs-btn-color: #fff;
  --bs-btn-bg: #727272;
  --bs-btn-border-color: #727272;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #616161;
  --bs-btn-hover-border-color: #5b5b5b;
  --bs-btn-focus-shadow-rgb: 135, 135, 135;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5b5b5b;
  --bs-btn-active-border-color: #565656;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #727272;
  --bs-btn-disabled-border-color: #727272; }

.btn-alt-blue {
  --bs-btn-color: #fff;
  --bs-btn-bg: #040210;
  --bs-btn-border-color: #040210;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #03020e;
  --bs-btn-hover-border-color: #03020d;
  --bs-btn-focus-shadow-rgb: 42, 40, 52;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #03020d;
  --bs-btn-active-border-color: #03020c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #040210;
  --bs-btn-disabled-border-color: #040210; }

.btn-outline-primary {
  --bs-btn-color: #6045FF;
  --bs-btn-border-color: #6045FF;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6045FF;
  --bs-btn-hover-border-color: #6045FF;
  --bs-btn-focus-shadow-rgb: 96, 69, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6045FF;
  --bs-btn-active-border-color: #6045FF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6045FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6045FF;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #FFA03A;
  --bs-btn-border-color: #FFA03A;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFA03A;
  --bs-btn-hover-border-color: #FFA03A;
  --bs-btn-focus-shadow-rgb: 255, 160, 58;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFA03A;
  --bs-btn-active-border-color: #FFA03A;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFA03A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFA03A;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #35CC6C;
  --bs-btn-border-color: #35CC6C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #35CC6C;
  --bs-btn-hover-border-color: #35CC6C;
  --bs-btn-focus-shadow-rgb: 53, 204, 108;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #35CC6C;
  --bs-btn-active-border-color: #35CC6C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #35CC6C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #35CC6C;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none; }

.btn-outline-shade {
  --bs-btn-color: #F4F5F8;
  --bs-btn-border-color: #F4F5F8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F4F5F8;
  --bs-btn-hover-border-color: #F4F5F8;
  --bs-btn-focus-shadow-rgb: 244, 245, 248;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F4F5F8;
  --bs-btn-active-border-color: #F4F5F8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F4F5F8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F4F5F8;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: rgba(96, 69, 255, 0.1);
  --bs-btn-border-color: rgba(96, 69, 255, 0.1);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(96, 69, 255, 0.1);
  --bs-btn-hover-border-color: rgba(96, 69, 255, 0.1);
  --bs-btn-focus-shadow-rgb: 96, 69, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(96, 69, 255, 0.1);
  --bs-btn-active-border-color: rgba(96, 69, 255, 0.1);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(96, 69, 255, 0.1);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(96, 69, 255, 0.1);
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #f30d0d;
  --bs-btn-border-color: #f30d0d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f30d0d;
  --bs-btn-hover-border-color: #f30d0d;
  --bs-btn-focus-shadow-rgb: 243, 13, 13;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f30d0d;
  --bs-btn-active-border-color: #f30d0d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f30d0d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f30d0d;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #040210;
  --bs-btn-border-color: #040210;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #040210;
  --bs-btn-hover-border-color: #040210;
  --bs-btn-focus-shadow-rgb: 4, 2, 16;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #040210;
  --bs-btn-active-border-color: #040210;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #040210;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #040210;
  --bs-gradient: none; }

.btn-outline-mute {
  --bs-btn-color: rgba(114, 114, 114, 0.5);
  --bs-btn-border-color: rgba(114, 114, 114, 0.5);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(114, 114, 114, 0.5);
  --bs-btn-hover-border-color: rgba(114, 114, 114, 0.5);
  --bs-btn-focus-shadow-rgb: 114, 114, 114;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(114, 114, 114, 0.5);
  --bs-btn-active-border-color: rgba(114, 114, 114, 0.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(114, 114, 114, 0.5);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(114, 114, 114, 0.5);
  --bs-gradient: none; }

.btn-outline-tertiary {
  --bs-btn-color: #FE5900;
  --bs-btn-border-color: #FE5900;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FE5900;
  --bs-btn-hover-border-color: #FE5900;
  --bs-btn-focus-shadow-rgb: 254, 89, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FE5900;
  --bs-btn-active-border-color: #FE5900;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FE5900;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FE5900;
  --bs-gradient: none; }

.btn-outline-blue {
  --bs-btn-color: #1D1354;
  --bs-btn-border-color: #1D1354;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1D1354;
  --bs-btn-hover-border-color: #1D1354;
  --bs-btn-focus-shadow-rgb: 29, 19, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1D1354;
  --bs-btn-active-border-color: #1D1354;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1D1354;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1D1354;
  --bs-gradient: none; }

.btn-outline-body {
  --bs-btn-color: #727272;
  --bs-btn-border-color: #727272;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #727272;
  --bs-btn-hover-border-color: #727272;
  --bs-btn-focus-shadow-rgb: 114, 114, 114;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #727272;
  --bs-btn-active-border-color: #727272;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #727272;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #727272;
  --bs-gradient: none; }

.btn-outline-alt-blue {
  --bs-btn-color: #040210;
  --bs-btn-border-color: #040210;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #040210;
  --bs-btn-hover-border-color: #040210;
  --bs-btn-focus-shadow-rgb: 4, 2, 16;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #040210;
  --bs-btn-active-border-color: #040210;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #040210;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #040210;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 120, 97, 255;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.26563rem + 0.1875vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg); }
  @media (min-width: 1200px) {
    .btn-lg, .btn-group-lg > .btn {
      --bs-btn-font-size: 1.40625rem; } }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.98438rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm); }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1.125rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 20px 20px 70px -8px rgba(4, 2, 16, 0.1);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #6045FF;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.98438rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #6045FF;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: var(--bs-border-radius); }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: calc(var(--bs-border-width) * -1); }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1); }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(96, 69, 255, 0.25); }
  .nav-link.disabled, .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #6045FF; }
  .nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius); }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap); }
  .nav-underline .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--bs-nav-underline-border-width) solid transparent; }
    .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
      border-bottom-color: currentcolor; }
  .nav-underline .nav-link.active,
  .nav-underline .show > .nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: currentcolor; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.28906rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.40625rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.40625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28114, 114, 114, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0rem;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - 0rem);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color); }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23727272'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23261c66'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #b0a2ff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(96, 69, 255, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.125rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a08fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a08fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "||") /* rtl: var(--bs-breadcrumb-divider, "||") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(96, 69, 255, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #6045FF;
  --bs-pagination-active-border-color: #6045FF;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1); }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: calc(1.26563rem + 0.1875vw);
  --bs-pagination-border-radius: var(--bs-border-radius-lg); }
  @media (min-width: 1200px) {
    .pagination-lg {
      --bs-pagination-font-size: 1.40625rem; } }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.98438rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm); }

.badge, .badge-lg {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty, .badge-lg:empty {
    display: none; }

.btn .badge, .btn .badge-lg {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color); }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis); }

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis); }

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis); }

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis); }

.alert-shade {
  --bs-alert-color: var(--bs-shade-text-emphasis);
  --bs-alert-bg: var(--bs-shade-bg-subtle);
  --bs-alert-border-color: var(--bs-shade-border-subtle);
  --bs-alert-link-color: var(--bs-shade-text-emphasis); }

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis); }

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis); }

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis); }

.alert-mute {
  --bs-alert-color: var(--bs-mute-text-emphasis);
  --bs-alert-bg: var(--bs-mute-bg-subtle);
  --bs-alert-border-color: var(--bs-mute-border-subtle);
  --bs-alert-link-color: var(--bs-mute-text-emphasis); }

.alert-tertiary {
  --bs-alert-color: var(--bs-tertiary-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-text-emphasis); }

.alert-blue {
  --bs-alert-color: var(--bs-blue-text-emphasis);
  --bs-alert-bg: var(--bs-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-border-subtle);
  --bs-alert-link-color: var(--bs-blue-text-emphasis); }

.alert-body {
  --bs-alert-color: var(--bs-body-text-emphasis);
  --bs-alert-bg: var(--bs-body-bg-subtle);
  --bs-alert-border-color: var(--bs-body-border-subtle);
  --bs-alert-link-color: var(--bs-body-text-emphasis); }

.alert-alt-blue {
  --bs-alert-color: var(--bs-alt-blue-text-emphasis);
  --bs-alert-bg: var(--bs-alt-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-alt-blue-border-subtle);
  --bs-alert-link-color: var(--bs-alt-blue-text-emphasis); }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.84375rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #6045FF;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-stacked > .progress {
  overflow: visible; }

.progress-stacked > .progress > .progress-bar {
  width: 100%; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #6045FF;
  --bs-list-group-active-border-color: #6045FF;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis); }

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis); }

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis); }

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis); }

.list-group-item-shade {
  --bs-list-group-color: var(--bs-shade-text-emphasis);
  --bs-list-group-bg: var(--bs-shade-bg-subtle);
  --bs-list-group-border-color: var(--bs-shade-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-shade-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-shade-border-subtle);
  --bs-list-group-active-color: var(--bs-shade-bg-subtle);
  --bs-list-group-active-bg: var(--bs-shade-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-shade-text-emphasis); }

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis); }

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis); }

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis); }

.list-group-item-mute {
  --bs-list-group-color: var(--bs-mute-text-emphasis);
  --bs-list-group-bg: var(--bs-mute-bg-subtle);
  --bs-list-group-border-color: var(--bs-mute-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-mute-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-mute-border-subtle);
  --bs-list-group-active-color: var(--bs-mute-bg-subtle);
  --bs-list-group-active-bg: var(--bs-mute-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-mute-text-emphasis); }

.list-group-item-tertiary {
  --bs-list-group-color: var(--bs-tertiary-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-text-emphasis); }

.list-group-item-blue {
  --bs-list-group-color: var(--bs-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-text-emphasis); }

.list-group-item-body {
  --bs-list-group-color: var(--bs-body-text-emphasis);
  --bs-list-group-bg: var(--bs-body-bg-subtle);
  --bs-list-group-border-color: var(--bs-body-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-body-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-body-border-subtle);
  --bs-list-group-active-color: var(--bs-body-bg-subtle);
  --bs-list-group-active-bg: var(--bs-body-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-body-text-emphasis); }

.list-group-item-alt-blue {
  --bs-list-group-color: var(--bs-alt-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-alt-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-alt-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-alt-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-alt-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-alt-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-alt-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-alt-blue-text-emphasis); }

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(96, 69, 255, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity); }
  .btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity); }
  .btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity); }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity); }

.btn-close-white {
  filter: var(--bs-btn-close-white-filter); }

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 20px 20px 70px -8px rgba(4, 2, 16, 0.1); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.98438rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.98438rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 20px 20px 70px -8px rgba(4, 2, 16, 0.1);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1.125rem;
  --bs-popover-header-color: #040210;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000; }

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height); }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-shade {
  color: #000 !important;
  background-color: RGBA(var(--bs-shade-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #fff !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-mute {
  color: #fff !important;
  background-color: RGBA(var(--bs-mute-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-tertiary {
  color: #000 !important;
  background-color: RGBA(var(--bs-tertiary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-blue {
  color: #fff !important;
  background-color: RGBA(var(--bs-blue-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-body {
  color: #fff !important;
  background-color: RGBA(var(--bs-body-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-alt-blue {
  color: #fff !important;
  background-color: RGBA(var(--bs-alt-blue-rgb), var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-primary:hover, .link-primary:focus {
    color: RGBA(77, 55, 204, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(77, 55, 204, var(--bs-link-underline-opacity, 1)) !important; }

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: RGBA(255, 179, 97, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 179, 97, var(--bs-link-underline-opacity, 1)) !important; }

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-success:hover, .link-success:focus {
    color: RGBA(93, 214, 137, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(93, 214, 137, var(--bs-link-underline-opacity, 1)) !important; }

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-info:hover, .link-info:focus {
    color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important; }

.link-shade {
  color: RGBA(var(--bs-shade-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-shade-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-shade:hover, .link-shade:focus {
    color: RGBA(246, 247, 249, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(246, 247, 249, var(--bs-link-underline-opacity, 1)) !important; }

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-light:hover, .link-light:focus {
    color: RGBA(17, 12, 44, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(17, 12, 44, var(--bs-link-underline-opacity, 1)) !important; }

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-danger:hover, .link-danger:focus {
    color: RGBA(245, 61, 61, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(245, 61, 61, var(--bs-link-underline-opacity, 1)) !important; }

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-dark:hover, .link-dark:focus {
    color: RGBA(3, 2, 13, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(3, 2, 13, var(--bs-link-underline-opacity, 1)) !important; }

.link-mute {
  color: RGBA(var(--bs-mute-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-mute-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-mute:hover, .link-mute:focus {
    color: RGBA(65, 65, 65, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(65, 65, 65, var(--bs-link-underline-opacity, 1)) !important; }

.link-tertiary {
  color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-tertiary:hover, .link-tertiary:focus {
    color: RGBA(254, 122, 51, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(254, 122, 51, var(--bs-link-underline-opacity, 1)) !important; }

.link-blue {
  color: RGBA(var(--bs-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-blue:hover, .link-blue:focus {
    color: RGBA(23, 15, 67, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(23, 15, 67, var(--bs-link-underline-opacity, 1)) !important; }

.link-body {
  color: RGBA(var(--bs-body-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-body-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body:hover, .link-body:focus {
    color: RGBA(91, 91, 91, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(91, 91, 91, var(--bs-link-underline-opacity, 1)) !important; }

.link-alt-blue {
  color: RGBA(var(--bs-alt-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-alt-blue-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-alt-blue:hover, .link-alt-blue:focus {
    color: RGBA(3, 2, 13, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(3, 2, 13, var(--bs-link-underline-opacity, 1)) !important; }

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body-emphasis:hover, .link-body-emphasis:focus {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important; }

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color); }

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden; }
  .icon-link > .bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: 0.2s ease-in-out transform; }
    @media (prefers-reduced-motion: reduce) {
      .icon-link > .bi {
        transition: none; } }

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0)); }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }
  .visually-hidden:not(caption),
  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
    position: absolute !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.object-fit-contain {
  object-fit: contain !important; }

.object-fit-cover {
  object-fit: cover !important; }

.object-fit-fill {
  object-fit: fill !important; }

.object-fit-scale {
  object-fit: scale-down !important; }

.object-fit-none {
  object-fit: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-inline-grid {
  display: inline-grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow, .category-entry2:hover span.cat-icon, .pricing-card:hover .btn, .pricing-card.active .btn {
  box-shadow: 20px 20px 70px -8px rgba(4, 2, 16, 0.1) !important; }

.shadow-sm, .admin-menu, .course-entry-3:hover, .review-2 .swiper-slide-next .review-card, .speaker-entry:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg, .social-share.white a, .teacher-entry .teacher-socials li a {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-shade {
  --bs-focus-ring-color: rgba(var(--bs-shade-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-mute {
  --bs-focus-ring-color: rgba(var(--bs-mute-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-tertiary {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-blue {
  --bs-focus-ring-color: rgba(var(--bs-blue-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-body {
  --bs-focus-ring-color: rgba(var(--bs-body-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-alt-blue {
  --bs-focus-ring-color: rgba(var(--bs-alt-blue-rgb), var(--bs-focus-ring-opacity)); }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border, .instruct-form input,
.instruct-form textarea {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-shade {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-shade-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-mute {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-mute-rgb), var(--bs-border-opacity)) !important; }

.border-tertiary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-rgb), var(--bs-border-opacity)) !important; }

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important; }

.border-body {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-rgb), var(--bs-border-opacity)) !important; }

.border-alt-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-alt-blue-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important; }

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important; }

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important; }

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important; }

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important; }

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important; }

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important; }

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4, .post-comments .comment-entry {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.row-gap-0 {
  row-gap: 0 !important; }

.row-gap-1 {
  row-gap: 0.25rem !important; }

.row-gap-2 {
  row-gap: 0.5rem !important; }

.row-gap-3 {
  row-gap: 1rem !important; }

.row-gap-4 {
  row-gap: 1.5rem !important; }

.row-gap-5 {
  row-gap: 3rem !important; }

.column-gap-0 {
  column-gap: 0 !important; }

.column-gap-1 {
  column-gap: 0.25rem !important; }

.column-gap-2 {
  column-gap: 0.5rem !important; }

.column-gap-3 {
  column-gap: 1rem !important; }

.column-gap-4 {
  column-gap: 1.5rem !important; }

.column-gap-5 {
  column-gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.40625rem + 1.875vw) !important; }

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-3 {
  font-size: calc(1.32188rem + 0.8625vw) !important; }

.fs-4 {
  font-size: calc(1.29375rem + 0.525vw) !important; }

.fs-5 {
  font-size: calc(1.26563rem + 0.1875vw) !important; }

.fs-6 {
  font-size: 1.125rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap, .product-entry .product-content h3, .product-entry .product-content .h3 {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-shade {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-shade-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-mute, .dashboard-sec .course-entry-3 .single-progress h6, .dashboard-sec .course-entry-3 .single-progress .h6, .dashboard-sec .course-entry-3 .single-progress .payment-option .accordion-button, .payment-option .dashboard-sec .course-entry-3 .single-progress .accordion-button, .dashboard-sec .course-entry-3 .single-progress .course-tab ul.nav li button, .course-tab ul.nav li .dashboard-sec .course-entry-3 .single-progress button,
.dashboard-sec .course-entry-3 .single-progress span {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-mute-rgb), var(--bs-text-opacity)) !important; }

.text-tertiary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important; }

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-alt-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-alt-blue-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important; }

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important; }

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important; }

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important; }

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important; }

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important; }

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important; }

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important; }

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important; }

.link-opacity-10 {
  --bs-link-opacity: 0.1; }

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1; }

.link-opacity-25 {
  --bs-link-opacity: 0.25; }

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25; }

.link-opacity-50 {
  --bs-link-opacity: 0.5; }

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5; }

.link-opacity-75 {
  --bs-link-opacity: 0.75; }

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75; }

.link-opacity-100 {
  --bs-link-opacity: 1; }

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1; }

.link-offset-1 {
  text-underline-offset: 0.125em !important; }

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important; }

.link-offset-2 {
  text-underline-offset: 0.25em !important; }

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important; }

.link-offset-3 {
  text-underline-offset: 0.375em !important; }

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important; }

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-shade {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-shade-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-mute {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-mute-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-tertiary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-body {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-body-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-alt-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-alt-blue-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1; }

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-shade {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-shade-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-mute {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-mute-rgb), var(--bs-bg-opacity)) !important; }

.bg-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-rgb), var(--bs-bg-opacity)) !important; }

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-alt-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-alt-blue-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important; }

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important; }

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important; }

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important; }

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important; }

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important; }

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important; }

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1, .course-nav .nav li .nav-link {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2, .footer-widget.post-widget .recent-entry .entry-thumb, .footer-widget.post-widget .recent-entry .entry-thumb img, form.form.checkout-form .nice-select, .dashboard-sec .table thead tr {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-2 {
  z-index: 2 !important; }

.z-3 {
  z-index: 3 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .object-fit-sm-contain {
    object-fit: contain !important; }
  .object-fit-sm-cover {
    object-fit: cover !important; }
  .object-fit-sm-fill {
    object-fit: fill !important; }
  .object-fit-sm-scale {
    object-fit: scale-down !important; }
  .object-fit-sm-none {
    object-fit: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-inline-grid {
    display: inline-grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .row-gap-sm-0 {
    row-gap: 0 !important; }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important; }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important; }
  .row-gap-sm-3 {
    row-gap: 1rem !important; }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important; }
  .row-gap-sm-5 {
    row-gap: 3rem !important; }
  .column-gap-sm-0 {
    column-gap: 0 !important; }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important; }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important; }
  .column-gap-sm-3 {
    column-gap: 1rem !important; }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important; }
  .column-gap-sm-5 {
    column-gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .object-fit-md-contain {
    object-fit: contain !important; }
  .object-fit-md-cover {
    object-fit: cover !important; }
  .object-fit-md-fill {
    object-fit: fill !important; }
  .object-fit-md-scale {
    object-fit: scale-down !important; }
  .object-fit-md-none {
    object-fit: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-inline-grid {
    display: inline-grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .row-gap-md-0 {
    row-gap: 0 !important; }
  .row-gap-md-1 {
    row-gap: 0.25rem !important; }
  .row-gap-md-2 {
    row-gap: 0.5rem !important; }
  .row-gap-md-3 {
    row-gap: 1rem !important; }
  .row-gap-md-4 {
    row-gap: 1.5rem !important; }
  .row-gap-md-5 {
    row-gap: 3rem !important; }
  .column-gap-md-0 {
    column-gap: 0 !important; }
  .column-gap-md-1 {
    column-gap: 0.25rem !important; }
  .column-gap-md-2 {
    column-gap: 0.5rem !important; }
  .column-gap-md-3 {
    column-gap: 1rem !important; }
  .column-gap-md-4 {
    column-gap: 1.5rem !important; }
  .column-gap-md-5 {
    column-gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .object-fit-lg-contain {
    object-fit: contain !important; }
  .object-fit-lg-cover {
    object-fit: cover !important; }
  .object-fit-lg-fill {
    object-fit: fill !important; }
  .object-fit-lg-scale {
    object-fit: scale-down !important; }
  .object-fit-lg-none {
    object-fit: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-inline-grid {
    display: inline-grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .row-gap-lg-0 {
    row-gap: 0 !important; }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important; }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important; }
  .row-gap-lg-3 {
    row-gap: 1rem !important; }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important; }
  .row-gap-lg-5 {
    row-gap: 3rem !important; }
  .column-gap-lg-0 {
    column-gap: 0 !important; }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important; }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important; }
  .column-gap-lg-3 {
    column-gap: 1rem !important; }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important; }
  .column-gap-lg-5 {
    column-gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .object-fit-xl-contain {
    object-fit: contain !important; }
  .object-fit-xl-cover {
    object-fit: cover !important; }
  .object-fit-xl-fill {
    object-fit: fill !important; }
  .object-fit-xl-scale {
    object-fit: scale-down !important; }
  .object-fit-xl-none {
    object-fit: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-inline-grid {
    display: inline-grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .row-gap-xl-0 {
    row-gap: 0 !important; }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xl-3 {
    row-gap: 1rem !important; }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xl-5 {
    row-gap: 3rem !important; }
  .column-gap-xl-0 {
    column-gap: 0 !important; }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xl-3 {
    column-gap: 1rem !important; }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xl-5 {
    column-gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .object-fit-xxl-contain {
    object-fit: contain !important; }
  .object-fit-xxl-cover {
    object-fit: cover !important; }
  .object-fit-xxl-fill {
    object-fit: fill !important; }
  .object-fit-xxl-scale {
    object-fit: scale-down !important; }
  .object-fit-xxl-none {
    object-fit: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-inline-grid {
    display: inline-grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .row-gap-xxl-0 {
    row-gap: 0 !important; }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xxl-3 {
    row-gap: 1rem !important; }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xxl-5 {
    row-gap: 3rem !important; }
  .column-gap-xxl-0 {
    column-gap: 0 !important; }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xxl-3 {
    column-gap: 1rem !important; }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xxl-5 {
    column-gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.8125rem !important; }
  .fs-2 {
    font-size: 2.25rem !important; }
  .fs-3 {
    font-size: 1.96875rem !important; }
  .fs-4 {
    font-size: 1.6875rem !important; }
  .fs-5 {
    font-size: 1.40625rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-inline-grid {
    display: inline-grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*=================================
Mixes
*/
/*
  $shadow-color: red;  // could also be a #F9F8F6 type color
  @include box-shadow(0 0px 12px rgba($shadow-color, .9));
*/
/*=================================
Typography
*/
body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (min-width: 1400px) {
  .container {
    max-width: 80.625rem; } }

a {
  color: #040210;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none; }
  a:focus, a:hover {
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #6045FF; }

.lead-sm {
  font-size: 1.25rem; }

.fw-bold {
  font-weight: 600 !important; }

input,
textarea,
.form-control {
  width: 100%;
  color: rgba(114, 114, 114, 0.5);
  padding: 1rem 1.25rem;
  border: 0.0625rem solid #E5E7ED;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem; }
  input.btn,
  textarea.btn,
  .form-control.btn {
    cursor: auto; }
  input:focus,
  textarea:focus,
  .form-control:focus {
    outline: 0 none;
    box-shadow: 0 0 0 0.8px rgba(96, 69, 255, 0.2); }
  input::placeholder,
  textarea::placeholder,
  .form-control::placeholder {
    opacity: 1;
    color: rgba(114, 114, 114, 0.5); }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(114, 114, 114, 0.5); }
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder,
  .form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(114, 114, 114, 0.5); }

input[type="checkbox"] {
  padding: 0rem; }

/*=================================
Theme Style
*/
.sec-intro {
  margin-bottom: 4.375rem; }
  @media (max-width: 575.98px) {
    .sec-intro {
      margin-bottom: 3.125rem;
      overflow: hidden; } }

.sec-padding {
  padding: 7.5rem 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .sec-padding {
      padding: 6.25rem 0; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .sec-padding {
      padding: 5.625rem 0; } }
  @media (max-width: 767.98px) {
    .sec-padding {
      padding: 5rem 0; } }

.sm-padding {
  padding: 5rem 0; }

.overly, .color, .color-info, .btn, .social-share-alt a, blockquote, .marquee p span, .navbar-line, .top-bar .quick-connect span:last-child, .top-bar .info-right > span, .header-actions a.text-reset.user-account, .btn-close, section.promo-sec, section.promo-sec nav, .category-search .search-group, .newsletter, .footer-widget .cta-link, .swiper-pagination span.swiper-pagination-bullet-active, .recent-entry .rc-entry, .instagram-widget .widget-inner .insta-wrap a, .banner-bottom, .banner-stat .single-stat, .subscribe-form, .course-sec, .course-footer, .course-preview, .video-block .cover-video, .video-block-sm .cover-video, .play-btn span, .about-sec2 .about-lists, .apply-process .apply-stat ul li, .review-2, .instructor-sec-2, .choose-txt ul li, .ct-tab .nav-link, .pricing-card .card-body ul li, .accordion-1 .accordion-item .accordion-body, .post-comments .comment-entry .commentor, .signup-sec, .login-form, .signup-form, .course-tab ul.nav li button, .profile-info {
  position: relative;
  z-index: 1; }
  .overly::before, .color::before, .color-info::before, .btn::before, .social-share-alt a::before, blockquote::before, .marquee p span::before, .navbar-line::before, .top-bar .quick-connect span:last-child::before, .top-bar .info-right > span::before, .header-actions a.text-reset.user-account::before, .btn-close::before, section.promo-sec::before, section.promo-sec nav::before, .category-search .search-group::before, .newsletter::before, .footer-widget .cta-link::before, .swiper-pagination span.swiper-pagination-bullet-active::before, .recent-entry .rc-entry::before, .instagram-widget .widget-inner .insta-wrap a::before, .banner-bottom::before, .banner-stat .single-stat::before, .subscribe-form::before, .course-sec::before, .course-footer::before, .course-preview::before, .video-block .cover-video::before, .video-block-sm .cover-video::before, .play-btn span::before, .about-sec2 .about-lists::before, .apply-process .apply-stat ul li::before, .review-2::before, .instructor-sec-2::before, .choose-txt ul li::before, .ct-tab .nav-link::before, .pricing-card .card-body ul li::before, .accordion-1 .accordion-item .accordion-body::before, .post-comments .comment-entry .commentor::before, .signup-sec::before, .login-form::before, .signup-form::before, .course-tab ul.nav li button::before, .profile-info::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #6045FF; }

.bg-cover, .contact-sec .contact-bg, .dash-cover-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.shadow-1, .contact-form input, .profile-form input,
.contact-form textarea,
.profile-form textarea, .accordion .accordion-item, .dashboard-sec {
  box-shadow: 0 0.0625rem 0.0625rem 0 rgba(106, 87, 231, 0.05), 0 0.0625rem 0.125rem 0.0625rem rgba(145, 145, 145, 0.06); }

.shadow-xs, .price-widget .social-share.white a, .row.announce-filter .nice-select {
  box-shadow: 0 1.25rem 2.5rem -0.8125rem rgba(0, 0, 0, 0.065) !important; }

.shadow-sm, .admin-menu, .course-entry-3:hover, .review-2 .swiper-slide-next .review-card, .speaker-entry:hover {
  box-shadow: 0 0.9375rem 3.75rem -0.75rem rgba(0, 0, 0, 0.08) !important; }

.shadow, .category-entry2:hover span.cat-icon, .pricing-card:hover .btn, .pricing-card.active .btn {
  box-shadow: 0 1.25rem 3.125rem rgba(96, 69, 255, 0.3) !important; }

.shadow-alt {
  box-shadow: 0 1.25rem 3.125rem 0 rgba(255, 160, 58, 0.4); }

.text-nowrap, .product-entry .product-content h3, .product-entry .product-content .h3 {
  text-overflow: ellipsis;
  overflow: hidden; }

.sec-title {
  line-height: 1.2;
  margin-bottom: 4.375rem;
  font-weight: 700; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .sec-title {
      font-size: 48px; } }

.sub-title {
  font-family: "Hind", sans-serif;
  font-weight: 600; }

.color {
  color: #FFA03A;
  display: inline-block; }
  .color::before {
    background: url("../images/icons/line-bg.png") no-repeat left bottom/contain;
    max-width: 24.9375rem;
    height: 0.875rem;
    top: 100%;
    width: 115%; }

.color-info {
  color: #fff; }
  .color-info::before {
    background: url("../images/icons/line-bg-white.png") no-repeat center bottom/contain; }

ol.breadcrumb {
  display: block; }
  ol.breadcrumb .breadcrumb-item {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    text-transform: capitalize; }
    ol.breadcrumb .breadcrumb-item::before {
      color: #6045FF;
      font-style: italic; }
    ol.breadcrumb .breadcrumb-item a {
      color: #fff; }

.btn {
  font-weight: 600;
  font-size: 1.125rem;
  padding: 1.0625rem 1.875rem;
  text-transform: capitalize;
  overflow: hidden;
  isolation: isolate;
  transform: translateZ(0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .btn::before {
    background-color: #1b01b5;
    height: 200%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
    opacity: 0.1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    filter: alpha(opacity=10);
    transform: scale(0.7) translateY(55%);
    transition: all 0.4s cubic-bezier(0, 0, 0.31, 0.99); }
  .btn:focus, .btn:visited {
    box-shadow: none;
    color: #fff; }
  .btn.btn-sm, .btn-group-sm > .btn {
    padding: 0.75rem 1.5625rem 0.625rem; }
  .btn:hover::before {
    transform: scaleX(2) scaleY(1) translateY(-15%); }
  .btn.btn-secondary::before {
    background-color: #874600; }
  .btn.btn-info {
    color: #040210; }
  .btn.btn-info::before, .btn.btn-dark::before {
    background-color: #b5b5b5; }
  .btn.btn-shade::before {
    background-color: transparent; }

.icon, .icon-sm, .icon-lg, .icon-xs, .teacher-entry .teacher-socials li a {
  height: 3.5rem;
  width: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  display: table;
  flex: 0 0 3.5rem; }
  .icon i, .icon-sm i, .icon-lg i, .icon-xs i, .teacher-entry .teacher-socials li a i {
    display: table-cell;
    vertical-align: middle; }

.icon-sm {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  flex: 0 0 4rem; }

.icon-lg {
  width: 4.5rem;
  height: 4.5rem;
  line-height: 4.5rem;
  flex: 0 0 4.5rem; }

.icon-xs, .teacher-entry .teacher-socials li a {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  flex: 0 0 2.5rem; }

.text-reset:hover {
  color: #6045FF !important; }

.full-screen {
  min-height: 100vh;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.125rem 0; }

.ratings {
  position: relative;
  line-height: 1;
  margin-top: -0.1875rem;
  letter-spacing: -0.25rem; }
  .ratings img {
    width: 1.25rem; }
    @media (max-width: 1199.98px) {
      .ratings img {
        width: 0.9375rem; } }

.badge-lg {
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0.5625rem 1.125rem;
  margin-bottom: 0.9375rem; }

.coupon-form .btn {
  right: 0;
  top: 0; }

.video-block, .video-block-sm {
  max-width: 250px;
  margin-left: auto;
  margin-right: 0; }
  @media (max-width: 575.98px) {
    .video-block, .video-block-sm {
      margin: 0 auto; } }
  .video-block .waves, .video-block-sm .waves {
    position: absolute;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    top: -60px;
    left: 10%;
    z-index: -1;
    -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite; }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .video-block .waves, .video-block-sm .waves {
        left: 4%; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .video-block .waves, .video-block-sm .waves {
        left: -13%; } }
  .video-block span, .video-block-sm span {
    font-size: 30px;
    font-weight: 500;
    color: #fff; }

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "alpha(opacity=0)"; }
  50% {
    opacity: .9;
    -ms-filter: "alpha(opacity=90)"; }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "alpha(opacity=0)"; } }

.video-block-sm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; }
  .video-block-sm .waves {
    width: 7.1875rem;
    height: 7.1875rem;
    left: -47%;
    top: -1.75rem; }
  .video-block-sm a.cover-video {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem; }
    .video-block-sm a.cover-video::before {
      width: 4.25rem;
      height: 4.25rem;
      outline: 0.3125rem solid rgba(255, 255, 255, 0.3);
      border: 0.3125rem solid rgba(255, 255, 255, 0.6); }

.anim-img {
  position: absolute;
  left: 1.875rem;
  top: 5rem;
  backface-visibility: hidden;
  animation: jumping 5s ease-in-out 4s infinite alternate;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  transition: all 4s ease 3s;
  user-select: none; }
  .anim-img.anim-right {
    left: auto;
    right: 1.875rem;
    animation: jumping2 5s ease-in-out 4s infinite alternate; }
  @media (max-width: 575.98px) {
    .anim-img {
      display: none; } }

@keyframes jumping {
  0% {
    transform: translateY(0rem) translateX(0) rotate(0) scale(0.5);
    opacity: .2; }
  50% {
    transform: translateY(-3.125rem) translateX(-3.125rem) rotate(15deg) scale(0.7);
    opacity: .7; }
  100% {
    transform: translateY(-4.6875rem) translateX(-4.6875rem) rotate(0) scale(1);
    opacity: 1; } }

@keyframes jumping2 {
  0% {
    transform: translateY(-9.375rem) translateX(-0.625rem) rotate(0) scale(1);
    opacity: .2; }
  50% {
    transform: translateY(-3.125rem) translateX(-0.625rem) rotate(5deg) scale(0.8);
    opacity: .5; }
  100% {
    transform: translateY(0rem) translateX(0) rotate(0) scale(0.7); } }

.social-share a {
  background-color: #6045FF;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  text-align: center;
  display: inline-table;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 0.625rem; }
  .social-share a i {
    display: table-cell;
    vertical-align: middle; }
  .social-share a:last-child {
    margin-right: 0; }

.social-share.white a {
  background-color: #fff;
  color: #6045FF; }
  .social-share.white a:hover {
    background-color: #6045FF;
    color: #fff; }

.social-share-alt a {
  margin-left: 0.625rem;
  padding: 5px;
  color: rgba(114, 114, 114, 0.5); }
  .social-share-alt a::before {
    width: 0rem;
    background-color: transparent;
    border-right: 0.0625rem solid rgba(255, 255, 255, 0.3);
    height: 1.25rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 1.9375rem; }
  .social-share-alt a:hover {
    color: #FFA03A; }

.social-share-alt span:last-child a::before {
  opacity: 0; }

.back-top {
  cursor: pointer;
  position: fixed !important;
  bottom: 2.5rem;
  right: 1.875rem;
  display: block;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #6045FF;
  color: #fff;
  border-radius: 10%;
  width: 3.25rem;
  height: 3.25rem;
  line-height: 3.25rem;
  text-align: center;
  font-size: 1.5rem; }
  .back-top.back-top-show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }

select {
  display: none; }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-radius: 0.3125rem;
  border: none;
  font-size: 1.125rem; }
  @media (max-width: 767.98px) {
    .nice-select {
      font-size: 1rem; } }
  .nice-select:after {
    border-color: rgba(114, 114, 114, 0.5);
    height: 0.5rem;
    width: 0.5rem;
    margin-top: -0.375rem;
    right: 0.875rem; }
  .nice-select .option.selected {
    font-weight: normal; }
  .nice-select .nice-select-dropdown {
    margin-top: 0rem; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #E5E7ED; }

.nice-select {
  border: 0.0625rem solid #E5E7ED;
  height: 2.9375rem;
  line-height: 2.9375rem; }

.pager a,
.pager span {
  width: 3.375rem;
  height: 3.375rem;
  line-height: 3.4375rem;
  display: inline-block;
  border: 0.0625rem solid #E5E7ED;
  font-size: 1.125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  text-align: center;
  margin-right: 0.3125rem;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .pager a:hover,
  .pager span:hover {
    background-color: #6045FF;
    color: #fff; }
  @media (max-width: 767.98px) {
    .pager a,
    .pager span {
      margin: 0.3125rem;
      width: 2.8125rem;
      height: 2.8125rem;
      line-height: 2.8125rem; } }
  @media (max-width: 575.98px) {
    .pager a,
    .pager span {
      margin: 0.125rem; } }

.pager .current {
  background-color: #6045FF;
  color: #fff; }

.btn-outline-primary {
  color: #040210; }
  .btn-outline-primary:focus, .btn-outline-primary:visited {
    color: #040210; }
  .btn-outline-primary:hover {
    color: #fff; }

blockquote {
  border-left: 0.5rem solid #432ebd;
  line-height: 1.8 !important;
  padding: 1.875rem 1.5625rem 1.875rem;
  font-weight: 400;
  font-size: 24px; }
  blockquote::before {
    left: auto;
    top: auto;
    right: 1.25rem;
    bottom: 0.625rem;
    height: 3rem;
    width: 4rem;
    background: url("../images/icons/quote-right.png") no-repeat; }

a#scrollUp {
  right: 2.5rem;
  bottom: 3.75rem;
  font-size: 3.125rem;
  width: 2.5rem;
  height: 4.375rem;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  a#scrollUp::before {
    position: absolute;
    content: "\f119";
    font-family: flaticon;
    -webkit-animation: 1.5s ease-in-out 0s normal none infinite running updown;
    animation: 1.5s ease-in-out 0s normal none infinite running updown;
    left: 65%;
    transform: translateX(-50%); }
  a#scrollUp::after {
    position: absolute;
    left: -0.3125rem;
    top: -1.375rem;
    width: 3.75rem;
    height: 3.75rem;
    background: #6045FF;
    content: "";
    border-radius: 50%;
    z-index: -1; }

.form-group.half-form {
  width: 48%;
  float: left;
  margin-bottom: 1.25rem; }
  @media (max-width: 767.98px) {
    .form-group.half-form {
      width: 100%;
      float: none; } }
  .form-group.half-form::after {
    content: "";
    display: table;
    clear: both; }
  .form-group.half-form:nth-child(2n) {
    float: right; }

.google-map iframe {
  height: 100%; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .google-map iframe {
      height: 28.125rem; } }
  @media (max-width: 767.98px) {
    .google-map iframe {
      height: 21.875rem; } }

/*
    Annimation
*/
@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(-20deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes updown {
  0% {
    transform: translateY(0rem); }
  50% {
    transform: translateY(1.875rem); }
  100% {
    transform: translateY(0rem); } }

#preloader.done {
  visibility: hidden;
  opacity: 0; }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999999; }

.preloader {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-block;
  padding: 0rem;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1.5625rem;
  margin-top: -1.5625rem; }
  .preloader span {
    position: absolute;
    display: inline-block;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 100%;
    background: #dbff00;
    -webkit-animation: preloader 1.3s linear infinite;
    animation: preloader 1.3s linear infinite; }
    .preloader span:last-child {
      animation-delay: -0.8s;
      -webkit-animation-delay: -0.8s; }

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  100% {
    transform: scale(1, 1);
    opacity: 0; } }

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0; } }

/*===========================================
Header Style*/
.marquee {
  overflow: hidden;
  display: flex; }

.marquee p {
  font-size: 1em;
  white-space: nowrap;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  margin: 0;
  letter-spacing: 0.0625rem; }
  .marquee p span {
    margin: 0 2rem; }
    .marquee p span::before {
      background: url(../images/icons/square.png) no-repeat;
      left: -2.375rem;
      top: 0.25rem; }

.header {
  position: relative;
  z-index: 99; }
  @media (max-width: 1199.98px) {
    .header .navbar {
      padding: 0.9375rem 0; } }
  .header .header-top {
    padding: 0.625rem 0; }
  .header.header-1 .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu {
    width: 100%; }
    .header.header-1 .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu:first-of-type:before {
      left: 23%; }
  .header.header-1 a.banner-ads img.img-fluid.banner-img {
    left: -47%; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .header.header-1 .navbar .navbar-nav li a.nav-link {
      padding: 1.875rem 0.8125rem; } }
  .header.header-1 .navbar .header-actions {
    gap: 0 1rem; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .header.header-1 .navbar .navbar-brand {
      margin-right: 2.5rem !important; } }
  .header.header-2 .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu {
    width: 100%; }
  .header.header-2 a.banner-ads img.img-fluid.banner-img {
    left: -47%; }
  .header.header-3 .header-actions {
    gap: 0rem 0.5rem; }
    .header.header-3 .header-actions .admin-menu {
      top: 110%;
      left: 70%; }
    .header.header-3 .header-actions .icon, .header.header-3 .header-actions .icon-sm, .header.header-3 .header-actions .icon-lg, .header.header-3 .header-actions .icon-xs, .header.header-3 .header-actions .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .header.header-3 .header-actions a {
      height: 3.25rem;
      line-height: 3.25rem;
      flex: 0 0 3.25rem;
      width: 3.25rem; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .header.header-3 .header-actions .category-search .search-group input {
        max-width: 8.125rem; } }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .header.header-3 .admin-user {
      display: none; } }

.navbar-line::before {
  width: calc(100% - 1.875rem);
  left: 50%;
  transform: translateX(-50%);
  height: 0rem;
  top: 100%;
  background-color: #E5E7ED; }
  @media (min-width: 992px) {
    .navbar-line::before {
      height: 0.0625rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .navbar-line::before {
      top: 135%; } }

.top-bar span,
.top-bar a {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.0625rem; }

.top-bar .dropdown-menu {
  min-width: 15.625rem; }
  .top-bar .dropdown-menu li {
    margin-bottom: 0.4375rem; }

.top-bar .quick-connect span:last-child {
  margin-left: 1.875rem; }
  .top-bar .quick-connect span:last-child::before {
    width: 0.0625rem;
    height: 1.25rem;
    left: -0.9375rem;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.top-bar .info-right > span {
  margin-right: 1.875rem; }
  .top-bar .info-right > span::before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.3);
    width: 0.0625rem;
    right: -0.9375rem;
    height: 1.25rem;
    left: auto; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .top-bar .info-right > span {
      margin-right: 1.25rem; }
      .top-bar .info-right > span::before {
        right: -0.625rem; } }
  @media (max-width: 767.98px) {
    .top-bar .info-right > span::before {
      display: none; } }

.navbar {
  padding: 0; }
  .navbar .dropdown,
  .navbar .dropdown-center,
  .navbar .dropend,
  .navbar .dropstart,
  .navbar .dropup,
  .navbar .dropup-center {
    position: relative; }
    @media (max-width: 1199.98px) {
      .navbar .dropdown,
      .navbar .dropdown-center,
      .navbar .dropend,
      .navbar .dropstart,
      .navbar .dropup,
      .navbar .dropup-center {
        width: 100%; } }
  .navbar .navbar-nav > li a.nav-link {
    padding: 2.0625rem 0.9375rem;
    color: #727272;
    text-transform: capitalize;
    font-size: 1.125rem;
    font-weight: 500; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .navbar .navbar-nav > li a.nav-link {
        padding: 1.75rem 0.6875rem; } }
    .navbar .navbar-nav > li a.nav-link::after {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  .navbar .navbar-nav > li .dropdown-item {
    color: #727272; }
  .navbar .navbar-nav > li:first-child a.nav-link {
    padding-left: 0; }
  .navbar .navbar-nav > li.dropdown-fullwidth {
    position: static; }
    .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu {
      left: 0 !important;
      margin-left: auto;
      margin-right: auto;
      right: 0 !important;
      top: 100%;
      transform: none !important;
      padding: 1.875rem 1.875rem 1.25rem;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu .dropdown-item,
      .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu .dropdown-header {
        padding-left: 0.3125rem; }
      .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu .dropdown-item:hover {
        padding-left: 0.625rem; }
      @media (min-width: 1200px) {
        .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu {
          width: calc(100% - 6.25rem); } }
      @media (max-width: 1199.98px) {
        .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu {
          padding: 1.25rem; } }
    .navbar .navbar-nav > li.dropdown-fullwidth .dropdown-menu:first-of-type:before {
      left: 38%; }
  @media (max-width: 1199.98px) {
    .navbar .navbar-nav > li {
      width: 100%; } }
  .navbar .navbar-nav .nav-item > .dropdown-menu:first-of-type::before {
    position: absolute;
    content: "";
    top: -0.75rem;
    left: 1.375rem;
    z-index: 8;
    width: 0;
    height: 0;
    border-left: 0.75rem solid transparent;
    border-right: 0.75rem solid transparent;
    border-bottom: 0.75rem solid #fff; }
  .navbar .navbar-nav .dropdown-menu {
    border: none;
    border-radius: 0.5rem;
    margin-top: 0;
    padding: 0.9375rem 0;
    min-width: 15.625rem;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: -0.125rem 0.125rem 4.375rem -1.5625rem rgba(0, 0, 0, 0.35);
    z-index: 10;
    transform: translateY(0.9375rem);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .navbar .navbar-nav .dropdown-menu .dropdown-item {
      align-items: center;
      display: flex;
      font-weight: 500;
      padding: 0.4375rem 0.9375rem;
      padding-left: 1.5625rem;
      width: 100%;
      font-size: 0.9375rem;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .navbar .navbar-nav .dropdown-menu .dropdown-item:hover, .navbar .navbar-nav .dropdown-menu .dropdown-item:focus {
        background-color: transparent;
        color: #6045FF;
        padding-left: 1.875rem; }
    .navbar .navbar-nav .dropdown-menu .dropdown-submenu a:after {
      position: absolute;
      right: 1rem;
      transform: rotate(-90deg); }
    .navbar .navbar-nav .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
      left: 100%;
      right: auto;
      top: -0.625rem; }
    .navbar .navbar-nav .dropdown-menu > li.dropdown-submenu.dropend:focus > a,
    .navbar .navbar-nav .dropdown-menu > li.dropdown-submenu.dropend:hover > a {
      color: #6045FF;
      background-color: transparent;
      padding-left: 1.875rem;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
  .navbar .navbar-nav > .dropdown:hover > .dropdown-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
    transform: translateY(0); }
  .navbar .navbar-brand {
    margin-right: 0; }
  .navbar .navbar-toggler:focus {
    box-shadow: none; }
  .navbar .dropdown .dropdown-toggle:after {
    content: "\f12f";
    font-family: "feather-icons" !important;
    border: 0 none;
    margin-left: 0.3125rem;
    float: right; }
    @media (max-width: 767.98px) {
      .navbar .dropdown .dropdown-toggle:after {
        margin-left: auto;
        margin-right: 0.625rem; } }
  .navbar button.navbar-toggler.offcanvas-nav-btn {
    border: 0;
    font-size: 24px;
    font-weight: 700;
    color: #040210; }
    .navbar button.navbar-toggler.offcanvas-nav-btn i {
      top: 2px;
      position: relative; }
  .navbar .dropdown-header {
    color: #040210;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 0.1875rem 0.9375rem 0.4375rem 1.5625rem;
    text-transform: uppercase; }
  .navbar .custom-btn {
    border-radius: 1.875rem;
    padding: 0.5625rem 1.75rem; }
  .navbar .nav-banner > a img {
    margin: 0.625rem 0; }
  .navbar .nav-cta a {
    width: 3.4375rem;
    text-align: center;
    color: #000; }
    @media (max-width: 575.98px) {
      .navbar .nav-cta a {
        width: 2.5rem; } }
    @media only screen and (max-width: 22.5rem) {
      .navbar .nav-cta a {
        width: 1.5625rem; } }
    .navbar .nav-cta a sup {
      background: #6045FF;
      color: #000;
      top: -0.75rem;
      right: 0;
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute; }
    .navbar .nav-cta a i {
      font-size: 1.25rem; }
    .navbar .nav-cta a.btn {
      width: auto; }
  .navbar .nav-cta .dropdown.nav-search a.dropdown-toggle {
    background: transparent; }
  .navbar.scroll-on .container::before {
    display: none; }
  .navbar.scroll-on .navbar-nav li a.nav-link {
    padding: 1.5625rem; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .navbar.scroll-on .navbar-nav li a.nav-link {
        padding: 1.375rem 0.9375rem; } }
  .navbar.scroll-on .navbar-nav li:first-child a.nav-link {
    padding-left: 0; }

.navbar.nav-center .offcanvas {
  max-width: 43.75rem; }
  @media (max-width: 991.98px) {
    .navbar.nav-center .offcanvas {
      max-width: calc(100% - 1.25rem); } }

.navbar.nav-center .navbar-nav li:last-child a.nav-link {
  padding-right: 0; }

@media (min-width: 1200px) {
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 33.33%; }
  .navbar .navbar-nav > li:hover > a.nav-link {
    color: #6045FF; }
    .navbar .navbar-nav > li:hover > a.nav-link::after {
      transform: rotate(180deg); }
  .navbar .navbar-nav .dropdown-menu {
    display: block;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    visibility: hidden;
    transform: translateY(1.25rem); }
  .navbar .navbar-nav .dropdown-submenu.dropend:hover > .dropdown-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
    transform: translateY(0); } }

@media (max-width: 1199.98px) {
  .navbar {
    padding: 0.625rem 0; }
    .navbar .offcanvas-header img {
      max-width: 7.1875rem; }
    .navbar .navbar-nav .dropdown-menu {
      background-color: transparent;
      min-width: 0 !important; }
      .navbar .navbar-nav .dropdown-menu .dropdown-item {
        font-size: 0.9375rem; }
    .navbar .offcanvas.bg-dark .dropdown-menu {
      background-color: #fff; }
    .navbar .offcanvas.bg-dark .navbar-nav .nav-item .nav-link {
      border-bottom: 0.0625rem solid #2b2b2b; }
  .navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
    margin-left: 0.625rem; }
  .navbar .navbar-nav .nav-item .nav-link {
    border-radius: 0;
    padding: 1rem 0;
    font-size: 1rem;
    line-height: 1; }
  .navbar .navbar-nav .nav-item:last-child .nav-link {
    border: 0 none; }
  .header-2 .navbar .offcanvas .navbar-nav li a.nav-link,
  .header-2 .navbar .offcanvas .dropdown > .dropdown-toggle:after {
    color: #040210; } }

.header-actions a.btn {
  padding: 0.8125rem 1.5rem; }

.header-actions a.text-reset.user-account {
  font-weight: 500;
  margin: 0 1.375rem; }
  .header-actions a.text-reset.user-account::before {
    width: 0.0625rem;
    background-color: #E5E7ED;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -0.9375rem;
    height: 1.25rem; }

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .header-actions a.icon, .header-actions a.icon-sm, .header-actions a.icon-lg, .header-actions a.icon-xs, .header-actions .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .header-actions a {
    width: 2.8125rem;
    flex: 0 0 2.8125rem;
    height: 2.8125rem; }
  .header-actions a.text-reset.user-account {
    margin: 0 0.875rem; }
    .header-actions a.text-reset.user-account::before {
      left: -0.625rem; } }

@media (max-width: 1199.98px) {
  .header-actions a.icon, .header-actions a.icon-sm, .header-actions a.icon-lg, .header-actions a.icon-xs, .header-actions .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .header-actions a {
    width: 2.8125rem;
    flex: 0 0 2.8125rem;
    height: 2.8125rem; }
  .header-actions a.text-reset.user-account {
    margin: 0 0.875rem; }
    .header-actions a.text-reset.user-account::before {
      left: -0.625rem; } }

.header-actions .cart-num {
  background-color: #FFA03A;
  color: #fff;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: -0.125rem;
  right: 0;
  font-size: 0.8125rem;
  line-height: 1.5rem; }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .header-default .header-actions {
    min-width: 24.0625rem; } }

header .header-nav-wrapper.header-sticky.scroll-on {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
  width: 100%;
  animation: headerSlideDown 1.3s ease forwards; }
  header .header-nav-wrapper.header-sticky.scroll-on .navbar-line:before {
    display: none; }

@keyframes headerSlideDown {
  0% {
    margin-top: -100px; }
  to {
    margin-top: 0; } }

.search-popup {
  min-height: 37.5rem !important; }
  @media (max-width: 575.98px) {
    .search-popup {
      min-height: 12.5rem; } }
  .search-popup .search-form {
    position: relative; }
    .search-popup .search-form:after {
      content: "\f1cd";
      font-family: "feather-icons";
      position: absolute;
      transform: translateY(-50%);
      z-index: 1;
      width: 1.25rem;
      left: 0.9375rem;
      background-color: transparent;
      top: 50%;
      transform: translateY(-50%); }
    .search-popup .search-form input {
      padding-left: 2.5rem; }
      .search-popup .search-form input:focus {
        box-shadow: none; }
  .search-popup .btn-close {
    position: absolute;
    right: 0;
    top: 1.25rem; }

.offcanvas-header .btn-close {
  padding: 0.25rem; }

.btn-close {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 2.25rem;
  flex: 0 0 1.875rem;
  text-align: center;
  display: table;
  background: 0 0;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
  background-color: transparent; }
  .btn-close:focus {
    border: 0 none;
    box-shadow: none; }
  .btn-close:before {
    font-family: "feather-icons";
    font-size: 20.8px;
    content: "\f213";
    color: #fff;
    background-color: transparent;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 2.3125rem; }

section.promo-sec {
  padding: 5.625rem 0rem 4.375rem; }
  section.promo-sec::before {
    background-color: #040210;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50); }
  section.promo-sec .breadcrumb-item + .breadcrumb-item::before {
    content: "\f135";
    font-family: "feather-icons";
    font-style: normal;
    color: #fff;
    font-size: 1.125rem; }
  section.promo-sec .breadcrumb .breadcrumb-item.active {
    color: #FFA03A; }
  section.promo-sec nav::before {
    background: url("../images/promo-arrows.png") no-repeat bottom left/contain;
    top: 3.125rem;
    left: 20%;
    width: 12.5rem; }

.promo-sec2, .dashbaord-promo {
  background: rgba(96, 69, 255, 0.1);
  background: linear-gradient(90deg, rgba(96, 69, 255, 0.1) 18%, #fff);
  padding: 5rem 0; }
  .promo-sec2 .course-intro p, .dashbaord-promo .course-intro p {
    max-width: 41.5625rem;
    margin: 1.25rem 0 1.875rem; }
  .promo-sec2 .course-intro i, .dashbaord-promo .course-intro i {
    color: #6045FF; }
  .promo-sec2 .course-intro img, .dashbaord-promo .course-intro img {
    width: 5.9375rem;
    margin-top: -0.25rem; }
  .promo-sec2 ol.breadcrumb .breadcrumb-item, .dashbaord-promo ol.breadcrumb .breadcrumb-item {
    color: #727272; }
    .promo-sec2 ol.breadcrumb .breadcrumb-item a, .dashbaord-promo ol.breadcrumb .breadcrumb-item a {
      color: #040210; }

.category-search form {
  border: 0.0625rem solid #E5E7ED;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  padding: 0.125rem; }
  .category-search form input:focus {
    box-shadow: none; }
  .category-search form .nice-select .nice-select-dropdown {
    margin-top: 1.4375rem; }
    .category-search form .nice-select .nice-select-dropdown ul {
      margin: 0;
      padding: 0.3125rem 0; }
    .category-search form .nice-select .nice-select-dropdown li {
      font-size: 1rem;
      font-weight: 500; }
    .category-search form .nice-select .nice-select-dropdown .option:hover,
    .category-search form .nice-select .nice-select-dropdown .option.focus,
    .category-search form .nice-select .nice-select-dropdown .option.selected.focus {
      background-color: #6045FF;
      color: #fff; }

.category-search .search-group::before {
  width: 0.125rem;
  background-color: #E5E7ED;
  z-index: 1;
  height: 1.5625rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.category-search .search-group button {
  right: 0.125rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 1.5625rem; }

.category-search .search-group input {
  padding: 0.5625rem 0.625rem;
  max-width: 12.8125rem; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .category-search .search-group input {
      max-width: 10.3125rem; } }

.category-search input,
.category-search .nice-select {
  border: 0; }

.category-search .nice-select {
  width: 8.25rem !important; }
  .category-search .nice-select span.current {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .category-search .nice-select .list {
    max-height: fit-content; }

.admin-menu {
  position: absolute;
  top: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%);
  width: 14.0625rem;
  padding: 0.9375rem; }
  .admin-menu .dashboard-nav .nav li .nav-link {
    padding: 0.5rem 0.3125rem;
    font-weight: 400;
    font-size: 1rem;
    font-family: "Hind", sans-serif; }
    .admin-menu .dashboard-nav .nav li .nav-link:hover {
      color: #6045FF; }
    .admin-menu .dashboard-nav .nav li .nav-link i {
      font-size: 0.9375rem; }

.promo-sec3 {
  padding: 5rem 0; }
  .promo-sec3::before {
    background-color: #000;
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
    filter: alpha(opacity=75); }
  .promo-sec3 #countdown {
    max-width: 31.25rem;
    font-size: 3.125rem;
    margin-top: 1.875rem; }
    .promo-sec3 #countdown span {
      background-color: rgba(255, 255, 255, 0.2);
      margin-right: 2%;
      padding: 0.625rem 0rem 1.25rem;
      -webkit-border-radius: 0.3125rem;
      -moz-border-radius: 0.3125rem;
      -ms-border-radius: 0.3125rem;
      border-radius: 0.3125rem; }
      .promo-sec3 #countdown span:after {
        bottom: 0.9375rem; }

a.banner-ads {
  background: #fff;
  padding: 1.875rem 0 2.5rem 4.375rem;
  position: relative; }
  a.banner-ads img.img-fluid.banner-img {
    position: absolute;
    left: -33%;
    bottom: -30%;
    width: 18.125rem; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      a.banner-ads img.img-fluid.banner-img {
        left: -45%; } }
    @media (max-width: 1199.98px) {
      a.banner-ads img.img-fluid.banner-img {
        position: absolute;
        left: 0 !important;
        bottom: 0;
        width: 59%; } }
  a.banner-ads .badge-lg {
    font-weight: 400; }
  a.banner-ads .b-content {
    text-align: right; }

/*=================================
Footer Style
*/
.newsletter {
  padding: 6.875rem 0 6.25rem; }
  .newsletter::before {
    background: url("../images/newsleft.png") no-repeat left top/contain;
    max-width: 31.875rem; }
  .newsletter::after {
    position: absolute;
    content: "";
    background: url("../images/newsright.png") no-repeat right bottom/contain;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 18.75rem;
    height: 100%;
    z-index: -1; }
  .newsletter .newsletter-form {
    max-width: 31.25rem; }
    @media (max-width: 991.98px) {
      .newsletter .newsletter-form {
        margin-top: 1.875rem; } }
    .newsletter .newsletter-form button {
      color: #fff; }

.footer {
  padding: 8.125rem 0 1.875rem; }
  .footer .footer-bottom {
    border-top: 0.0625rem solid rgba(255, 255, 255, 0.2);
    padding: 2.1875rem 0 0;
    margin-top: 5rem; }
    @media (max-width: 991.98px) {
      .footer .footer-bottom {
        margin-top: 2rem; } }
    .footer .footer-bottom p {
      font-size: 1rem; }
  .footer .social-share-alt img {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .footer .social-share-alt img:hover {
      opacity: 0.85;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
      filter: alpha(opacity=85); }
  .footer .social-share-alt a::before {
    display: none; }
  @media (max-width: 575.98px) {
    .footer .social-share-alt {
      margin-bottom: 1.25rem; }
      .footer .social-share-alt a {
        margin: 0 0.9375rem 1.25rem 0; }
        .footer .social-share-alt a::before {
          display: none; } }

.footer-widget .cta-link {
  margin: 2.5rem 0 0.3125rem;
  display: inline-block; }
  .footer-widget .cta-link::before {
    height: 2px;
    background-color: #FFA03A;
    top: 90%; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .footer-widget .cta-link {
      font-size: 2.1rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .footer-widget .cta-link {
      font-size: 1.8rem; } }

.footer-widget .widget-title {
  margin-bottom: 2.5rem; }

.footer-widget ul {
  margin: 0px;
  padding: 0px;
  list-style: none; }
  .footer-widget ul li {
    margin-bottom: 1.25rem;
    color: #fff; }
    @media (max-width: 1199.98px) {
      .footer-widget ul li {
        font-size: 1rem; } }
    .footer-widget ul li span i {
      margin-right: 0.625rem; }
    .footer-widget ul li a {
      color: rgba(255, 255, 255, 0.7); }
    .footer-widget ul li:hover a {
      color: #FFA03A; }

.footer-widget p {
  color: rgba(255, 255, 255, 0.6); }

.footer-widget.post-widget .recent-entry {
  margin-bottom: 1.875rem; }
  .footer-widget.post-widget .recent-entry .entry-thumb {
    overflow: hidden;
    flex: 0 0 6.5625rem; }
  .footer-widget.post-widget .recent-entry .entry-content h4 a, .footer-widget.post-widget .recent-entry .entry-content .h4 a, .footer-widget.post-widget .recent-entry .entry-content .product-details .product-price a, .product-details .footer-widget.post-widget .recent-entry .entry-content .product-price a {
    color: rgba(255, 255, 255, 0.7); }
    .footer-widget.post-widget .recent-entry .entry-content h4 a:hover, .footer-widget.post-widget .recent-entry .entry-content .h4 a:hover, .footer-widget.post-widget .recent-entry .entry-content .product-details .product-price a:hover, .product-details .footer-widget.post-widget .recent-entry .entry-content .product-price a:hover {
      color: #FFA03A; }
  .footer-widget.post-widget .recent-entry .entry-content span {
    margin-right: 0.625rem;
    font-size: 1.0625rem; }
    @media (max-width: 1199.98px) {
      .footer-widget.post-widget .recent-entry .entry-content span {
        font-size: 1rem; } }
    .footer-widget.post-widget .recent-entry .entry-content span i {
      margin-right: 0.3125rem; }
  .footer-widget.post-widget .recent-entry:last-child {
    margin-bottom: 0; }

@media (max-width: 767.98px) {
  .footer-widget {
    margin-bottom: 2.5rem; } }

.about-footer ul li a,
.about-footer ul li {
  color: rgba(255, 255, 255, 0.7); }
  .about-footer ul li a:hover,
  .about-footer ul li:hover {
    color: #FFA03A; }

.footer-social a {
  background-color: #F4F5F8;
  display: inline-table;
  width: 3rem;
  height: 3rem;
  text-align: center;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #dedede;
  margin-right: 0.625rem; }
  .footer-social a i {
    display: table-cell;
    vertical-align: middle; }
  .footer-social a:hover {
    background-color: #6045FF;
    color: #fff;
    border: 0.0625rem solid #6045FF; }

.footer-social .socials-2 a {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.25rem; }
  .footer-social .socials-2 a:hover {
    background-color: #FFA03A;
    border: 0.0625rem solid #FFA03A; }

.footer-2 .footer-widget p {
  color: #727272; }

.footer-2 .footer-widget ul li a {
  color: #727272; }
  .footer-2 .footer-widget ul li a:hover {
    color: #6045FF; }

@media (max-width: 991.98px) {
  .footer-2 .footer-widget {
    margin-bottom: 2.8125rem; } }

.footer-2 .copy-right p {
  color: rgba(255, 255, 255, 0.7); }

.about-footer p {
  max-width: 18.75rem; }

.subs-form .form-group {
  position: relative; }
  .subs-form .form-group img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 1.5625rem; }

.subs-form .btn i {
  margin-left: 0.625rem; }

.subs-form input {
  padding-left: 2.8125rem; }

/*=================================
Swiper Slider Style
*/
.swiper-pagination {
  text-align: left;
  display: flex;
  align-items: center; }
  .swiper-pagination span {
    width: 0.625rem;
    height: 0.625rem;
    background-color: #fff;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
    .swiper-pagination span.swiper-pagination-bullet-active {
      width: 1.375rem;
      height: 1.375rem;
      border: 0.0625rem solid #000;
      background-color: transparent; }
      .swiper-pagination span.swiper-pagination-bullet-active::before {
        width: 0.625rem;
        height: 0.625rem;
        background-color: #000;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }

.swiper-button-next,
.swiper-button-prev {
  background: #6045FF;
  display: inline-table;
  width: 4.125rem;
  height: 4.125rem;
  text-align: center;
  z-index: 9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  left: 0; }

.swiper-button-next {
  left: auto;
  right: 0; }

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "\f114";
  font-family: "feather-icons" !important;
  font-size: 1.5625rem;
  display: table-cell;
  vertical-align: middle; }

.swiper-button-prev:after {
  content: "\f112"; }

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); }

.custom-nav > div {
  background-color: #6045FF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 1.375rem; }

.product-slider {
  height: 80%;
  width: 100%; }

.product-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 0.625rem 0; }
  .product-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.9;
    cursor: pointer; }
    .product-thumbs .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-border-radius: 0.625rem;
      -moz-border-radius: 0.625rem;
      -ms-border-radius: 0.625rem;
      border-radius: 0.625rem; }
  .product-thumbs .swiper-slide-thumb-active {
    opacity: 1; }

/* =====================================
Widgets Style */
.sidebar-spacing {
  padding: 2.5rem 3.125rem 0 0; }
  @media (max-width: 1199.98px) {
    .sidebar-spacing {
      padding: 2.5rem 0.625rem 0 0; } }

.widget {
  margin-bottom: 2.8125rem; }
  .widget .widget-inner {
    border: 0.0625rem solid #E5E7ED;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    border-radius: 0.5rem;
    padding: 1.25rem;
    margin-top: 1.5625rem; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .widget .widget-inner {
        padding: 0.9375rem; } }
    .widget .widget-inner ul {
      margin: 0px;
      padding: 0px;
      list-style: none; }
      .widget .widget-inner ul li {
        margin-bottom: 0.5rem; }
        .widget .widget-inner ul li a {
          padding: 0.625rem 1.25rem;
          display: block;
          background: #F4F5F8;
          -webkit-border-radius: 0.3125rem;
          -moz-border-radius: 0.3125rem;
          -ms-border-radius: 0.3125rem;
          border-radius: 0.3125rem;
          color: #727272; }
          .widget .widget-inner ul li a:hover {
            background: #6045FF;
            color: #fff; }
        .widget .widget-inner ul li span.count {
          float: right; }
        .widget .widget-inner ul li:last-child {
          margin-bottom: 0; }
  .widget.category-widget ul li a {
    display: flex;
    justify-content: space-between;
    padding: 1.125rem 0.6875rem 0.8125rem 1.25rem;
    align-items: center; }

.search-widget {
  padding: 1.25rem;
  border: 0.0625rem solid #E5E7ED;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  border-radius: 0.625rem; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .search-widget {
      padding: 0.9375rem; } }
  .search-widget input {
    background-color: #F4F5F8;
    padding: 0.8125rem 1.875rem; }
  .search-widget .btn {
    right: 0rem;
    padding: 0.875rem 1.375rem; }
    .search-widget .btn i {
      margin-left: 0; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .widget_author .footer-social a {
    width: 2.75rem;
    height: 2.75rem; } }

.recent-entry .rc-entry {
  margin-bottom: 1.5625rem;
  padding-bottom: 1.25rem; }
  .recent-entry .rc-entry::before {
    background-color: #E5E7ED;
    width: calc(100% - 6.25rem);
    left: auto;
    right: 0;
    height: 0.0625rem;
    top: 100%; }
  .recent-entry .rc-entry .course-info {
    flex: 0 0 70%; }
  .recent-entry .rc-entry:last-child {
    margin-bottom: 0;
    padding-bottom: 0; }
    .recent-entry .rc-entry:last-child::before {
      display: none; }

.instagram-widget .widget-inner .insta-wrap a {
  overflow: hidden;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  display: block; }
  .instagram-widget .widget-inner .insta-wrap a::before {
    background-color: #000;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0); }
  .instagram-widget .widget-inner .insta-wrap a span {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 2.5rem;
    color: #fff; }
  .instagram-widget .widget-inner .insta-wrap a:hover::before {
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70); }
  .instagram-widget .widget-inner .insta-wrap a:hover span {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    top: 50%; }

.widget_tag_cloud a {
  display: inline-block;
  font-size: 1rem !important;
  line-height: 1;
  color: #727272;
  padding: 0.5rem 0.9375rem;
  margin: 2% 1%;
  text-transform: capitalize;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  background: #F4F5F8; }
  .widget_tag_cloud a:hover {
    background-color: #6045FF;
    color: #fff; }

.widget-top {
  margin-top: -28.125rem; }
  .widget-top.sticky-top {
    top: 6.25rem; }
  @media (max-width: 991.98px) {
    .widget-top {
      margin-top: 2.8125rem; } }

.price-widget h3 del, .price-widget .h3 del {
  font-size: 0.9375rem;
  font-weight: 400;
  color: #727272; }

.price-widget .price-widget-inner {
  margin-top: 1.875rem; }
  .price-widget .price-widget-inner ul {
    margin: 0px;
    padding: 0px;
    list-style: none; }
    .price-widget .price-widget-inner ul li {
      font-weight: 500;
      margin-bottom: 0.9375rem;
      border-bottom: 0.0625rem solid #eeeff3;
      padding-bottom: 0.9375rem;
      color: #727272;
      font-size: 1rem; }
      .price-widget .price-widget-inner ul li i {
        margin-right: 0.4375rem;
        color: #727272; }
      .price-widget .price-widget-inner ul li span {
        float: right;
        font-weight: 400; }
      .price-widget .price-widget-inner ul li:last-child {
        border: 0 none; }

.price-widget .social-share.white a {
  width: 3rem;
  height: 3rem; }

.ticket-card h3 span, .ticket-card .h3 span {
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(114, 114, 114, 0.5); }

.checkbox-custom {
  opacity: 0;
  position: absolute; }

.checkbox-custom,
.checkbox-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 0.3125rem;
  cursor: pointer;
  width: auto; }

.checkbox-custom-label {
  position: relative; }

.checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: #F4F5F8;
  display: inline-block;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  margin-right: 0.625rem;
  text-align: center;
  font-size: 0.9375rem;
  margin-top: -0.3125rem; }

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f12e";
  font-family: 'feather-icons';
  background: #6045FF;
  color: #fff; }

.form-check-input[type="radio"] {
  border-radius: 50%;
  padding: 0; }

.ls-entry {
  text-align: left;
  margin-bottom: 1.25rem; }
  .ls-entry .ls-info {
    flex: 0 0 65%; }
    .ls-entry .ls-info h6, .ls-entry .ls-info .h6, .ls-entry .ls-info .payment-option .accordion-button, .payment-option .ls-entry .ls-info .accordion-button, .ls-entry .ls-info .course-tab ul.nav li button, .course-tab ul.nav li .ls-entry .ls-info button {
      font-size: 1.25rem; }
    .ls-entry .ls-info a {
      font-weight: 500; }
  .ls-entry:last-child {
    margin-bottom: 0; }

/* =====================================
Banner Style */
.banner-content {
  padding: 90px 0; }
  .banner-content .banner-info {
    max-width: 400px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .banner-content .banner-info {
        margin-left: 40px; } }
  .banner-content h1, .banner-content .h1 {
    line-height: 1.2; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .banner-content h1, .banner-content .h1 {
        font-size: 72px; } }
  .banner-content p {
    font-weight: 400; }
  .banner-content .img-meta {
    left: 30px;
    top: 10px; }
  @media (max-width: 1199.98px) {
    .banner-content h1, .banner-content .h1 {
      font-size: 60px;
      margin-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .banner-content .banner-info {
      max-width: 100%; } }
  @media (max-width: 575.98px) {
    .banner-content {
      padding: 50px 0; }
      .banner-content h1, .banner-content .h1 {
        font-size: 36px;
        margin-bottom: 20px; } }

.banner-bottom::before {
  left: auto;
  right: -30px;
  top: -35%;
  width: 270px;
  background: url("../images/icons/stat-bg.png") no-repeat;
  background-position: right center;
  background-size: contain; }

.banner-bg {
  flex: 0 0 82%;
  overflow: hidden; }

.banner-stat {
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 330px;
  position: absolute;
  padding: 50px 40px !important; }
  @media (max-width: 575.98px) {
    .banner-stat {
      padding: 40px 30px !important; } }
  .banner-stat::after {
    background: url("../images/icons/line-shape.png") no-repeat right bottom;
    content: "";
    position: absolute;
    width: 63px;
    height: 38px;
    right: -33px;
    bottom: -35px; }
  .banner-stat .single-stat {
    padding-bottom: 19px;
    margin-bottom: 19px; }
    .banner-stat .single-stat::before {
      width: 62%;
      left: auto;
      right: 0px;
      background: transparent;
      border-bottom: 1px solid rgba(4, 2, 16, 0.1);
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
    .banner-stat .single-stat p {
      margin-bottom: 0; }
    .banner-stat .single-stat .stat-info .display-3 {
      line-height: 1.2;
      font-weight: 600; }
    .banner-stat .single-stat .icon, .banner-stat .single-stat .icon-sm, .banner-stat .single-stat .icon-lg, .banner-stat .single-stat .icon-xs, .banner-stat .single-stat .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .banner-stat .single-stat a {
      background-color: #ffad56;
      border: 1px dashed #fff; }
    .banner-stat .single-stat:last-child {
      padding-bottom: 0;
      margin-bottom: 0; }
      .banner-stat .single-stat:last-child::before {
        border: 0 none; }
    @media (max-width: 575.98px) {
      .banner-stat .single-stat {
        padding-bottom: 11px; } }
  @media (max-width: 767.98px) {
    .banner-stat {
      transform: translateY(-50px);
      right: 0; } }
  @media (max-width: 575.98px) {
    .banner-stat {
      background-color: rgba(255, 173, 86, 0.99) !important; } }

.banner-sec2 .banner2-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .banner-sec2 .banner2-bg {
      width: 55%; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .banner-sec2 .banner2-bg {
      right: -8%;
      width: 70%; } }

.banner-sec2 h1, .banner-sec2 .h1 {
  font-size: 76px;
  margin-bottom: 40px; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .banner-sec2 h1, .banner-sec2 .h1 {
      font-size: 66px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .banner-sec2 h1, .banner-sec2 .h1 {
      font-size: 55px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .banner-sec2 h1, .banner-sec2 .h1 {
      font-size: 40px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .banner-sec2 h1, .banner-sec2 .h1 {
      font-size: 55px; } }
  @media (max-width: 575.98px) {
    .banner-sec2 h1, .banner-sec2 .h1 {
      font-size: 40px; } }

.profile-ratings a {
  position: relative;
  margin-left: -10px; }

.profile-ratings img {
  width: 74px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }

.profile-ratings .s-rating {
  position: relative;
  line-height: 55px;
  flex: 0 0 75px;
  height: 75px;
  text-align: center;
  border: 3px solid #fff; }
  .profile-ratings .s-rating i {
    position: absolute;
    left: 27px;
    bottom: 16px;
    font-size: 17px; }

.subscribe-form {
  margin: 40px 0 60px; }
  .subscribe-form::before {
    background: url("../images/icons/message-dark.png") no-repeat center left;
    left: 30px;
    z-index: 1;
    top: -1px;
    width: 30px; }
  .subscribe-form input {
    background-color: #F4F5F8;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    padding: 21px 20px 21px 60px; }
  .subscribe-form .btn {
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    padding: 13px 28px;
    box-shadow: none; }
  @media (max-width: 575.98px) {
    .subscribe-form::before {
      left: 15px; }
    .subscribe-form .btn {
      padding: 11px 14px;
      font-size: 14px; }
    .subscribe-form input {
      padding: 15px 10px 15px 40px; } }

.banner-3 .banner-content {
  max-width: 630px; }
  .banner-3 .banner-content h1, .banner-3 .banner-content .h1 {
    font-size: 88px;
    margin-left: -5px; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .banner-3 .banner-content h1, .banner-3 .banner-content .h1 {
        font-size: 75px; } }
    @media (max-width: 1199.98px) {
      .banner-3 .banner-content h1, .banner-3 .banner-content .h1 {
        font-size: 65px; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .banner-3 .banner-content h1, .banner-3 .banner-content .h1 {
        font-size: 55px; } }
    @media (max-width: 767.98px) {
      .banner-3 .banner-content h1, .banner-3 .banner-content .h1 {
        font-size: 45px; } }
  .banner-3 .banner-content .banner-meta {
    display: inline-block;
    height: 40px;
    background-color: rgba(255, 173, 86, 0.1);
    padding-right: 20px; }
    .banner-3 .banner-content .banner-meta span {
      width: 40px;
      line-height: 40px;
      text-align: center;
      display: inline-block;
      margin-right: 10px;
      background-color: #FFA03A; }

.banner-3 .banner-media .anim-img {
  left: -5%;
  top: -10%; }
  .banner-3 .banner-media .anim-img.anim-right {
    left: auto;
    right: -10%; }

/* =====================================
Course Style */
.course-sec {
  padding-bottom: 0; }
  @media (max-width: 991.98px) {
    .course-sec .icon-sm {
      width: 3rem;
      height: 3rem;
      flex: 0 0 3rem;
      line-height: 3rem; } }
  .course-sec::before {
    height: 36.8125rem;
    background-color: #F4F5F8; }
  .course-sec .sec-intro {
    margin-bottom: 0rem; }
  .course-sec .custom-nav {
    margin-top: -0.625rem; }
  .course-sec .swiper-slide {
    width: 23.75rem; }
  .course-sec .swiper-slide-active .course-entry-3 {
    box-shadow: 0.625rem 1.25rem 3.125rem -0.5rem rgba(4, 2, 16, 0.08); }
    .course-sec .swiper-slide-active .course-entry-3 .sub-title a,
    .course-sec .swiper-slide-active .course-entry-3 .course-footer a {
      color: #6045FF; }

.course-entry-3 {
  transform-origin: 50% 100%;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .course-entry-3 .action-wishlist {
    right: 0.625rem;
    top: 0.625rem;
    background: rgba(0, 0, 0, 0.3); }
  .course-entry-3 .course-cat {
    left: 0.625rem;
    top: 1rem;
    position: absolute;
    font-size: 1rem;
    font-weight: 400; }
  .course-entry-3 .card-body {
    padding: 0.9375rem 1.5625rem 1.25rem; }
  .course-entry-3 .course-meta img {
    width: 1.0625rem;
    margin-top: -0.3125rem; }
  .course-entry-3 .course-meta strong {
    margin: 0 0.3125rem; }
  .course-entry-3 .course-footer {
    border-top: 0.0625rem solid #E5E7ED; }
    .course-entry-3 .course-footer::before {
      background-color: #E5E7ED; }
    .course-entry-3 .course-footer .price,
    .course-entry-3 .course-footer a {
      color: #040210; }
  .course-entry-3:hover .sub-title a,
  .course-entry-3:hover .course-footer a {
    color: #6045FF; }
  .course-entry-3.card-list {
    display: flex;
    align-items: center; }
    .course-entry-3.card-list .card-media {
      flex: 0 0 46%; }
    .course-entry-3.card-list .card-body {
      padding: 1.25rem 1.875rem; }
      @media (min-width: 1200px) and (max-width: 1399.98px) {
        .course-entry-3.card-list .card-body {
          padding: 0.9375rem 1.25rem; } }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .course-entry-3.card-list .card-body {
          padding: 0.5rem 1.25rem; } }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .course-entry-3.card-list .card-body {
          padding: 0.5rem 1.875rem; } }
    @media (max-width: 767.98px) {
      .course-entry-3.card-list {
        display: block; } }
    .course-entry-3.card-list:hover {
      -webkit-box-shadow: 0.125rem 0.625rem 1.25rem 0.125rem rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0.125rem 0.625rem 1.25rem 0.125rem rgba(0, 0, 0, 0.05);
      box-shadow: 0.125rem 0.625rem 1.25rem 0.125rem rgba(0, 0, 0, 0.05); }

.categories-sec .anim-img {
  z-index: -1; }

.category-entry {
  border: 0.0625rem solid #E5E7ED;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .category-entry .icon, .category-entry .icon-sm, .category-entry .icon-lg, .category-entry .icon-xs, .category-entry .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .category-entry a {
    background-color: rgba(96, 96, 255, 0.1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .category-entry .icon svg, .category-entry .icon-sm svg, .category-entry .icon-lg svg, .category-entry .icon-xs svg, .category-entry .teacher-entry .teacher-socials li a svg, .teacher-entry .teacher-socials li .category-entry a svg {
      fill: #6045FF;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .category-entry .icon svg:hover, .category-entry .icon-sm svg:hover, .category-entry .icon-lg svg:hover, .category-entry .icon-xs svg:hover, .category-entry .teacher-entry .teacher-socials li a svg:hover, .teacher-entry .teacher-socials li .category-entry a svg:hover {
        fill: #fff; }
  .category-entry small, .category-entry .small, .category-entry .course-entry-3 .course-meta, .course-entry-3 .category-entry .course-meta, .category-entry .instructor-sec-2 .instructor-card p, .instructor-sec-2 .instructor-card .category-entry p, .category-entry .author-awards .award-stat p, .author-awards .award-stat .category-entry p, .category-entry .dashboard-sidebar .grettings, .dashboard-sidebar .category-entry .grettings {
    color: #727272; }
  .category-entry:hover, .category-entry.active {
    box-shadow: 20px 20px 70px -8px rgba(4, 2, 16, 0.1);
    border: 0.0625rem solid transparent; }
    .category-entry:hover .icon, .category-entry:hover .icon-sm, .category-entry:hover .icon-lg, .category-entry:hover .icon-xs, .category-entry:hover .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .category-entry:hover a, .category-entry.active .icon, .category-entry.active .icon-sm, .category-entry.active .icon-lg, .category-entry.active .icon-xs, .category-entry.active .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .category-entry.active a {
      background-color: #FFA03A;
      box-shadow: 0rem 1.25rem 3.125rem 0rem rgba(255, 160, 58, 0.4); }
      .category-entry:hover .icon svg, .category-entry:hover .icon-sm svg, .category-entry:hover .icon-lg svg, .category-entry:hover .icon-xs svg, .category-entry:hover .teacher-entry .teacher-socials li a svg, .teacher-entry .teacher-socials li .category-entry:hover a svg, .category-entry.active .icon svg, .category-entry.active .icon-sm svg, .category-entry.active .icon-lg svg, .category-entry.active .icon-xs svg, .category-entry.active .teacher-entry .teacher-socials li a svg, .teacher-entry .teacher-socials li .category-entry.active a svg {
        fill: #fff; }
  .category-entry .cat-info {
    margin-left: 1.25rem; }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .category-entry .cat-info {
        margin-left: 0.9375rem; }
        .category-entry .cat-info h4, .category-entry .cat-info .h4, .category-entry .cat-info .product-details .product-price, .product-details .category-entry .cat-info .product-price {
          font-size: 17.6px; } }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .category-entry .cat-info h4, .category-entry .cat-info .h4, .category-entry .cat-info .product-details .product-price, .product-details .category-entry .cat-info .product-price {
        font-size: 22.4px; } }
    @media (max-width: 575.98px) {
      .category-entry .cat-info h4, .category-entry .cat-info .h4, .category-entry .cat-info .product-details .product-price, .product-details .category-entry .cat-info .product-price {
        font-size: 17.6px; } }

.course-entry {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0.0625rem solid rgba(229, 231, 237, 0.15);
  transform-origin: 50% 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .course-entry .action-wishlist {
    right: 0.625rem;
    top: 0.625rem; }
  .course-entry .sub-title {
    margin: 0.9375rem 0 1.875rem;
    line-height: 1.5; }
    .course-entry .sub-title a {
      color: #fff; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .course-entry .sub-title {
        font-size: 22.4px; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .course-entry .sub-title {
        font-size: 20.8px; } }
    @media (max-width: 767.98px) {
      .course-entry .sub-title {
        font-size: 25.6px; } }
    @media (max-width: 575.98px) {
      .course-entry .sub-title {
        font-size: 22.4px; } }
  .course-entry .card-body span,
  .course-entry .author-meta a {
    color: rgba(255, 255, 255, 0.6);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 575.98px) {
      .course-entry .card-body span,
      .course-entry .author-meta a {
        font-size: 1rem; } }
    .course-entry .card-body span:hover,
    .course-entry .author-meta a:hover {
      color: #6045FF; }
  .course-entry:hover {
    background-color: #fff;
    transform: scale(1.01, 1.01, 0.6) translate3d(0.32px, -1.6px, 80px); }
    .course-entry:hover .sub-title a {
      color: #6045FF; }
    .course-entry:hover .card-body span,
    .course-entry:hover .author-meta a {
      color: #727272; }
    .course-entry:hover .course-footer {
      border-top: 0.0625rem solid rgba(229, 231, 237, 0.85); }
      .course-entry:hover .course-footer::before {
        background-color: rgba(229, 231, 237, 0.85); }
      .course-entry:hover .course-footer a,
      .course-entry:hover .course-footer .price {
        color: #040210; }

.course-footer {
  border-top: 0.0625rem solid rgba(229, 231, 237, 0.15);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .course-footer::before {
    background-color: rgba(229, 231, 237, 0.15);
    width: 0.0625rem;
    height: 1.875rem;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .course-footer .price {
    font-size: 1.375rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .course-footer .price del {
      font-size: 0.9375rem;
      margin-left: 0.3125rem;
      font-weight: 300; }
  .course-footer a {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 600; }

.course-sec-2 {
  padding-bottom: 13.75rem; }
  .course-sec-2 .grid-item {
    margin-bottom: 2.8125rem; }

.course-entry-2 .card-media img {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  transition: transform 1.5s cubic-bezier(0, 0, 0.44, 1.18); }

.course-entry-2 .card-media .course-cat {
  left: 0.625rem;
  top: 0.625rem;
  font-size: 0.9375rem;
  padding: 0.625rem 0.8125rem; }
  .course-entry-2 .card-media .course-cat:hover {
    color: #fff; }

.course-entry-2 .card-body {
  bottom: -2.5rem;
  left: 0;
  max-width: calc(100% - 2.5rem);
  padding: 1.5625rem 1.25rem 0.9375rem;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .course-entry-2 .card-body {
      max-width: calc(100% - 1.25rem); } }
  @media (max-width: 575.98px) {
    .course-entry-2 .card-body {
      max-width: calc(100% - 0.625rem); } }
  .course-entry-2 .card-body .sub-title {
    margin: 0; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .course-entry-2 .card-body .sub-title {
        font-size: 19.2px; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .course-entry-2 .card-body .sub-title {
        font-size: 19.2px; } }
  .course-entry-2 .card-body .course-meta span:first-child {
    margin-right: 2.5rem; }
  @media (max-width: 575.98px) {
    .course-entry-2 .card-body .course-meta {
      font-size: 1rem; } }
  .course-entry-2 .card-body .course-hover {
    height: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .course-entry-2 .card-body .rating-wrap {
    margin-top: 0.9375rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .course-entry-2 .card-body .course-footer {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .course-entry-2 .card-body .course-footer .price {
      font-family: "Hind", sans-serif;
      font-weight: 500;
      margin: 0; }
      .course-entry-2 .card-body .course-footer .price del {
        margin-right: 0.625rem;
        font-weight: 400;
        font-size: 1.125rem;
        color: #727272; }

.course-entry-2:hover .card-media img {
  transform: scale(1.06); }

.course-entry-2:hover .card-body .course-hover {
  height: 6.25rem;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.category-entry2 span.cat-icon {
  width: 11.875rem;
  height: 11.875rem;
  line-height: 11.875rem;
  display: block;
  text-align: center;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 auto; }
  .category-entry2 span.cat-icon svg {
    fill: #6045FF;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.category-entry2:hover span.cat-icon {
  background-color: #6045FF; }
  .category-entry2:hover span.cat-icon svg {
    fill: #fff; }

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .category-entry2 span.cat-icon {
    width: 10.9375rem;
    height: 10.9375rem;
    line-height: 10.9375rem; }
  .category-entry2 h4, .category-entry2 .h4, .category-entry2 .product-details .product-price, .product-details .category-entry2 .product-price {
    font-size: 16px; } }

.course-filters .nice-select {
  min-width: 11.5625rem;
  height: 3.5rem;
  line-height: 3.5rem; }

@media (max-width: 575.98px) {
  .course-filters .result p {
    font-size: 1rem; } }

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .courses-sec .course-entry-3 .sub-title {
    font-size: 22.4px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .courses-sec .course-entry-3 .sub-title {
    font-size: 19.2px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .courses-sec .course-entry-3 .sub-title {
    font-size: 22.4px; } }

.course-nav nav.sticky-top,
.sidebar.sticky-top {
  z-index: 5; }
  @media (max-width: 767.98px) {
    .course-nav nav.sticky-top,
    .sidebar.sticky-top {
      position: static; } }

.course-nav .nav {
  justify-content: space-between;
  margin-bottom: 1.875rem;
  flex-wrap: nowrap; }
  @media (max-width: 767.98px) {
    .course-nav .nav {
      flex-wrap: wrap; } }
  .course-nav .nav li .nav-link {
    background: #F4F5F8;
    padding: 1.25rem 2.8125rem;
    color: #040210;
    font-weight: 500;
    line-height: 1; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .course-nav .nav li .nav-link {
        padding: 0.9375rem 1.25rem; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .course-nav .nav li .nav-link {
        padding: 0.9375rem 1.875rem; } }
    @media (max-width: 767.98px) {
      .course-nav .nav li .nav-link {
        padding: 0.9375rem 0.625rem;
        font-size: 0.9375rem;
        margin-bottom: 0.625rem; } }
    .course-nav .nav li .nav-link i {
      font-size: 1.25rem;
      position: relative;
      top: 0.125rem;
      margin-right: 0.3125rem; }
      @media (max-width: 575.98px) {
        .course-nav .nav li .nav-link i {
          font-size: 0.875rem;
          margin-right: 0.125rem; } }
    .course-nav .nav li .nav-link.active, .course-nav .nav li .nav-link:hover {
      background-color: #6045FF;
      color: #fff; }

.course-nav .inner-sec {
  margin-top: 2.5rem; }
  .course-nav .inner-sec h2.display-4, .course-nav .inner-sec .display-4.h2 {
    margin-bottom: 1.875rem; }
  .course-nav .inner-sec ul {
    padding: 0.625rem 1.25rem 0; }
    .course-nav .inner-sec ul li {
      margin-bottom: 0.8125rem; }
  .course-nav .inner-sec p {
    line-height: 170%; }

.course-nav nav.sticky-top {
  top: 6.25rem;
  background-color: #fff;
  padding: 0.625rem 0rem; }
  .course-nav nav.sticky-top ul {
    margin: 0; }

.author-card .author-img {
  flex: 0 0 15rem;
  margin-right: 1.875rem; }

.author-card .author-text h4, .author-card .author-text .h4, .author-card .author-text .product-details .product-price, .product-details .author-card .author-text .product-price {
  margin: 0; }

.author-card .author-text p {
  margin: 0.9375rem 0 1.25rem; }

@media (max-width: 1199.98px) {
  .course-intro h1, .course-intro .h1 {
    font-size: 36.8px; } }

@media (max-width: 575.98px) {
  .course-intro h1, .course-intro .h1 {
    font-size: 28.8px; }
  .course-intro span {
    font-size: 0.9375rem; } }

.course-intro .d-flex span {
  margin-right: 0.9375rem; }

.course-intro #countdown {
  margin-left: 0; }

.course-details-sec {
  padding: 5rem 0; }
  .course-details-sec .course-details-meta {
    padding: 1.875rem 0; }
  @media (max-width: 575.98px) {
    .course-details-sec .course-reviews {
      margin: 0.625rem 0; } }

.course-preview {
  -webkit-transition: all ease 0.7s;
  -moz-transition: all ease 0.7s;
  -ms-transition: all ease 0.7s;
  -o-transition: all ease 0.7s;
  transition: all ease 0.7s; }
  .course-preview::before {
    background-color: #212529;
    z-index: 1;
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40); }

/* =====================================
CTA Style */
.cta-1 {
  padding: 7.5rem 0; }
  .cta-1::before {
    background: #040210;
    background: linear-gradient(90deg, #040210 0%, rgba(4, 2, 16, 0) 100%); }
  @media (max-width: 1199.98px) {
    .cta-1 .sec-title {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .cta-1 .sec-title {
      font-size: 2.5rem; } }
  @media (max-width: 575.98px) {
    .cta-1 .consul-video.text-center {
      margin-top: 2.1875rem; }
    .cta-1 .sec-title {
      font-size: 1.875rem; } }

.video-block .cover-video, .video-block-sm .cover-video {
  height: 5rem;
  width: 5rem;
  line-height: 5rem;
  text-align: center;
  border-radius: 100%;
  font-size: 1.875rem;
  display: inline-block;
  color: #FFA03A;
  background: #fff; }
  .video-block .cover-video::before, .video-block-sm .cover-video::before {
    width: 110%;
    height: 110%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: 3.375rem solid rgba(255, 255, 255, 0.5);
    outline: 0.875rem solid rgba(255, 255, 255, 0.3);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }

.cta-wrap {
  padding: 80px; }
  .cta-wrap .cta-bg {
    bottom: -10px;
    right: -10px;
    width: 40%;
    height: 362px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom; }
  .cta-wrap p {
    color: rgba(255, 255, 255, 0.6); }
    @media (max-width: 1199.98px) {
      .cta-wrap p {
        max-width: 390px; } }

/* =====================================
About Us Style */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .about-sec .about-txt {
    margin-left: 2.5rem; }
    .about-sec .about-txt h2, .about-sec .about-txt .h2 {
      font-size: 2.8rem; } }

@media (max-width: 1199.98px) {
  .about-sec .about-txt {
    margin-top: 2.5rem; } }

.about-sec .about-content {
  max-width: 43.75rem; }

.about-media::before {
  background: url("../../images/icons/stat-bg.png") no-repeat top left;
  left: -15%;
  top: -8%; }

.about-media .category-entry {
  width: 17.8125rem;
  position: absolute;
  right: 1.5625rem;
  background-color: #fff;
  top: 2.5rem; }
  .about-media .category-entry .icon-lg {
    margin-left: 0.3125rem; }
  .about-media .category-entry .cat-info h3, .about-media .category-entry .cat-info .h3 {
    margin-bottom: 0; }

.about-item {
  margin-bottom: 1.25rem; }

.about-media-2 {
  max-width: 41.25rem; }
  .about-media-2 img.ab-media-1 {
    border-radius: 11.25rem 11.25rem 0rem 11.25rem; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .about-media-2 img.ab-media-1 {
        width: 55%; } }
    @media (max-width: 767.98px) {
      .about-media-2 img.ab-media-1 {
        width: 55%; } }
  .about-media-2 img.ab-media-2 {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    right: 0;
    top: 0; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .about-media-2 img.ab-media-2 {
        width: 43%; } }
    @media (max-width: 767.98px) {
      .about-media-2 img.ab-media-2 {
        width: 42%; } }
  .about-media-2 img.ab-media-3 {
    -webkit-border-radius: 7.25rem 10rem 10rem 0rem;
    -moz-border-radius: 7.25rem 10rem 10rem 0rem;
    -ms-border-radius: 7.25rem 10rem 10rem 0rem;
    border-radius: 7.25rem 10rem 10rem 0rem;
    right: 0;
    bottom: 0; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .about-media-2 img.ab-media-3 {
        width: 43%; } }
    @media (max-width: 767.98px) {
      .about-media-2 img.ab-media-3 {
        width: 42%; } }

.play-btn span {
  display: inline-block; }
  .play-btn span::before, .play-btn span::after {
    background-color: transparent;
    border: 0.0625rem solid #FFA03A;
    width: 120%;
    height: 120%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
  .play-btn span::after {
    position: absolute;
    content: "";
    width: 145%;
    height: 145%; }

.about-sec2 {
  padding-bottom: 6.25rem; }
  @media (max-width: 575.98px) {
    .about-sec2 {
      padding-bottom: 1.875rem; } }
  .about-sec2 .sec-title {
    font-size: 3.75rem; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .about-sec2 .sec-title {
        font-size: 2.8125rem; } }
    @media (max-width: 575.98px) {
      .about-sec2 .sec-title {
        font-size: 2.1875rem; } }
  .about-sec2 .about-info {
    padding: 1.25rem 0 2.1875rem; }
  .about-sec2 .about-media {
    margin-left: -6.25rem; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .about-sec2 .about-media {
        margin-left: -7.1875rem; } }
    @media (max-width: 1199.98px) {
      .about-sec2 .about-media {
        margin-left: auto;
        margin-bottom: 3.75rem; } }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .about-sec2 .about-item h3, .about-sec2 .about-item .h3 {
      font-size: 18px; } }
  .about-sec2 .about-lists {
    padding: 2.1875rem 0;
    border-top: 0.0625rem solid #E5E7ED;
    border-bottom: 0.0625rem solid #E5E7ED; }
    .about-sec2 .about-lists::before {
      width: 0.0625rem;
      background-color: #E5E7ED;
      left: 50%;
      transform: translateX(-50%); }
      @media (max-width: 575.98px) {
        .about-sec2 .about-lists::before {
          display: none; } }

@media (max-width: 767.98px) {
  .about-mission:first-child {
    margin-bottom: 1.875rem; } }

.about-mission ul {
  margin: 0px;
  padding: 0px;
  list-style: none; }
  .about-mission ul li {
    position: relative;
    padding-left: 1.875rem;
    line-height: 2.375rem; }
    .about-mission ul li::before {
      position: absolute;
      content: "\f12d";
      font-family: feather-icons;
      color: #6045FF;
      left: 0rem; }

.admission-media .video-block, .admission-media .video-block-sm {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  .admission-media .video-block .waves, .admission-media .video-block-sm .waves {
    left: -3.75rem; }

.apply-process .card span {
  font-size: 1.5625rem; }

.apply-process .card p {
  margin: 0; }

.apply-process .apply-stat ul {
  margin: 0px;
  padding: 0px;
  list-style: none; }
  .apply-process .apply-stat ul li {
    margin-bottom: 1.25rem;
    padding-left: 1.875rem; }
    .apply-process .apply-stat ul li::before {
      content: "\f12e";
      font-family: "feather-icons";
      color: #FFA03A;
      background-color: transparent;
      font-size: 1.25rem; }

/* =====================================
Testimonial Style */
.review-sec {
  padding: 5.625rem 0 7.5rem; }

.review-entry p {
  color: #212529;
  line-height: 1.5;
  margin-bottom: 1.875rem;
  font-size: 2.375rem; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .review-entry p {
      font-size: 2.0625rem; } }
  @media (max-width: 1199.98px) {
    .review-entry p {
      font-size: 1.75rem; } }
  @media (max-width: 991.98px) {
    .review-entry p {
      font-size: 1.5rem; } }
  @media (max-width: 767.98px) {
    .review-entry p {
      font-size: 1.625rem; } }

.review-entry .ratings {
  margin-right: 1.875rem; }
  @media (max-width: 767.98px) {
    .review-entry .ratings {
      text-align: center;
      margin-right: 3.75rem;
      margin-top: 0.3125rem; } }

@media (min-width: 992px) {
  .review-img {
    position: relative;
    margin-left: 3.125rem; } }

.review-2 {
  padding-bottom: 3.75rem; }
  .review-2::before {
    height: 20.3125rem;
    background-color: #F4F5F8;
    top: auto;
    bottom: 0;
    width: calc(100% - 5rem);
    left: 50%;
    transform: translateX(-50%); }
  .review-2 .swiper-button-next,
  .review-2 .swiper-button-prev {
    position: relative;
    margin: 0 0.9375rem;
    -webkit-border-radius: 0.3125rem;
    -moz-border-radius: 0.3125rem;
    -ms-border-radius: 0.3125rem;
    border-radius: 0.3125rem;
    background-color: #6045FF;
    color: #fff; }
  .review-2 .swiper-slide-next .review-card {
    border: 0 none; }

.review-card {
  padding: 1.875rem;
  border: 0.0625rem solid #E5E7ED; }
  .review-card h3, .review-card .h3 {
    font-weight: 600; }

/* =====================================
Instructor Style */
.team-sec {
  overflow: hidden; }

.teacher-entry {
  min-height: 32.5rem;
  background-size: cover;
  background-position: top center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media (max-width: 1199.98px) {
    .teacher-entry {
      min-height: 25rem; } }
  .teacher-entry .teacher-info {
    bottom: 0rem;
    width: 100%; }
    .teacher-entry .teacher-info .teacher-intro {
      background-color: rgba(4, 2, 16, 0.3);
      transition: background-color 0.4s ease-in; }
      .teacher-entry .teacher-info .teacher-intro h3 a.text-reset, .teacher-entry .teacher-info .teacher-intro .h3 a.text-reset,
      .teacher-entry .teacher-info .teacher-intro span {
        color: #fff !important;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in; }
  .teacher-entry .social-action {
    right: 1.75rem;
    top: 0rem;
    cursor: pointer;
    font-size: 1.25rem;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  @media (max-width: 1199.98px) {
    .teacher-entry h3.display-4, .teacher-entry .display-4.h3 {
      font-size: 1.5625rem; } }
  .teacher-entry .teacher-socials {
    right: 2.5rem;
    bottom: 100%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .teacher-entry .teacher-socials li {
      display: block;
      margin-bottom: 0.625rem; }
      .teacher-entry .teacher-socials li a {
        background-color: #fff;
        color: #6045FF;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
    .teacher-entry .teacher-socials.active {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
  .teacher-entry:hover {
    transform: translateY(-0.625rem); }
    .teacher-entry:hover .social-action {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      top: -0.625rem; }
    .teacher-entry:hover .teacher-info .teacher-intro {
      background-color: #F4F5F8; }
      .teacher-entry:hover .teacher-info .teacher-intro h3 a.text-reset, .teacher-entry:hover .teacher-info .teacher-intro .h3 a.text-reset, .teacher-entry:hover .teacher-info .teacher-intro span {
        color: #040210 !important; }

.instructor-sec-2 {
  overflow: hidden; }
  .instructor-sec-2::before {
    height: 34.375rem;
    background-color: #000; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .instructor-sec-2::before {
        height: 27.5rem; } }
  .instructor-sec-2 .social-share {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    visibility: hidden;
    transform: translateY(3.125rem);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .instructor-sec-2 .social-share a {
      margin: 0 0.3125rem; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .instructor-sec-2 .social-share a {
          margin: 0; } }
  .instructor-sec-2 .instructor-card {
    overflow: hidden; }
    .instructor-sec-2 .instructor-card h3, .instructor-sec-2 .instructor-card .h3 {
      font-size: 1.5rem; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .instructor-sec-2 .instructor-card h3, .instructor-sec-2 .instructor-card .h3 {
          font-size: 1.1875rem; } }
    .instructor-sec-2 .instructor-card:hover .social-share {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      visibility: visible;
      transform: translateY(0rem); }

.contact-lists ul li {
  margin-bottom: 1.25rem;
  max-width: 18.75rem;
  display: flex; }
  .contact-lists ul li span {
    margin-right: 0.9375rem; }

.author-awards {
  flex: 0 0 45%; }
  @media (max-width: 575.98px) {
    .author-awards {
      margin-top: 2.8125rem; } }
  .author-awards .award-stat {
    margin: 0.3125rem;
    flex: 0 0 45%;
    -webkit-border-radius: 0.3125rem;
    -moz-border-radius: 0.3125rem;
    -ms-border-radius: 0.3125rem;
    border-radius: 0.3125rem; }
    .author-awards .award-stat .display-3 {
      color: #040210; }
    .author-awards .award-stat p {
      margin: 0; }

.ins-featured .course-entry-2 {
  margin-bottom: 1.25rem; }

/* =====================================
Why Us Style */
.choose-txt blockquote {
  border-left: 0.1875rem solid #6045FF;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 1.25rem 1.875rem;
  margin-top: 2.5rem; }

.choose-txt ul {
  margin: 0px;
  padding: 0px;
  list-style: none; }
  .choose-txt ul li {
    line-height: 1.5;
    margin-bottom: 0.9375rem;
    padding-left: 0.9375rem; }
    .choose-txt ul li::before {
      width: 0.25rem;
      height: 0.25rem;
      background-color: #FFA03A;
      -webkit-border-radius: 3.125rem;
      -moz-border-radius: 3.125rem;
      -ms-border-radius: 3.125rem;
      border-radius: 3.125rem;
      top: 40%;
      transform: translateY(-50%); }

.choose-txt h3.display-3, .choose-txt .display-3.h3 {
  margin-bottom: 2.1875rem; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .choose-txt h3.display-3, .choose-txt .display-3.h3 {
      font-size: 1.875rem; } }

@media (max-width: 991.98px) {
  .choose-txt {
    margin-top: 7.5rem; } }

.choose-media .choose-abs {
  position: absolute;
  right: 3.125rem;
  bottom: -6.25rem; }
  @media (max-width: 1199.98px) {
    .choose-media .choose-abs {
      right: 0; } }

.ct-tab {
  display: flex;
  justify-content: space-around;
  border: 0 none;
  border-bottom: 0.0625rem solid #E5E7ED; }
  .ct-tab .nav-link {
    padding: 1.25rem 1.875rem;
    color: #212529; }
    .ct-tab .nav-link::before {
      height: 0.125rem;
      top: auto;
      bottom: -0.0625rem;
      width: 50%;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .ct-tab .nav-link img {
      margin-right: 0.3125rem; }
    .ct-tab .nav-link.active, .ct-tab .nav-link:hover {
      border: 0.0625rem solid transparent;
      background-color: transparent;
      color: #6045FF; }
      .ct-tab .nav-link.active::before, .ct-tab .nav-link:hover::before {
        width: 100%;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100); }
  @media (max-width: 1199.98px) {
    .ct-tab .nav-link {
      padding: 0.9375rem 0.4375rem; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .course-sec-2 .sec-title {
    font-size: 40px; } }

.portfolio-menu .nav .nav-link {
  color: #040210;
  position: relative; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .portfolio-menu .nav .nav-link {
      font-size: 1.0625rem;
      padding: 0 0.375rem; } }
  .portfolio-menu .nav .nav-link.active {
    color: #6045FF; }
  .portfolio-menu .nav .nav-link span.badge, .portfolio-menu .nav .nav-link span.badge-lg {
    background: #6045FF;
    top: -1.1875rem;
    position: absolute;
    right: 0.25rem; }
    .portfolio-menu .nav .nav-link span.badge::after, .portfolio-menu .nav .nav-link span.badge-lg::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 0.625rem solid #6045FF;
      border-right: 0.625rem solid transparent;
      top: 100%;
      left: 0.5rem; }

/* =====================================
Blog Style */
@media (max-width: 991.98px) {
  .blog-sec .sec-intro {
    margin-bottom: 1.25rem; }
  .blog-sec .single-entry {
    margin-bottom: 50px; } }

.entry-meta span,
.entry-meta a {
  color: #727272;
  font-size: 1.0625rem; }

.entry-meta span {
  margin-right: 1.25rem; }
  @media (max-width: 575.98px) {
    .entry-meta span {
      margin-right: 0.3125rem; } }
  .entry-meta span:last-child {
    margin-right: 0; }

.entry-meta i {
  margin-right: 0.4375rem; }

.single-entry {
  height: 31.75rem;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  @media (max-width: 991.98px) {
    .single-entry {
      max-width: 37.5rem; } }
  .single-entry .entry-media {
    height: 100%; }
    .single-entry .entry-media img {
      transform: scale(1);
      transition: transform 0.7s cubic-bezier(0, 0, 0.44, 1.18); }
  .single-entry .entry-content {
    position: absolute;
    background-color: #fff;
    padding: 1.875rem 1.25rem 1.25rem 2.1875rem;
    max-width: calc(100% - 2.5rem);
    left: 0;
    bottom: -1.5625rem;
    overflow: hidden;
    z-index: 2;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
    .single-entry .entry-content .content-header {
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
    .single-entry .entry-content .entry-meta {
      max-width: 80%; }
      .single-entry .entry-content .entry-meta i {
        color: #6045FF; }
    .single-entry .entry-content .entry-hover {
      height: auto;
      max-height: 0;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .single-entry .entry-content .entry-hover p {
        margin-bottom: 1.25rem; }
      .single-entry .entry-content .entry-hover i {
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease; }
      .single-entry .entry-content .entry-hover a:hover i {
        padding-left: 0.5rem; }
    @media (max-width: 1199.98px) {
      .single-entry .entry-content {
        padding: 1.25rem; }
        .single-entry .entry-content h3, .single-entry .entry-content .h3 {
          font-size: 1.625rem; }
        .single-entry .entry-content .entry-meta {
          max-width: calc(100% - 1.25rem); } }
    @media (min-width: 576px) and (max-width: 991.98px) {
      .single-entry .entry-content {
        max-width: calc(100% - 15%); } }
    @media (max-width: 575.98px) {
      .single-entry .entry-content {
        max-width: 100%;
        height: 8.4375rem; }
        .single-entry .entry-content .entry-meta span,
        .single-entry .entry-content .entry-meta span a {
          font-size: 0.8438rem; }
        .single-entry .entry-content h3, .single-entry .entry-content .h3 {
          font-size: 1.375rem; } }
  .single-entry:hover .entry-media img {
    transform: scale(1.06); }
  .single-entry:hover .entry-content {
    -webkit-box-shadow: 1.25rem 1.25rem 4.375rem -0.5rem rgba(4, 2, 16, 0.1);
    -moz-box-shadow: 1.25rem 1.25rem 4.375rem -0.5rem rgba(4, 2, 16, 0.1);
    box-shadow: 1.25rem 1.25rem 4.375rem -0.5rem rgba(4, 2, 16, 0.1); }
    .single-entry:hover .entry-content .entry-hover {
      max-height: 7.8125rem;
      padding-top: 0.625rem;
      transition-timing-function: cubic-bezier(1, 1, 1, 1);
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .single-entry:hover .entry-content {
        height: 20.625rem; } }
    @media (max-width: 1199.98px) {
      .single-entry:hover .entry-content {
        height: 18.75rem; } }
    @media (max-width: 575.98px) {
      .single-entry:hover .entry-content {
        height: 19.6875rem; } }

.single-entry-2 .entry-thumb {
  overflow: hidden; }
  .single-entry-2 .entry-thumb img {
    transform: scale(1);
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s; }

.single-entry-2 .entry-meta span {
  margin-right: 1.25rem; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .single-entry-2 .entry-meta span {
      font-size: 1rem;
      margin-right: 0.25rem; } }

.single-entry-2:hover .entry-thumb img {
  transform: scale(1.1); }

.single-entry-2 .entry-content {
  padding: 1.25rem 3.125rem 2.5rem; }
  .single-entry-2 .entry-content .entry-footer {
    margin-top: 3.125rem; }
    .single-entry-2 .entry-content .entry-footer .social-share a,
    .single-entry-2 .entry-content .entry-footer .btn {
      color: #727272;
      background-color: #F4F5F8; }
      .single-entry-2 .entry-content .entry-footer .social-share a:hover,
      .single-entry-2 .entry-content .entry-footer .btn:hover {
        background-color: #6045FF;
        color: #fff; }
  @media (max-width: 767.98px) {
    .single-entry-2 .entry-content {
      padding: 0.625rem 1.25rem 1.875rem; }
      .single-entry-2 .entry-content .entry-footer {
        margin-top: 2.5rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .single-entry-2 .entry-content {
      padding: 0.9375rem 1.875rem 1.875rem; } }

.blog-standard .single-entry-2 {
  margin-bottom: 2.8125rem; }

.blog-page .single-entry {
  margin-bottom: 1.875rem; }
  .blog-page .single-entry .entry-content {
    background-color: #F4F5F8;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .blog-page .single-entry:hover .entry-content {
    background-color: #fff; }

.single-article .entry-meta span {
  margin-right: 2.1875rem;
  font-size: 1rem; }
  .single-article .entry-meta span i {
    margin-right: 0.3125rem;
    color: #6045FF; }

.single-article h2, .single-article .h2 {
  margin-bottom: 1.875rem; }

.single-article p {
  line-height: 170%; }

.single-article ul {
  padding: 1.25rem 0 1.25rem 1.875rem; }
  .single-article ul li {
    margin-bottom: 0.9375rem; }

.single-article .social-share.white a {
  color: #727272;
  box-shadow: none !important; }
  .single-article .social-share.white a:hover {
    color: #fff; }

.single-article .article-footer .widget_tag_cloud a {
  margin: 0;
  background-color: #fff; }
  .single-article .article-footer .widget_tag_cloud a:hover {
    background-color: #6045FF; }

.list-entry {
  margin-bottom: 1.875rem; }
  .list-entry .entry-media {
    flex: 0 0 23.75rem; }
  .list-entry .entry-content {
    padding: 0.625rem 3.125rem 0.625rem 1.25rem; }
    .list-entry .entry-content .entry-meta span {
      margin-right: 0.625rem; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .list-entry .entry-content {
      padding: 0.625rem 0.9375rem 0.625rem 0.625rem; }
      .list-entry .entry-content h3, .list-entry .entry-content .h3 {
        font-size: 1.75rem; }
    .list-entry .entry-media {
      flex: 0 0 21.875rem; } }
  @media (max-width: 1199.98px) {
    .list-entry .entry-media {
      flex: 0 0 17.1875rem; }
    .list-entry .entry-content {
      padding: 0.625rem; }
      .list-entry .entry-content h3, .list-entry .entry-content .h3 {
        font-size: 1.5625rem; } }
  @media (max-width: 991.98px) {
    .list-entry .entry-media {
      flex: 0 0 20rem; }
    .list-entry .entry-content {
      padding: 0.625rem 1.875rem 0.625rem 1.25rem; }
      .list-entry .entry-content h3, .list-entry .entry-content .h3 {
        font-size: 1.5625rem; } }
  @media (max-width: 767.98px) {
    .list-entry .entry-content {
      padding: 0.625rem 1.25rem 1.25rem 1.25rem; } }

.search-widget {
  padding: 1.25rem;
  border: 0.0625rem solid #E5E7ED;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  border-radius: 0.625rem; }
  .search-widget input {
    background-color: #F4F5F8;
    padding: 0.8125rem 1.25rem; }
  .search-widget .btn {
    right: 0rem;
    padding: 0.8125rem 1.25rem; }
    .search-widget .btn i {
      margin-left: 0; }

/* =====================================
Brand Style */
.brand-sec {
  padding: 5rem 0; }
  .brand-sec .brand-item a {
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-table;
    width: 15rem;
    height: 5.3125rem;
    line-height: 5.3125rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 3.125rem;
    -moz-border-radius: 3.125rem;
    -ms-border-radius: 3.125rem;
    border-radius: 3.125rem;
    border: 0.0625rem solid rgba(255, 255, 255, 0.15); }
    .brand-sec .brand-item a:hover {
      background-color: rgba(255, 255, 255, 0.2); }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .brand-sec .brand-item a {
        width: 13.75rem; } }

/* =====================================
Counter/Stat Style */
@media (max-width: 575.98px) {
  .stat-sec .col {
    flex: auto; }
  .stat-sec .stat-info .display-3 {
    font-size: 2.5rem; } }

.stat-sec .stat-wrap {
  margin-top: -7.1875rem;
  padding: 4.0625rem; }
  @media (max-width: 767.98px) {
    .stat-sec .stat-wrap {
      padding: 2.8125rem 0.9375rem 1.875rem; } }

@media (max-width: 1199.98px) {
  .stat-sec .stat-item {
    margin-bottom: 1.875rem; } }

.stat-sec .stat-item .icon-lg {
  background-color: rgba(255, 255, 255, 0.2); }

.stat-sec .stat-item p {
  margin: 0; }

/* =====================================
Pricing Table Style */
.pricing-card {
  padding: 3.125rem 1.5625rem 2.5rem; }
  .pricing-card .card-head h2, .pricing-card .card-head .h2 {
    line-height: 1;
    font-weight: 600; }
    @media (max-width: 1199.98px) {
      .pricing-card .card-head h2, .pricing-card .card-head .h2 {
        font-size: 2.5rem; } }
    .pricing-card .card-head h2 sub, .pricing-card .card-head .h2 sub {
      font-size: 0.875rem;
      left: -1.25rem;
      color: #727272; }
  .pricing-card .card-head .badge-lg {
    font-weight: 500; }
  .pricing-card .card-body {
    padding: 1.875rem 1.5625rem;
    margin: 1.25rem 0 1.875rem; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .pricing-card .card-body {
        padding: 1.875rem 0.9375rem;
        font-size: 1rem; } }
    .pricing-card .card-body ul {
      margin: 0px;
      padding: 0px;
      list-style: none; }
      .pricing-card .card-body ul li {
        margin-bottom: 1.25rem;
        padding-left: 1.5625rem; }
        .pricing-card .card-body ul li:last-child {
          margin-bottom: 0; }
        .pricing-card .card-body ul li::before {
          content: "\f12d";
          font-family: "feather-icons";
          background-color: transparent;
          top: -0.125rem; }
  .pricing-card .btn {
    color: #212529;
    background-color: #fff; }
  .pricing-card:hover .btn, .pricing-card.active .btn {
    background-color: #6045FF;
    color: #fff; }

/* =====================================
Feature Style */
.feature-card {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0.125rem solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  transform: translateY(0);
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer; }
  .feature-card span {
    color: #fff;
    background-color: #6045FF;
    font-size: 1.5rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .feature-card .feature-txt {
    margin-left: 1.25rem; }
    .feature-card .feature-txt p {
      color: rgba(255, 255, 255, 0.7);
      margin: 0.9375rem 0 0;
      -webkit-transition: all ease 0.5s;
      -moz-transition: all ease 0.5s;
      -ms-transition: all ease 0.5s;
      -o-transition: all ease 0.5s;
      transition: all ease 0.5s; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .feature-card {
      padding: 0.9375rem; }
      .feature-card .display-5, .feature-card .sub-title, .feature-card .widget-title, .feature-card .product-details-tab ul.nav-tabs li .nav-link, .product-details-tab ul.nav-tabs li .feature-card .nav-link {
        font-size: 1.25rem; } }
  @media (max-width: 991.98px) {
    .feature-card {
      padding: 1.25rem; }
      .feature-card .feature-txt {
        margin: 1.25rem 0 0; } }
  .feature-card:hover {
    background-color: #ffc107;
    transform: translateY(-0.625rem); }
    .feature-card:hover span {
      background-color: #fff;
      color: #ffc107; }
    .feature-card:hover .feature-txt p {
      color: #fff; }

/* =====================================
Process Style */
.process-card .process-step {
  width: 11.875rem;
  height: 11.875rem;
  line-height: 11.875rem;
  -webkit-box-shadow: 0rem 1.875rem 7.5rem 0rem rgba(4, 2, 16, 0.1);
  -moz-box-shadow: 0rem 1.875rem 7.5rem 0rem rgba(4, 2, 16, 0.1);
  box-shadow: 0rem 1.875rem 7.5rem 0rem rgba(4, 2, 16, 0.1); }
  .process-card .process-step span {
    width: 2.5rem;
    right: 0.625rem;
    top: 0.625rem;
    font-weight: 600;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.125rem;
    -webkit-box-shadow: 0rem 0.75rem 1.625rem 0rem rgba(96, 69, 255, 0.5);
    -moz-box-shadow: 0rem 0.75rem 1.625rem 0rem rgba(96, 69, 255, 0.5);
    box-shadow: 0rem 0.75rem 1.625rem 0rem rgba(96, 69, 255, 0.5); }

.process-card h4, .process-card .h4, .process-card .product-details .product-price, .product-details .process-card .product-price {
  margin-top: 40px; }

.apply-card {
  padding: 2.5rem 3.125rem; }
  .apply-card h3, .apply-card .h3 {
    font-size: 3rem;
    color: #fff; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .apply-card h3, .apply-card .h3 {
        font-size: 2.5rem; } }
    @media (max-width: 1199.98px) {
      .apply-card h3, .apply-card .h3 {
        font-size: 2rem; } }
  .apply-card .btn {
    margin-top: 1.875rem; }
  @media (max-width: 991.98px) {
    .apply-card {
      margin-bottom: 1.25rem; } }
  @media (max-width: 575.98px) {
    .apply-card {
      padding: 1.875rem; }
      .apply-card h3, .apply-card .h3 {
        font-size: 1.65rem; } }

/* =====================================
Contact Style */
.contact-sec {
  background-color: #1D1354; }
  .contact-sec .contact-bg {
    width: 50%;
    left: 0;
    height: 100%;
    top: 0;
    background-repeat: no-repeat; }
  .contact-sec .contact-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18%; }
  .contact-sec .contact-txt {
    max-width: 24.0625rem;
    margin-left: auto;
    margin-right: 0; }
    .contact-sec .contact-txt p {
      color: rgba(255, 255, 255, 0.6); }
  @media (max-width: 767.98px) {
    .contact-sec .contact-bg {
      display: none; }
    .contact-sec .contact-txt {
      margin: 0 0 40px;
      max-width: 100%; }
      .contact-sec .contact-txt .sec-title {
        margin-bottom: 40px; } }

.contact-card-sec .card {
  padding: 2.8125rem; }
  .contact-card-sec .card span {
    font-size: 1.375rem; }
  .contact-card-sec .card p {
    margin: 0; }
  .contact-card-sec .card ul {
    margin: 0px;
    padding: 0px;
    list-style: none; }

.google-map {
  height: 31.25rem;
  width: 100%;
  margin-bottom: 0; }

.contact-form input, .profile-form input,
.contact-form textarea,
.profile-form textarea {
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  padding: 1rem 1.875rem; }

.contact-form input, .profile-form input,
.contact-form textarea,
.profile-form textarea {
  padding-left: 2.6875rem; }


.contact-form textarea,
.profile-form textarea {
  padding-left: 1.25rem; }

.contact-form .form-group, .profile-form .form-group {
  margin-bottom: 1.25rem;
  font-size: 1.0625rem;
  position: relative; }
  .contact-form .form-group i, .profile-form .form-group i {
    position: absolute;
    top: 1.1875rem;
    left: 1.5625rem;
    font-size: 1.125rem;
    color: rgba(114, 114, 114, 0.5); }

.contact-form .nice-select, .profile-form .nice-select {
  background-color: #fff;
  width: 100%;
  height: 3.5625rem;
  line-height: 3.5625rem;
  color: rgba(114, 114, 114, 0.5); }

/* =====================================
Accordion, FAQ Style */
.faq-sec .anim-img {
  top: auto;
  bottom: 15%; }

.faq-sec .anim-right {
  top: 15%; }

.accordion-1 .accordion-item {
  border: 0 none;
  margin-bottom: 1.875rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  border-radius: 0.625rem;
  -webkit-box-shadow: 0rem 0.75rem 3.125rem -0.5rem rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0rem 0.75rem 3.125rem -0.5rem rgba(0, 0, 0, 0.08);
  box-shadow: 0rem 0.75rem 3.125rem -0.5rem rgba(0, 0, 0, 0.08); }
  .accordion-1 .accordion-item .accordion-body p:last-child {
    margin: 0; }
  .accordion-1 .accordion-item .accordion-header {
    padding: 0rem;
    -webkit-border-radius: 0.625rem;
    -moz-border-radius: 0.625rem;
    -ms-border-radius: 0.625rem;
    border-radius: 0.625rem; }
  .accordion-1 .accordion-item .accordion-button {
    border-bottom: none;
    background-color: transparent;
    padding: 1.375rem 1.875rem 1.375rem 4.375rem;
    background-color: #E5E7ED;
    box-shadow: none;
    border-radius: 0.625rem !important; }
    .accordion-1 .accordion-item .accordion-button:after {
      background-image: none;
      font-family: "feather-icons";
      position: absolute;
      left: 0.625rem;
      content: "\f1bf";
      font-size: 18.4px;
      color: #fff;
      background-color: #6045FF;
      text-align: center;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      -webkit-border-radius: 0.3125rem;
      -moz-border-radius: 0.3125rem;
      -ms-border-radius: 0.3125rem;
      border-radius: 0.3125rem;
      transform: rotate(0); }
    .accordion-1 .accordion-item .accordion-button:focus {
      box-shadow: none; }
  .accordion-1 .accordion-item .accordion-body {
    padding: 1.5625rem 4.375rem;
    background-color: #fff;
    -webkit-border-radius: 0.625rem;
    -moz-border-radius: 0.625rem;
    -ms-border-radius: 0.625rem;
    border-radius: 0.625rem; }
    .accordion-1 .accordion-item .accordion-body::before {
      height: 0.0625rem;
      background-color: #E5E7ED;
      width: calc(100% - 17%);
      left: 50%;
      transform: translateX(-50%); }
    @media (max-width: 575.98px) {
      .accordion-1 .accordion-item .accordion-body {
        padding: 1.5625rem 1.875rem 1.5625rem 4.375rem; } }

.accordion-1 .accordion-button:not(.collapsed) {
  background-color: #fff; }
  .accordion-1 .accordion-button:not(.collapsed):after {
    content: "\f1a2";
    background-color: #FFA03A; }

.accordion .accordion-item {
  margin-bottom: 1.25rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #E5E7ED; }
  .accordion .accordion-item .accordion-header {
    padding: 16px; }

.accordion .accordion-body {
  padding-top: 0; }

.accordion .accordion-button {
  padding: 0; }
  .accordion .accordion-button:focus {
    box-shadow: none; }

.accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none; }

.accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0rem; }

.faq-sec.bg-shade .accordion-button {
  background-color: #fff; }

.accordion-2 .accordion-item {
  box-shadow: none;
  border: 0.0625rem solid #E5E7ED;
  margin-bottom: 1.25rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  border-radius: 0.625rem; }
  .accordion-2 .accordion-item button.accordion-button {
    background-color: transparent;
    padding: 1.25rem 1.875rem;
    font-size: 24px;
    border-bottom: 0.0625rem solid #E5E7ED;
    color: #6045FF; }
    @media (max-width: 575.98px) {
      .accordion-2 .accordion-item button.accordion-button {
        padding: 0.9375rem;
        font-size: 19.2px; }
        .accordion-2 .accordion-item button.accordion-button sub {
          display: none; } }
    .accordion-2 .accordion-item button.accordion-button.collapsed {
      border-bottom: 0 none;
      color: #040210; }
    .accordion-2 .accordion-item button.accordion-button:after {
      position: absolute;
      right: 1.25rem;
      content: "\f1bf";
      font-family: 'feather-icons'; }
    .accordion-2 .accordion-item button.accordion-button sub {
      font-size: 0.875rem; }
  .accordion-2 .accordion-item .accordion-button:not(.collapsed):after {
    content: "\f1a2"; }
  .accordion-2 .accordion-item .accordion-body {
    padding: 0.5rem; }
    @media (max-width: 575.98px) {
      .accordion-2 .accordion-item .accordion-body ul {
        padding: 0.625rem 0.3125rem 0; } }

.lesson-items li img {
  width: 1.125rem;
  height: 1.125rem;
  margin: -0.25rem 0.1875rem 0; }

/* =====================================
Post Comment Style */
.post-comments > ol {
  padding-left: 0; }

.post-comments ol {
  list-style: none; }

.post-comments .comment-entry {
  position: relative; }
  .post-comments .comment-entry .comment-thumb {
    flex: 0 0 5.625rem; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .post-comments .comment-entry .comment-thumb {
        margin-right: 1.25rem; } }
  .post-comments .comment-entry .commentor::before {
    width: 0;
    height: 0;
    background-color: transparent;
    border-top: 0.625rem solid transparent;
    border-right: 1rem solid #F4F5F8;
    border-bottom: 0.625rem solid transparent;
    left: -1rem;
    top: 1.875rem; }
  .post-comments .comment-entry .commentor p {
    margin: 0; }
  @media (max-width: 575.98px) {
    .post-comments .comment-entry .comment-thumb {
      margin-bottom: 0.9375rem; }
    .post-comments .comment-entry .commentor::before {
      top: -0.625rem;
      left: 0.9375rem; } }

.comment-form input, .comment-form textarea {
  background-color: #F4F5F8; }

/* =====================================
Shop Style */
.product-entry .product-actions {
  bottom: 2.5rem;
  right: 0.9375rem;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .product-entry .product-actions a {
    display: block;
    margin-bottom: 0.9375rem; }

.product-entry .product-price {
  color: #212529; }
  .product-entry .product-price del {
    font-weight: 400;
    margin-left: 0.3125rem;
    color: rgba(114, 114, 114, 0.5);
    font-size: 1rem; }

.product-entry .btn {
  padding: 0.75rem 1.25rem;
  margin-top: 1.5625rem; }

.product-entry:hover .btn {
  background-color: #6045FF;
  color: #fff; }

.product-entry:hover .product-actions {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); }

.shop-sidebar .price-widget .widget-inner {
  padding: 1.875rem; }

.shop-sidebar .price-widget .btn {
  font-size: 0.9375rem;
  padding: 0.375rem 1.25rem 0.3125rem; }

.noUi-horizontal {
  height: 0.375rem;
  border: 0 none;
  box-shadow: none;
  background-color: #E5E7ED; }
  .noUi-horizontal .noUi-connect {
    background: #6045FF; }
  .noUi-horizontal .noUi-handle {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #6045FF;
    -webkit-border-radius: 3.125rem;
    -moz-border-radius: 3.125rem;
    -ms-border-radius: 3.125rem;
    border-radius: 3.125rem;
    top: -0.5rem;
    border: 0 none;
    box-shadow: none; }
    .noUi-horizontal .noUi-handle:after, .noUi-horizontal .noUi-handle:before {
      display: none; }

.cart-cta .btn.btn-primary {
  padding: 1rem 2.8125rem;
  width: 100%; }

.cart-counter {
  border: 0.0625rem solid #E5E7ED;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  padding: 0rem 1.25rem;
  color: #040210;
  font-size: 1.25rem; }
  .cart-counter > div {
    cursor: pointer; }

.cart-number {
  max-width: 10.625rem; }
  .cart-number input {
    text-align: center;
    border: 0 none;
    background-color: transparent;
    color: #040210;
    padding: 0.8125rem; }
    .cart-number input:focus {
      box-shadow: none; }

.product-details .product-price {
  margin-bottom: 1.25rem; }
  .product-details .product-price del {
    color: rgba(114, 114, 114, 0.5);
    font-size: 1.125rem;
    font-weight: 400; }

.product-details .cart-cta button.btn i {
  margin-left: -0.4375rem; }

.product-details .product-meta {
  margin: 1.875rem 0; }
  .product-details .product-meta p {
    margin: 0 0 0.9375rem; }
    .product-details .product-meta p strong {
      color: #000; }
  .product-details .product-meta strong {
    width: 4.375rem;
    display: inline-block; }

.product-details #countdown {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  min-width: 14.6875rem; }
  .product-details #countdown span {
    position: relative;
    text-align: center;
    color: #6045FF;
    width: 25%; }
    .product-details #countdown span::after {
      left: auto;
      right: -0.125rem;
      top: 0;
      position: absolute;
      color: #6045FF;
      font-size: 1.125rem; }
    .product-details #countdown span::before {
      position: absolute;
      right: 80%;
      color: #6045FF;
      content: ":";
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
    .product-details #countdown span:first-child::before {
      content: " "; }
  .product-details #countdown .days:after {
    content: "d"; }
  .product-details #countdown .hours:after {
    content: "h"; }
  .product-details #countdown .minutes:after {
    content: " m"; }
  .product-details #countdown .seconds:after {
    content: "s"; }

.product-countdown {
  border: 0.0625rem solid #6045FF; }
  .product-countdown span {
    color: #6045FF; }
  .product-countdown .icon, .product-countdown .icon-sm, .product-countdown .icon-lg, .product-countdown .icon-xs, .product-countdown .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .product-countdown a {
    margin-right: 0.625rem; }

.product-details-tab ul.nav-tabs {
  padding: 0; }
  .product-details-tab ul.nav-tabs li .nav-link {
    padding: 1.25rem 2.1875rem;
    border: 0 none;
    text-transform: capitalize; }
    .product-details-tab ul.nav-tabs li .nav-link.active {
      border-bottom: 0.125rem solid #6045FF; }

.tab-desc ul li {
  margin-bottom: 0.9375rem; }

.recent-ratings {
  max-width: 53.125rem; }
  .recent-ratings .comment-entry {
    margin-bottom: 1.25rem; }

.ratings-form .form-group {
  margin-bottom: 1.25rem; }
  .ratings-form .form-group input,
  .ratings-form .form-group textarea {
    background-color: #F4F5F8; }
  .ratings-form .form-group label {
    margin-bottom: 0.3125rem;
    font-weight: 500; }

.ratings-form button {
  margin-top: 1.25rem; }

.product-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

/* ==================================
 Cart Style
*/
.woocommerce {
  background-color: rgba(114, 114, 114, 0.5);
  padding: 1.875rem; }
  @media (max-width: 575.98px) {
    .woocommerce {
      padding: 0.625rem; }
      .woocommerce .woocommerce-cart-form {
        overflow-x: auto;
        margin-bottom: 1.875rem;
        overflow-y: hidden; } }
  .woocommerce .shop_table {
    text-align: left;
    width: 100%;
    border-collapse: separate;
    -webkit-border-radius: 0.625rem;
    -moz-border-radius: 0.625rem;
    -ms-border-radius: 0.625rem;
    border-radius: 0.625rem;
    border-spacing: 0 1.25rem; }
    .woocommerce .shop_table thead tr th {
      padding: 0.9375rem 1.25rem;
      background-color: #fff;
      font-size: 1.375rem;
      font-weight: 600;
      color: #040210; }
      @media (max-width: 575.98px) {
        .woocommerce .shop_table thead tr th {
          font-size: 1.125rem;
          padding: 1.25rem; } }
      .woocommerce .shop_table thead tr th:first-child, .woocommerce .shop_table thead tr th:last-child {
        -webkit-border-radius: 0.625rem;
        -moz-border-radius: 0.625rem;
        -ms-border-radius: 0.625rem;
        border-radius: 0.625rem; }
    .woocommerce .shop_table tr {
      background-color: #fff;
      color: #FFA03A; }
      .woocommerce .shop_table tr td:first-child {
        border-left-style: solid;
        border-top-left-radius: 0.625rem;
        border-bottom-left-radius: 0.625rem; }
      .woocommerce .shop_table tr td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 0.625rem;
        border-top-right-radius: 0.625rem; }
    .woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td {
      font-weight: 600;
      align-items: center;
      vertical-align: middle; }
      @media (max-width: 575.98px) {
        .woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td {
          font-size: 0.875rem; } }
      .woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td.product-thumbnail {
        width: 15%;
        padding: 0.9375rem 0 0.9375rem 0.9375rem; }
        @media (max-width: 575.98px) {
          .woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td.product-thumbnail {
            padding: 0.3125rem 0 0.3125rem 0.3125rem; }
            .woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td.product-thumbnail img {
              max-width: 100%; } }
  .woocommerce th {
    font-weight: 700;
    padding: 0.5625rem 0.75rem;
    line-height: 1.5em; }
  .woocommerce td.product-remove a.remove {
    height: 1.5625rem;
    width: 1.5625rem;
    line-height: 1.5625rem;
    background: rgba(96, 69, 255, 0.1);
    display: inline-block;
    text-align: center;
    -webkit-border-radius: 3.125rem;
    -moz-border-radius: 3.125rem;
    -ms-border-radius: 3.125rem;
    border-radius: 3.125rem; }
    .woocommerce td.product-remove a.remove i {
      color: #727272;
      font-weight: 600;
      font-size: 0.9375rem;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; }
    .woocommerce td.product-remove a.remove:hover {
      background-color: #6045FF; }
      .woocommerce td.product-remove a.remove:hover i {
        color: #fff; }
  @media (max-width: 575.98px) {
    .woocommerce .table.shop_table.cart > tbody > tr > td.product-price,
    .woocommerce .table.shop_table.cart > tbody > tr > th.product-price,
    .woocommerce .table.shop_table.cart > tfoot > tr > td.product-price,
    .woocommerce .table.shop_table.cart > tfoot > tr > th.product-price,
    .woocommerce .table.shop_table.cart > thead > tr > td.product-price,
    .woocommerce .table.shop_table.cart > thead > tr > th.product-price,
    .woocommerce table.shop_table.cart > tbody > tr > td.product-price,
    .woocommerce table.shop_table.cart > tbody > tr > th.product-price,
    .woocommerce table.shop_table.cart > tfoot > tr > td.product-price,
    .woocommerce table.shop_table.cart > tfoot > tr > th.product-price,
    .woocommerce table.shop_table.cart > thead > tr > td.product-price,
    .woocommerce table.shop_table.cart > thead > tr > th.product-price {
      display: none; } }
  .woocommerce .coupon {
    position: relative;
    flex: 0 0 50%; }
    .woocommerce .coupon input {
      border: 0.0625rem solid #E5E7ED;
      padding: 0.875rem;
      font-size: 0.9375rem; }
      .woocommerce .coupon input:focus {
        outline: 0 none;
        box-shadow: none; }
    .woocommerce .coupon .btn {
      position: absolute;
      right: -0.125rem;
      border-radius: 0.3125rem;
      top: -0.0625rem;
      font-size: 0.9375rem;
      height: 101%;
      line-height: 1; }
    @media (max-width: 575.98px) {
      .woocommerce .coupon {
        flex: 0 0 100%; } }

.woocommerce .shop_table tr:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.cart-quanty {
  display: flex;
  justify-content: space-between;
  width: 6.25rem;
  padding: 0.25rem 0.8125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  font-size: 0.9375rem;
  background-color: #F4F5F8; }
  .cart-quanty input {
    border: 0 none;
    padding: 0;
    color: #040210;
    font-weight: 600;
    background: transparent;
    text-align: center; }
  .cart-quanty i {
    cursor: pointer; }

.cart-totals {
  padding: 1.25rem 1.875rem 1.875rem; }
  @media (max-width: 575.98px) {
    .cart-totals {
      margin-top: 1.875rem; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .cart-totals {
      margin-top: 1.875rem; } }
  .cart-totals table {
    width: 100%;
    border-spacing: 0 0.625rem;
    border-collapse: separate; }
    .cart-totals table th {
      color: #040210; }
    .cart-totals table td {
      font-weight: 500; }
    .cart-totals table tbody tr td {
      padding: 0.9375rem 0.625rem;
      text-align: right; }
    .cart-totals table tr:first-child {
      font-weight: 700; }
    .cart-totals table tr.product-list {
      background: #fff; }
      .cart-totals table tr.product-list th,
      .cart-totals table tr.product-list td {
        padding-left: 1.25rem;
        padding: 0.625rem 0.9375rem;
        color: #727272; }
      .cart-totals table tr.product-list th {
        border-top-left-radius: 0.625rem;
        border-bottom-left-radius: 0.625rem; }
      .cart-totals table tr.product-list td {
        border-bottom-right-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
        font-size: 1.25rem; }

/* ==================================
Checkout Style
*/
@media (max-width: 575.98px) {
  .order-summery {
    margin-top: 1.875rem; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .order-summery {
    margin-top: 1.875rem; } }

.coupon-wrap input {
  flex: 0 0 65%; }

@media (max-width: 991.98px) {
  .coupon-wrap {
    margin-bottom: 1.25rem; }
    .coupon-wrap input {
      flex: 0 0 55%; } }

.checkout-coupon h6, .checkout-coupon .h6, .checkout-coupon .payment-option .accordion-button, .payment-option .checkout-coupon .accordion-button, .checkout-coupon .course-tab ul.nav li button, .course-tab ul.nav li .checkout-coupon button {
  margin-bottom: 0; }
  .checkout-coupon h6 button, .checkout-coupon .h6 button, .checkout-coupon .payment-option .accordion-button button, .payment-option .checkout-coupon .accordion-button button, .checkout-coupon .course-tab ul.nav li button button, .course-tab ul.nav li .checkout-coupon button button {
    border: 0;
    color: #FFA03A;
    background: transparent; }

.checkout-coupon form {
  max-width: 37.5rem; }
  .checkout-coupon form .btn {
    border-radius: 0.3125rem; }

form.form.checkout-form label {
  margin-bottom: 0.3125rem;
  font-weight: 500;
  color: #040210;
  font-size: 1.0625rem; }

form.form.checkout-form .nice-select {
  display: block !important;
  width: 100%;
  border: 0.0625rem solid #E5E7ED;
  background-color: #fff;
  font-size: 1rem;
  height: 3.5rem;
  line-height: 3.5rem; }

form.form.checkout-form input {
  font-size: 1rem;
  margin-bottom: 1.875rem; }

form.form.checkout-form input[type="checkbox"] {
  width: auto; }

.payment-option {
  margin-bottom: 1.875rem; }
  .payment-option .accordion-button:after {
    right: 0rem;
    background: transparent;
    border-radius: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    box-shadow: none; }
  .payment-option .accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-color: transparent;
    transform: rotate(0deg); }

/* =====================================
My Account Style */
.signup-sec::before {
  width: 45%; }
  @media (max-width: 767.98px) {
    .signup-sec::before {
      width: 100%;
      height: 46%; } }
  @media (max-width: 575.98px) {
    .signup-sec::before {
      height: 37%; } }

.signup-sec .anim-img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 6.5625rem;
  height: 4.5rem; }

@media (min-width: 1200px) {
  .signup-sec .signup-thumb {
    margin-left: -20%; } }

.login-form, .signup-form {
  max-width: 32.5rem;
  margin-left: auto;
  margin-right: 0; }
  .login-form::before, .signup-form::before {
    background: url("../images/award-1.png") no-repeat left top;
    left: -25%;
    top: -1.25rem;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50); }
  .login-form::after, .signup-form::after {
    position: absolute;
    content: "";
    background: url("../images/award-2.png") no-repeat right bottom;
    width: 7.875rem;
    height: 9.6875rem;
    bottom: -8%;
    right: -21%;
    z-index: -1;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50); }
  .login-form .form-group, .signup-form .form-group {
    margin-bottom: 0.9375rem; }
    .login-form .form-group input, .signup-form .form-group input {
      padding-left: 3.125rem; }
    .login-form .form-group span, .signup-form .form-group span {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 1.5625rem;
      color: rgba(114, 114, 114, 0.5); }
    @media (max-width: 575.98px) {
      .login-form .form-group input, .signup-form .form-group input {
        padding-left: 2.5rem; }
      .login-form .form-group span, .signup-form .form-group span {
        left: 0.9375rem; } }
  .login-form .form-footer .btn, .signup-form .form-footer .btn {
    margin-bottom: 0.9375rem; }
    .login-form .form-footer .btn:hover::before, .signup-form .form-footer .btn:hover::before {
      display: none; }
    .login-form .form-footer .btn img, .signup-form .form-footer .btn img {
      margin-right: 0.625rem; }
  .login-form .form-footer > p, .signup-form .form-footer > p {
    margin-top: 1.25rem; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .login-form::before, .signup-form::before {
      left: -15%; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .login-form, .signup-form {
      max-width: 26.25rem; }
      .login-form::before, .signup-form::before {
        left: -22%; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .login-form, .signup-form {
      max-width: 21.25rem; }
      .login-form::before, .signup-form::before {
        left: -11%;
        top: -6.25rem; } }
  @media (max-width: 767.98px) {
    .login-form, .signup-form {
      margin-top: 5rem; } }

.signup-form::before {
  background: url("../images/shape-5.png") no-repeat left top; }

.signup-form::after {
  position: absolute;
  content: "";
  background: url("../images/shape-3.png") no-repeat right bottom/contain;
  width: 9.5625rem; }

.alter.overly, .alter.color, .alter.color-info, .alter.btn, .social-share-alt a.alter, blockquote.alter, .marquee p span.alter, .alter.navbar-line, .top-bar .quick-connect span.alter:last-child, .top-bar .info-right > span.alter, .header-actions a.alter.text-reset.user-account, .alter.btn-close, section.alter.promo-sec, section.promo-sec nav.alter, .category-search .alter.search-group, .alter.newsletter, .footer-widget .alter.cta-link, .swiper-pagination span.alter.swiper-pagination-bullet-active, .recent-entry .alter.rc-entry, .instagram-widget .widget-inner .insta-wrap a.alter, .alter.banner-bottom, .banner-stat .alter.single-stat, .alter.subscribe-form, .alter.course-sec, .alter.course-footer, .alter.course-preview, .video-block .alter.cover-video, .video-block-sm .alter.cover-video, .play-btn span.alter, .about-sec2 .alter.about-lists, .apply-process .apply-stat ul li.alter, .alter.review-2, .alter.instructor-sec-2, .choose-txt ul li.alter, .ct-tab .alter.nav-link, .pricing-card .card-body ul li.alter, .accordion-1 .accordion-item .alter.accordion-body, .post-comments .comment-entry .alter.commentor, .alter.signup-sec, .alter.login-form, .alter.signup-form, .course-tab ul.nav li button.alter, .alter.profile-info {
  text-align: center;
  padding: 0.3125rem 0; }
  .alter.overly::before, .alter.color::before, .alter.color-info::before, .alter.btn::before, .social-share-alt a.alter::before, blockquote.alter::before, .marquee p span.alter::before, .alter.navbar-line::before, .top-bar .quick-connect span.alter:last-child::before, .top-bar .info-right > span.alter::before, .header-actions a.alter.text-reset.user-account::before, .alter.btn-close::before, section.alter.promo-sec::before, section.promo-sec nav.alter::before, .category-search .alter.search-group::before, .alter.newsletter::before, .footer-widget .alter.cta-link::before, .swiper-pagination span.alter.swiper-pagination-bullet-active::before, .recent-entry .alter.rc-entry::before, .instagram-widget .widget-inner .insta-wrap a.alter::before, .alter.banner-bottom::before, .banner-stat .alter.single-stat::before, .alter.subscribe-form::before, .alter.course-sec::before, .alter.course-footer::before, .alter.course-preview::before, .video-block .alter.cover-video::before, .video-block-sm .alter.cover-video::before, .play-btn span.alter::before, .about-sec2 .alter.about-lists::before, .apply-process .apply-stat ul li.alter::before, .alter.review-2::before, .alter.instructor-sec-2::before, .choose-txt ul li.alter::before, .ct-tab .alter.nav-link::before, .pricing-card .card-body ul li.alter::before, .accordion-1 .accordion-item .alter.accordion-body::before, .post-comments .comment-entry .alter.commentor::before, .alter.signup-sec::before, .alter.login-form::before, .alter.signup-form::before, .course-tab ul.nav li button.alter::before, .alter.profile-info::before {
    height: 0.0625rem;
    top: 1.125rem;
    background-color: #E5E7ED; }
  .alter.overly p, .alter.color p, .alter.color-info p, .alter.btn p, .social-share-alt a.alter p, blockquote.alter p, .marquee p span.alter p, .alter.navbar-line p, .top-bar .quick-connect span.alter:last-child p, .top-bar .info-right > span.alter p, .header-actions a.alter.text-reset.user-account p, .alter.btn-close p, section.alter.promo-sec p, section.promo-sec nav.alter p, .category-search .alter.search-group p, .alter.newsletter p, .footer-widget .alter.cta-link p, .swiper-pagination span.alter.swiper-pagination-bullet-active p, .recent-entry .alter.rc-entry p, .instagram-widget .widget-inner .insta-wrap a.alter p, .alter.banner-bottom p, .banner-stat .alter.single-stat p, .alter.subscribe-form p, .alter.course-sec p, .alter.course-footer p, .alter.course-preview p, .video-block .alter.cover-video p, .video-block-sm .alter.cover-video p, .play-btn span.alter p, .about-sec2 .alter.about-lists p, .apply-process .apply-stat ul li.alter p, .alter.review-2 p, .alter.instructor-sec-2 p, .choose-txt ul li.alter p, .ct-tab .alter.nav-link p, .pricing-card .card-body ul li.alter p, .accordion-1 .accordion-item .alter.accordion-body p, .post-comments .comment-entry .alter.commentor p, .alter.signup-sec p, .alter.login-form p, .alter.signup-form p, .course-tab ul.nav li button.alter p, .alter.profile-info p {
    display: inline-block;
    background: #fff;
    padding: 0 0.9375rem; }

.error-txt {
  max-width: 39.375rem;
  margin: 1.875rem auto 0; }

/* =====================================
Lesson Style */
.lession-sidebar {
  height: 100%; }

.lesson-container {
  overflow-x: hidden;
  overflow-y: auto;
  width: 27rem;
  height: 100vh; }
  .lesson-container .accordion-2 .accordion-item button.accordion-button {
    font-size: 1.2rem;
    padding: 0.75rem; }
    .lesson-container .accordion-2 .accordion-item button.accordion-button:after {
      right: 0.625rem; }
  .lesson-container .lesson-items li {
    margin-bottom: 0.625rem; }

.lession-content .nav {
  margin-bottom: 1.875rem; }
  .lession-content .nav li {
    margin-right: 1.25rem; }
    .lession-content .nav li button {
      background-color: #F4F5F8;
      color: #4c4343;
      padding: 0.625rem 1.875rem;
      font-size: 1.25rem;
      font-weight: 600;
      border-radius: 2.5rem; }
      .lession-content .nav li button.active {
        background-color: #6045FF;
        color: #fff; }

.ratings-details {
  flex: 0 0 70%; }
  .ratings-details .d-flex {
    margin-bottom: 1.25rem; }
  .ratings-details span img {
    width: 1.5rem; }
  .ratings-details .progress {
    flex: 0 0 80%;
    margin-left: 1.25rem; }

/* =====================================
Dashboard Style */
.dash-cover-bg {
  min-height: 18.75rem;
  position: relative; }

.dashbaord-promo {
  padding: 6.25rem 0; }

.dashbaord-cover .dash-cover-bg {
  margin-top: -16.875rem; }

.dash-cover-info {
  position: absolute;
  bottom: -1.5625rem;
  z-index: 2;
  width: 100%;
  padding: 0 3.125rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%); }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .dash-cover-info {
      padding: 0 1.5625rem; } }
  @media (max-width: 575.98px) {
    .dash-cover-info {
      padding: 0 2.1875rem; } }
  .dash-cover-info .ava-wrap .avatar {
    border: 0.4375rem solid #fff; }
  .dash-cover-info .ava-wrap a.icon-xs, .dash-cover-info .ava-wrap .teacher-entry .teacher-socials li a, .teacher-entry .teacher-socials li .dash-cover-info .ava-wrap a {
    position: absolute;
    right: 0;
    bottom: 0.5rem;
    border: 0.0625rem solid #E5E7ED;
    color: #040210; }
  @media (max-width: 767.98px) {
    .dash-cover-info .ava-wrap {
      margin-bottom: 1.25rem; }
      .dash-cover-info .ava-wrap .avatar img {
        width: 5.625rem !important; } }
  .dash-cover-info .ava-meta span {
    margin-right: 0.625rem;
    font-size: 1rem; }
    .dash-cover-info .ava-meta span i,
    .dash-cover-info .ava-meta span img {
      color: #fff;
      margin-right: 0.3125rem; }
    .dash-cover-info .ava-meta span img {
      margin-top: -0.25rem; }
  .dash-cover-info .btn-sm, .dash-cover-info .btn-group-sm > .btn {
    font-size: 0.9375rem;
    padding: 0.625rem 1.375rem; }

.dashboard-sidebar {
  padding: 1.875rem;
  background-color: #fff; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .dashboard-sidebar {
      padding: 1.875rem 0.9375rem; } }
  @media (max-width: 991.98px) {
    .dashboard-sidebar {
      margin-bottom: 1.875rem; } }
  .dashboard-sidebar .grettings {
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    font-weight: 600; }

.dashboard-nav .nav {
  display: block; }
  .dashboard-nav .nav li {
    display: block; }
    .dashboard-nav .nav li .nav-link {
      padding: 0.75rem 0.3125rem;
      display: block;
      font-weight: 500;
      font-size: 1rem;
      font-family: "Lexend", sans-serif;
      color: #727272; }
      .dashboard-nav .nav li .nav-link.active {
        color: #6045FF; }
      .dashboard-nav .nav li .nav-link i {
        font-size: 1.125rem; }
      .dashboard-nav .nav li .nav-link span {
        margin-left: 0.625rem; }
    .dashboard-nav .nav li.border-bottom {
      height: 0.5625rem;
      margin-bottom: 0.4375rem; }

.stat-counter {
  padding: 0.9375rem 1.25rem;
  background-color: #fff;
  border: 0.0625rem solid #E5E7ED; }
  .stat-counter img {
    max-width: 2.5rem;
    max-height: 2.3125rem; }
  .stat-counter p {
    margin: 0;
    font-size: 0.9375rem; }

.dashboard-sec {
  background-color: #fff;
  padding: 1.875rem;
  border: 0.0625rem solid #E5E7ED;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px; }
  .dashboard-sec.course-dash {
    background-color: #F4F5F8;
    box-shadow: none;
    border: 0 none;
    padding: 0; }
  .dashboard-sec .pager a {
    background-color: #fff; }
    .dashboard-sec .pager a:hover {
      background-color: #6045FF; }
  .dashboard-sec .course-entry-3 h3.sub-title, .dashboard-sec .course-entry-3 .sub-title.h3 {
    font-size: 1.375rem; }
  .dashboard-sec .course-entry-3 .ratings img {
    width: 1.0625rem; }
  .dashboard-sec .course-entry-3 .btn.btn-sm, .dashboard-sec .course-entry-3 .btn-group-sm > .btn {
    padding: 0.5625rem 0.3125rem; }
  .dashboard-sec .course-entry-3 .course-footer:before {
    left: 54%; }
  .dashboard-sec .table > :not(caption) > * > * {
    padding: 11.2px 16px; }
  .dashboard-sec .table .display-6, .dashboard-sec .table .ct-tab .nav-link, .ct-tab .dashboard-sec .table .nav-link {
    font-size: 17.6px; }
  .dashboard-sec .table thead tr th {
    color: #040210;
    border: 0 none;
    background: rgba(96, 69, 255, 0.1); }
  .dashboard-sec .table tbody tr td {
    vertical-align: middle;
    font-size: 1rem; }
    .dashboard-sec .table tbody tr td p {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 400; }
  .dashboard-sec .course-entry-3 .single-progress .progress {
    height: 0.3125rem; }
    .dashboard-sec .course-entry-3 .single-progress .progress .progress-bar {
      background-color: rgba(96, 69, 255, 0.75); }
    .dashboard-sec .course-entry-3 .single-progress .progress .progress-number {
      position: absolute;
      font-size: 0.875rem;
      top: 0;
      line-height: 1.25;
      right: 0;
      z-index: 1; }
  .dashboard-sec .course-entry-3 .single-progress h6, .dashboard-sec .course-entry-3 .single-progress .h6, .dashboard-sec .course-entry-3 .single-progress .payment-option .accordion-button, .payment-option .dashboard-sec .course-entry-3 .single-progress .accordion-button, .dashboard-sec .course-entry-3 .single-progress .course-tab ul.nav li button, .course-tab ul.nav li .dashboard-sec .course-entry-3 .single-progress button,
  .dashboard-sec .course-entry-3 .single-progress span {
    font-size: 0.75rem;
    text-transform: uppercase; }
  .dashboard-sec .course-entry-3 .course-footer a {
    font-size: 0.9375rem; }

.profile-cover-wrap .profile-cover-bg {
  min-height: 15.625rem; }

.profile-cover-wrap .dash-cover-info {
  padding: 0 1.875rem; }

.row.announce-filter .nice-select {
  background: #fff;
  width: 100%;
  line-height: 2.8125rem;
  height: 2.8125rem; }

.course-tab {
  background: #fff;
  padding: 0.625rem 0 0;
  margin-bottom: 1.875rem;
  border-radius: 0.3125rem; }
  .course-tab ul.nav {
    border-bottom: 0.0625rem solid #E5E7ED;
    margin-bottom: 1.5625rem; }
    .course-tab ul.nav li {
      margin-right: 2.5rem; }
      .course-tab ul.nav li button {
        padding: 0.9375rem 0;
        color: #040210;
        margin: 0rem; }
        .course-tab ul.nav li button::before {
          top: 98%;
          height: 0.1875rem;
          width: 50%;
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translate(-50%);
          background-color: #6045FF;
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        .course-tab ul.nav li button.active {
          color: #6045FF; }
          .course-tab ul.nav li button.active::before {
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            width: 100%; }

.profile-form label {
  margin-bottom: 0.3125rem; }

.profile-form input {
  padding: 1rem 1.25rem; }

.profile-info::before {
  background-color: #f5f3ff;
  width: 15.625rem; }

.profile-info ul {
  margin: 0px;
  padding: 0px;
  list-style: none; }
  .profile-info ul li {
    display: flex;
    border-bottom: 0.0625rem solid #E5E7ED;
    padding: 0.8125rem 1.25rem; }
    .profile-info ul li:last-child {
      border-bottom: 0 none; }
    .profile-info ul li span {
      flex: 0 0 14.0625rem;
      display: inline-block;
      margin-right: 1.875rem;
      font-weight: 500;
      font-size: 1rem;
      font-family: "Lexend", sans-serif; }

/* =====================================
Event Style */
.event-entry {
  transform: translateY(0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .event-entry .event-meta span {
    font-size: 1rem; }
    .event-entry .event-meta span i {
      margin-right: 0.3125rem; }
  .event-entry .btn {
    padding: 0.625rem 1.5625rem;
    font-size: 0.9375rem;
    font-weight: 500; }
  .event-entry .event-footer {
    margin-top: 1.875rem; }
    .event-entry .event-footer img {
      margin-right: 0.3125rem; }
  .event-entry:hover {
    transform: translateY(-0.3125rem); }
  .event-entry.list-entry {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .event-entry.list-entry .event-thumb {
      flex: 0 0 16.875rem;
      min-height: 15.625rem; }
    .event-entry.list-entry .event-content {
      padding: 0.625rem 2.5rem 0.625rem 1.875rem; }
    .event-entry.list-entry .event-meta span:first-child {
      margin-right: 1.25rem; }
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .event-entry.list-entry .event-thumb {
        flex: 0 0 15rem; }
      .event-entry.list-entry .event-content {
        padding: 0.625rem 0 0.625rem 1.25rem; } }
    @media (max-width: 1199.98px) {
      .event-entry.list-entry {
        display: block;
        width: 100%; }
        .event-entry.list-entry .event-thumb {
          display: block;
          min-height: 14.375rem; }
        .event-entry.list-entry .event-content {
          padding: 1.25rem 1.25rem 1.875rem; } }

.event-details-sec .widget-top {
  margin-top: -23.125rem; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .event-main-2 .list-entry {
    display: flex; } }

.event-main-2 .list-entry .event-thumb {
  min-height: 20rem;
  flex: 0 0 18.75rem; }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .event-main-2 .list-entry .event-thumb {
      flex: 0 0 15.625rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .event-main-2 .list-entry .event-thumb {
      flex: 0 0 16.25rem; } }
  @media (max-width: 767.98px) {
    .event-main-2 .list-entry .event-thumb {
      min-height: 15.625rem; } }

.event-main-2 .list-entry .entry-content {
  padding: 0.625rem 3.125rem 0.625rem 1.875rem; }
  @media (max-width: 767.98px) {
    .event-main-2 .list-entry .entry-content {
      padding: 1.25rem; } }
  .event-main-2 .list-entry .entry-content h3, .event-main-2 .list-entry .entry-content .h3 {
    margin-bottom: 1.25rem; }

.single-event p {
  line-height: 1.7; }

.single-event ul li:not(:last-of-type) {
  margin-bottom: 0.625rem; }

.speaker-entry {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .speaker-entry h5, .speaker-entry .h5, .speaker-entry .course-entry-2 .card-body .course-footer .price, .course-entry-2 .card-body .course-footer .speaker-entry .price {
    margin-bottom: 0; }
  .speaker-entry .footer-social a {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.3125rem;
    font-size: 1rem;
    -webkit-border-radius: 3.125rem;
    -moz-border-radius: 3.125rem;
    -ms-border-radius: 3.125rem;
    border-radius: 3.125rem; }

/* =====================================
Inner Pages Style */
.profile-wrap {
  padding: 3.75rem;
  background-color: #fff; }
  .profile-wrap .footer-social a {
    width: 2.75rem;
    height: 2.75rem; }
  .profile-wrap .award-badge {
    flex: 0 0 18.75rem;
    text-align: center;
    background: rgba(96, 69, 255, 0.1);
    display: table;
    margin-left: 4.375rem; }
    .profile-wrap .award-badge .award-cell {
      display: table-cell;
      vertical-align: middle; }

.instructor-content {
  align-items: center; }
  .instructor-content h6, .instructor-content .h6, .instructor-content .payment-option .accordion-button, .payment-option .instructor-content .accordion-button, .instructor-content .course-tab ul.nav li button, .course-tab ul.nav li .instructor-content button,
  .instructor-content p {
    margin-bottom: 1.875rem; }

.instruct-form {
  padding: 5rem; }
  .instruct-form input,
  .instruct-form textarea {
    -webkit-border-radius: 0.3125rem;
    -moz-border-radius: 0.3125rem;
    -ms-border-radius: 0.3125rem;
    border-radius: 0.3125rem; }
  .instruct-form label {
    margin-bottom: 0.3125rem;
    color: rgba(114, 114, 114, 0.5);
    font-weight: 500; }

.account-nav a {
  display: block;
  width: 100%;
  padding: 0.9375rem 1.5625rem;
  font-weight: 500;
  border-bottom: 0.0625rem solid #E5E7ED;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .account-nav a.active, .account-nav a:hover {
    background-color: #6045FF;
    color: #fff; }
  .account-nav a:last-child {
    border-bottom: 0; }

.my-account-sec .tab-content {
  padding: 2.5rem; }
  .my-account-sec .tab-content h3, .my-account-sec .tab-content .h3 {
    margin-bottom: 1.875rem;
    font-size: 1.5625rem; }

.comming-wrap .btn {
  right: -0.0625rem;
  top: 0rem; }

.comming-wrap p {
  color: rgba(255, 255, 255, 0.6); }

.comming-wrap .comming-title {
  font-size: 5.625rem;
  font-weight: 400;
  color: #fff;
  font-family: "Hind", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.625rem;
  margin-top: 1.875rem; }

.comming-wrap form {
  max-width: 31.25rem;
  margin: 2.5rem auto 1.25rem; }
  .comming-wrap form input {
    padding: 0.9375rem 1.25rem; }

.comming-wrap #countdown {
  font-size: 3.75rem; }

#countdown {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 33.125rem;
  margin: 0 auto; }
  #countdown span {
    position: relative;
    text-align: center;
    width: 25%;
    color: #fff; }
    #countdown span:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.9375rem;
      color: rgba(255, 255, 255, 0.6); }
  #countdown .days:after {
    content: "Days"; }
  #countdown .hours:after {
    content: "Hours"; }
  #countdown .minutes:after {
    content: "Months"; }
  #countdown .seconds:after {
    content: "Seconds"; }

.comming-soon.overly::before, .comming-soon.color::before, .comming-soon.color-info::before, .comming-soon.btn::before, .social-share-alt a.comming-soon::before, blockquote.comming-soon::before, .marquee p span.comming-soon::before, .comming-soon.navbar-line::before, .top-bar .quick-connect span.comming-soon:last-child::before, .top-bar .info-right > span.comming-soon::before, .header-actions a.comming-soon.text-reset.user-account::before, .comming-soon.btn-close::before, section.comming-soon.promo-sec::before, section.promo-sec nav.comming-soon::before, .category-search .comming-soon.search-group::before, .comming-soon.newsletter::before, .footer-widget .comming-soon.cta-link::before, .swiper-pagination span.comming-soon.swiper-pagination-bullet-active::before, .recent-entry .comming-soon.rc-entry::before, .instagram-widget .widget-inner .insta-wrap a.comming-soon::before, .comming-soon.banner-bottom::before, .banner-stat .comming-soon.single-stat::before, .comming-soon.subscribe-form::before, .comming-soon.course-sec::before, .comming-soon.course-footer::before, .comming-soon.course-preview::before, .video-block .comming-soon.cover-video::before, .video-block-sm .comming-soon.cover-video::before, .play-btn span.comming-soon::before, .about-sec2 .comming-soon.about-lists::before, .apply-process .apply-stat ul li.comming-soon::before, .comming-soon.review-2::before, .comming-soon.instructor-sec-2::before, .choose-txt ul li.comming-soon::before, .ct-tab .comming-soon.nav-link::before, .pricing-card .card-body ul li.comming-soon::before, .accordion-1 .accordion-item .comming-soon.accordion-body::before, .post-comments .comment-entry .comming-soon.commentor::before, .comming-soon.signup-sec::before, .comming-soon.login-form::before, .comming-soon.signup-form::before, .course-tab ul.nav li button.comming-soon::before, .comming-soon.profile-info::before {
  background-color: #000;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75); }
