/* =====================================
Testimonial Style */

.review-sec{
	padding: 5.625rem 0 7.5rem;
}
.review-entry {
	p {
		color: $dark;
		line-height: 1.5;
		margin-bottom: 1.875rem;
		font-size: 2.375rem;

		@include media-breakpoint-only(xl) {
			font-size: 2.0625rem;
		}

		@include media-breakpoint-down(xl) {
			font-size: 1.75rem;
		}

		@include media-breakpoint-down(lg) {
			font-size: 1.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.625rem;
		}
	}

	.ratings {
		margin-right: 1.875rem;

		@include media-breakpoint-down(md) {
			text-align: center;
			margin-right: 3.75rem;
			margin-top: 0.3125rem;
		}
	}
}

.review-img {
	@include media-breakpoint-up(lg) {
		position: relative;
		margin-left: 3.125rem;
	}
}

.review-2 {
	@extend .overly;
	padding-bottom: 3.75rem;

	&::before {
		height: 20.3125rem;
		background-color: $shade;
		top: auto;
		bottom: 0;
		width: calc(100% - 5rem);
		left: 50%;
		transform: translateX(-50%);
	}

	.swiper-button-next,
	.swiper-button-prev {
		position: relative;
		margin: 0 0.9375rem;
		@include border-radius(0.3125rem);
		background-color: $primary;
		color: $white;
	}

	.swiper-slide-next .review-card {
		border: 0 none;
		@extend .shadow-sm;
	}
}

.review-card {
	padding: 1.875rem;
	border: 0.0625rem solid $border-color;

	h3 {
		font-weight: 600;
	}
}