/* =====================================
Shop Style */

.product-entry {
	.product-actions {
		bottom: 2.5rem;
		right: 0.9375rem;
		@include opacity(0);
		@include transition();

		a {
			display: block;
			margin-bottom: 0.9375rem;
		}
	}

	.product-content {
		h3 {
			@extend .text-nowrap;
		}
	}

	.product-price {
		color: $dark;

		del {
			font-weight: 400;
			margin-left: 0.3125rem;
			color: $mute;
			font-size: 1rem;
		}
	}

	.btn {
		padding: 0.75rem 1.25rem;
		margin-top: 1.5625rem;
	}

	&:hover {
		.btn {
			background-color: $primary;
			color: $white;
		}

		.product-actions {
			@include opacity(1);
		}
	}
}

.shop-sidebar {
	.price-widget {
		.widget-inner {
			padding: 1.875rem;
		}

		.btn {
			font-size: 0.9375rem;
			padding: 0.375rem 1.25rem 0.3125rem;
		}
	}
}

.noUi-horizontal {
	height: 0.375rem;
	border: 0 none;
	box-shadow: none;
	background-color: $border-color;

	.noUi-connect {
		background: $primary;
	}

	.noUi-handle {
		width: 1.5rem;
		height: 1.5rem;
		background-color: $primary;
		@include border-radius(3.125rem);
		top: -0.5rem;
		border: 0 none;
		box-shadow: none;

		&:after,
		&:before {
			display: none;
		}
	}
}

.cart-cta {
	.btn.btn-primary {
		padding: 1rem 2.8125rem;
		width: 100%
	}
}

.cart-counter {
	border: 0.0625rem solid $border-color;
	@include border-radius(0.3125rem);
	padding: 0rem 1.25rem;
	color: $headings-color;
	font-size: 1.25rem;

	>div {
		cursor: pointer;
	}

}

.cart-number {
	max-width: 10.625rem;

	input {
		text-align: center;
		border: 0 none;
		background-color: transparent;
		color: $headings-color;
		padding: 0.8125rem;

		&:focus {
			box-shadow: none;
		}
	}
}

.product-details {
	.product-price {
		@extend .h4;
		margin-bottom: 1.25rem;

		del {
			color: $mute;
			font-size: 1.125rem;
			font-weight: 400;
		}
	}

	.cart-cta {
		button.btn i {
			margin-left: -0.4375rem;
		}
	}

	.product-meta {
		margin: 1.875rem 0;

		p {
			margin: 0 0 0.9375rem;

			strong {
				color: $black;
			}
		}

		strong {
			width: 4.375rem;
			display: inline-block;
		}
	}

	#countdown {
		text-align: center;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0;
		min-width: 14.6875rem;

		span {
			position: relative;
			text-align: center;
			color: $primary;
			width: 25%;

			&::after {
				left: auto;
				right: -0.125rem;
				top: 0;
				position: absolute;
				color: $primary;
				font-size: 1.125rem;
			}

			&::before {
				position: absolute;
				right: 80%;
				color: $primary;
				content: ":";
				@include position('vertical')
			}

			&:first-child::before {
				content: " ";
			}
		}

		.days:after {
			content: "d";
		}

		.hours:after {
			content: "h";
		}

		.minutes:after {
			content: " m";
		}

		.seconds:after {
			content: "s";
		}
	}
}


.product-countdown {
	border: 0.0625rem solid $primary;

	span {
		color: $primary;
	}

	.icon {
		margin-right: 0.625rem;
	}
}


.product-details-tab ul.nav-tabs {
	padding: 0;

	li {
		.nav-link {
			@extend .display-5;
			padding: 1.25rem 2.1875rem;
			border: 0 none;
			text-transform: capitalize;

			&.active {
				border-bottom: 0.125rem solid $primary;
			}
		}
	}
}

.tab-desc {
	ul li {
		margin-bottom: 0.9375rem;
	}
}

.recent-ratings {
	max-width: 53.125rem;

	.comment-entry {
		margin-bottom: 1.25rem;
	}
}

.ratings-form {
	.form-group {
		margin-bottom: 1.25rem;

		input,
		textarea {
			background-color: $shade;
		}

		label {
			margin-bottom: 0.3125rem;
			font-weight: 500;
		}

	}

	button {
		margin-top: 1.25rem;
	}
}

.product-video {
	.play-btn {
		@include position('center')
	}
}