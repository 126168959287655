/* =====================================
Post Comment Style */

.post-comments {
	>ol {
		padding-left: 0;
	}

	ol {
		list-style: none;

	}

	.comment-entry {
		position: relative;
		@extend .mb-4;

		.comment-thumb {
			flex: 0 0 5.625rem;
			@include media-breakpoint-between(sm,md) {
				margin-right: 1.25rem;
			}
		}

		.commentor {
			@extend .overly;

			&::before {
				width: 0;
				height: 0;
				background-color: transparent;
				border-top: 0.625rem solid transparent;
				border-right: 1rem solid $shade;
				border-bottom: 0.625rem solid transparent;
				left: -1rem;
				top: 1.875rem;
			}

			p {
				margin: 0;
			}
		}

		@include media-breakpoint-down(sm) {
			.comment-thumb {
				margin-bottom: 0.9375rem;
			}
			.commentor{
				&::before{
					top: -0.625rem;
					left: 0.9375rem;
				}
			}
		}
	}
}
.comment-form{
	input,textarea{
		background-color: $shade;
	}
}