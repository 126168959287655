/* =====================================
Feature Style */

.feature-card {
	background-color: rgba($color: $white, $alpha: .2);
	border: 0.125rem solid rgba($color: $white, $alpha: .1);
	padding: 2rem;
	transform: translateY(0);
	@include transition(all, ease, .5s);
	cursor: pointer;

	span {
		color: $white;
		background-color: $primary;
		font-size: 1.5rem;
		@include transition();
	}

	.feature-txt {
		margin-left: 1.25rem;

		p {
			color: rgba($color: $white, $alpha: .7);
			margin: 0.9375rem 0 0;
			@include transition(all, ease, .5s);
		}
		
	}

	@include media-breakpoint-only(lg) {
		padding: 0.9375rem;
		.display-5 {
			font-size: 1.25rem;
		}
	}
@include media-breakpoint-down(lg) {
	padding: 1.25rem;
	.feature-txt{
		margin: 1.25rem 0 0;
	}
}
	&:hover {
		background-color: $yellow;
		transform: translateY(-0.625rem);

		span {
			background-color: $white;
			color: $yellow;
		}

		.feature-txt {
			p {
				color: $white;
			}
		}
	}
}