/*===========================================
Header Style*/

.marquee {
	overflow: hidden;
	display: flex;
}

.marquee p {
	font-size: 1em;
	white-space: nowrap;
	text-transform: uppercase;
	align-items: center;
	display: flex;
	margin: 0;
	letter-spacing: 0.0625rem;

	span {
		margin: 0 2rem;
		@extend .overly;

		&::before {
			background: url(../images/icons/square.png) no-repeat;
			left: -2.375rem;
			top: 0.25rem;
		}
	}
}

.header {
	position: relative;
	z-index: 99;

	@include media-breakpoint-down(xl) {
		.navbar {
			padding: 0.9375rem 0;
		}
	}

	.header-top {
		padding: 0.625rem 0;
	}

	&.header-1 {
		.navbar .navbar-nav>li.dropdown-fullwidth .dropdown-menu {
			width: 100%;

			&:first-of-type:before {
				left: 23%;
			}
		}

		a.banner-ads img.img-fluid.banner-img {
			left: -47%;
		}

		.navbar {
			@include media-breakpoint-only(xl) {
				.navbar-nav li a.nav-link {
					padding: 1.875rem 0.8125rem;
				}
			}

			.header-actions {
				gap: 0 1rem;
			}

			@include media-breakpoint-only(lg) {
				.navbar-brand {
					margin-right: 2.5rem !important;
				}
			}
		}
	}

	&.header-2 {
		.navbar .navbar-nav>li.dropdown-fullwidth .dropdown-menu {
			width: 100%;
		}

		a.banner-ads img.img-fluid.banner-img {
			left: -47%;
		}
	}

	&.header-3 {
		.header-actions {
			gap: 0rem 0.5rem;

			.admin-menu {
				top: 110%;
				left: 70%;
			}

			.icon {
				height: 3.25rem;
				line-height: 3.25rem;
				flex: 0 0 3.25rem;
				width: 3.25rem;
			}

			.category-search .search-group input {
				@include media-breakpoint-only(md){
					max-width: 8.125rem;
				}
			}
		}

		@include media-breakpoint-only(xl) {
			.admin-user {
				display: none
			}
		}


	}

	// Header 3

}

.navbar-line {
	@extend .overly;

	&::before {
		width: calc(100% - 1.875rem);
		left: 50%;
		transform: translateX(-50%);
		height: 0rem;
		top: 100%;
		background-color: $border-color;

		@include media-breakpoint-up(lg) {
			height: 0.0625rem;
		}
		@include media-breakpoint-only(lg){
			top: 135%;
		}
	}
}

.top-bar {

	span,
	a {
		color: rgba($color: $white, $alpha: .9);
		font-size: 1.0625rem;
	}

	.dropdown-menu {
		min-width: 15.625rem;

		li {
			margin-bottom: 0.4375rem;
		}
	}

	.quick-connect {
		span:last-child {
			margin-left: 1.875rem;
			@extend .overly;

			&::before {
				width: 0.0625rem;
				height: 1.25rem;
				left: -0.9375rem;
				background: rgba($color: $white, $alpha: .3);
				@include position('vertical')
			}
		}
	}

	.info-right {
		>span {
			margin-right: 1.875rem;
			@extend .overly;

			&::before {
				@include position('vertical');
				background-color: rgba($color: $white, $alpha: .3);
				width: 0.0625rem;
				right: -0.9375rem;
				height: 1.25rem;
				left: auto;
			}

			@include media-breakpoint-only(lg) {
				margin-right: 1.25rem;

				&::before {
					right: -0.625rem;
				}
			}

			@include media-breakpoint-down(md) {
				&::before {
					display: none;
				}
			}
		}
	}
}

// Header Start
.navbar {
	padding: 0;

	.dropdown,
	.dropdown-center,
	.dropend,
	.dropstart,
	.dropup,
	.dropup-center {
		position: relative;

		@include media-breakpoint-down(xl) {
			width: 100%;
		}
	}

	.navbar-nav {
		>li {
			a.nav-link {
				padding: 2.0625rem 0.9375rem;
				color: $body-color;
				text-transform: capitalize;
				font-size: 1.125rem;
				font-weight: 500;

				@include media-breakpoint-only(xl) {
					padding: 1.75rem 0.6875rem;
				}

				&::after {
					@include transition();
				}


			}



			.dropdown-item {
				color: $body-color;
			}

			&:first-child a.nav-link {
				padding-left: 0;
			}

			&.dropdown-fullwidth {
				position: static;

				.dropdown-menu {
					left: 0 !important;
					margin-left: auto;
					margin-right: auto;
					right: 0 !important;
					top: 100%;
					transform: none !important;
					padding: 1.875rem 1.875rem 1.25rem;
					@include transition();

					.dropdown-item,
					.dropdown-header {
						padding-left: 0.3125rem;
					}

					.dropdown-item:hover {
						padding-left: 0.625rem;
					}

					@include media-breakpoint-up(xl) {
						width: calc(100% - 6.25rem);
					}
					@include media-breakpoint-down(xl) {
						padding: 1.25rem;
					}
				}

				.dropdown-menu:first-of-type:before {
					left: 38%;
				}
			}

			@include media-breakpoint-down(xl) {
				width: 100%;
			}

		}

		.nav-item>.dropdown-menu:first-of-type::before {

			position: absolute;
			content: "";
			top: -0.75rem;
			left: 1.375rem;
			z-index: 8;
			width: 0;
			height: 0;
			border-left: 0.75rem solid transparent;
			border-right: 0.75rem solid transparent;
			border-bottom: 0.75rem solid $white;

		}

		.dropdown-menu {
			border: none;
			border-radius: 0.5rem;
			margin-top: 0;
			padding: 0.9375rem 0;
			min-width: 15.625rem;
			top: 100%;
			left: 0;
			background-color: $white;
			box-shadow: -0.125rem 0.125rem 4.375rem -1.5625rem rgba(0, 0, 0, .35);
			z-index: 10;
			transform: translateY(0.9375rem);
			@include transition(all, .4s, ease);

			.dropdown-item {
				align-items: center;
				display: flex;
				font-weight: 500;
				padding: 0.4375rem 0.9375rem;
				padding-left: 1.5625rem;
				width: 100%;
				font-size: 0.9375rem;

				@include transition(all, .3s, ease-in-out);

				&:hover,
				&:focus {
					background-color: transparent;
					color: $primary;
					padding-left: 1.875rem;
				}
			}

			.dropdown-submenu a:after {
				position: absolute;
				right: 1rem;
				transform: rotate(-90deg);
			}

			.dropdown-submenu.dropend .dropdown-menu {
				left: 100%;
				right: auto;
				top: -0.625rem;
			}

			>li.dropdown-submenu.dropend:focus>a,
			>li.dropdown-submenu.dropend:hover>a {
				color: $primary;
				background-color: transparent;
				padding-left: 1.875rem;
				@include transition(all, .3s, ease)
			}
		}

		>.dropdown:hover>.dropdown-menu {
			@include opacity(1);
			visibility: visible;
			transform: translateY(0);
		}

	}

	.navbar-brand {
		margin-right: 0;

	}

	.navbar-toggler:focus {
		box-shadow: none;
	}

	.dropdown .dropdown-toggle:after {
		content: "\f12f";
		font-family: "feather-icons" !important;
		border: 0 none;
		margin-left: 0.3125rem;
		float: right;

		@include media-breakpoint-down(md) {
			margin-left: auto;
			margin-right: 0.625rem;
		}
	}

	button.navbar-toggler.offcanvas-nav-btn {
		border: 0;
		font-size: 24px;
		font-weight: 700;
		color: $headings-color;

		i {
			top: 2px;
			position: relative;
		}
	}

	.dropdown-header {
		color: $headings-color;
		font-size: 1rem;
		font-weight: 700;
		letter-spacing: 1px;
		padding: 0.1875rem 0.9375rem 0.4375rem 1.5625rem;
		text-transform: uppercase;
	}

	.custom-btn {
		border-radius: 1.875rem;
		padding: 0.5625rem 1.75rem;
	}

	.nav-banner>a img {
		margin: 0.625rem 0;
	}

	.nav-cta {
		a {
			width: 3.4375rem;
			text-align: center;
			color: $black;

			@include media-breakpoint-down(sm) {
				width: 2.5rem;
			}

			@media only screen and (max-width: 22.5rem) {
				width: 1.5625rem;
			}

			sup {

				background: $primary;
				color: $black;
				top: -0.75rem;
				right: 0;
				display: inline-block;
				width: 1.25rem;
				height: 1.25rem;
				line-height: 1.25rem;
				@include border-radius(50%);
				position: absolute;

			}

			i {
				font-size: 1.25rem;
			}

			&.btn {
				width: auto;
			}
		}

		.dropdown.nav-search a.dropdown-toggle {
			background: transparent;
		}
	}

	&.scroll-on {
		.container::before {
			display: none;
		}

		.navbar-nav li {
			a.nav-link {
				padding: 1.5625rem;

				@include media-breakpoint-only(lg) {
					padding: 1.375rem 0.9375rem;
				}
			}

			&:first-child a.nav-link {
				padding-left: 0;
			}
		}
	}
}

.navbar.nav-center {
	.offcanvas {
		max-width: 43.75rem;

		@include media-breakpoint-down(lg) {
			max-width: calc(100% - 1.25rem);
		}
	}

	.navbar-nav li:last-child a.nav-link {
		padding-right: 0;
	}
}

//Navbar End

@include media-breakpoint-up(xl) {


	.row-cols-lg-4>* {
		flex: 0 0 auto;
		width: 33.33%;
	}

	.navbar {
		.navbar-nav {
			>li:hover>a.nav-link {
				color: $primary;

				&::after {
					transform: rotate(180deg);
				}
			}

			.dropdown-menu {
				display: block;
				@include opacity(0);
				visibility: hidden;
				transform: translateY(1.25rem);

			}

			.dropdown-submenu.dropend:hover>.dropdown-menu {
				@include opacity(1);
				visibility: visible;
				transform: translateY(0);
			}
		}
	}
}

@include media-breakpoint-down(xl) {

	.navbar {
		padding: 0.625rem 0;

		.offcanvas-header img {
			max-width: 7.1875rem;
		}

		.navbar-nav .dropdown-menu {
			background-color: transparent;
			min-width: 0 !important;

			.dropdown-item {
				font-size: 0.9375rem;
			}
		}

		.offcanvas.bg-dark {
			.dropdown-menu {
				background-color: $white;
			}

			.navbar-nav .nav-item .nav-link {
				border-bottom: 0.0625rem solid #2b2b2b;
			}

		}
	}

	.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
		margin-left: 0.625rem;
	}

	.navbar .navbar-nav .nav-item {
		.nav-link {
			border-radius: 0;
			padding: 1rem 0;
			font-size: 1rem;
			line-height: 1;
		}

		&:last-child .nav-link {
			border: 0 none;
		}
	}

	.header-2 .navbar .offcanvas .navbar-nav li a.nav-link,
	.header-2 .navbar .offcanvas .dropdown>.dropdown-toggle:after {
		color: $headings-color;
	}
}

.header-actions {
	a {
		&.btn {
			padding: 0.8125rem 1.5rem;
		}

	}

	a.text-reset.user-account {
		font-weight: 500;
		margin: 0 1.375rem;
		@extend .overly;

		&::before {
			width: 0.0625rem;
			background-color: $border-color;
			@include position('vertical');
			left: -0.9375rem;
			height: 1.25rem;
		}

	}

	@include media-breakpoint-only(xl) {
		a.icon {
			width: 2.8125rem;
			flex: 0 0 2.8125rem;
			height: 2.8125rem;
		}

		a.text-reset.user-account {
			margin: 0 0.875rem;

			&::before {
				left: -0.625rem;
			}
		}
	}

	@include media-breakpoint-down(xl) {
		a.icon {
			width: 2.8125rem;
			flex: 0 0 2.8125rem;
			height: 2.8125rem;
		}

		a.text-reset.user-account {
			margin: 0 0.875rem;

			&::before {
				left: -0.625rem;
			}
		}
	}

	.cart-num {
		background-color: $secondary;
		color: $white;
		display: inline-block;
		@include border-radius(50%);
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		font-weight: 500;
		text-align: center;
		position: absolute;
		top: -0.125rem;
		right: 0;
		font-size: 0.8125rem;
		line-height: 1.5rem;

	}
}

.header-default {
	.header-actions {
		@include media-breakpoint-between(md, xl) {
			min-width: 24.0625rem;
		}
	}
}

//Sticky Nav
header .header-nav-wrapper.header-sticky.scroll-on {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 99;
	background: $white;
	box-shadow: 0 4px 20px rgba(0, 0, 0, .07);
	width: 100%;
	animation: headerSlideDown 1.3s ease forwards;

	.navbar-line:before {
		display: none;
	}
}


@keyframes headerSlideDown {
	0% {
		margin-top: -100px
	}

	to {
		margin-top: 0
	}
}

.search-popup {
	min-height: 37.5rem !important;

	@include media-breakpoint-down(sm) {
		min-height: 12.5rem;
	}

	.search-form {
		position: relative;

		&:after {
			content: "\f1cd";
			font-family: "feather-icons";
			position: absolute;
			transform: translateY(-50%);
			z-index: 1;
			width: 1.25rem;
			left: 0.9375rem;
			background-color: transparent;
			top: 50%;
			transform: translateY(-50%);
		}

		input {
			padding-left: 2.5rem;

			&:focus {
				box-shadow: none;
			}
		}
	}

	.btn-close {
		position: absolute;
		right: 0;
		top: 1.25rem;
	}
}

.offcanvas-header .btn-close {
	padding: 0.25rem;
}


.btn-close {
	width: 1.875rem;
	height: 1.875rem;
	line-height: 2.25rem;
	flex: 0 0 1.875rem;
	text-align: center;
	display: table;
	background: 0 0;
	@include opacity(.9);
	background-color: transparent;
	@extend .overly;

	&:focus {
		border: 0 none;
		box-shadow: none;
	}

	&:before {
		font-family: "feather-icons";
		font-size: 20.8px;
		content: "\f213";
		color: $white;
		background-color: transparent;
		@include opacity(1);
		@include border-radius(50%);
		line-height: 2.3125rem;
	}
}


section.promo-sec {
	padding: 5.625rem 0rem 4.375rem;
	@extend .overly;

	&::before {
		background-color: $headings-color;
		@include opacity(.5)
	}

	.breadcrumb-item+.breadcrumb-item::before {
		content: "\f135";
		font-family: "feather-icons";
		font-style: normal;
		color: $white;
		font-size: 1.125rem;
	}

	.breadcrumb {
		.breadcrumb-item.active {
			color: $secondary;
		}
	}

	nav {
		@extend .overly;

		&::before {
			background: url('../images/promo-arrows.png')no-repeat bottom left / contain;
			top: 3.125rem;
			left: 20%;
			width: 12.5rem;
		}
	}
}

.promo-sec2 {
	background: rgba($color: $primary, $alpha: .1);
	background: linear-gradient(90deg, rgba(96, 69, 255, .10) 18%, #fff);
	padding: 5rem 0;

	.course-intro {
		p {
			max-width: 41.5625rem;
			margin: 1.25rem 0 1.875rem;
		}

		i {
			color: $primary;
		}

		img {
			width: 5.9375rem;
			margin-top: -0.25rem;
		}
	}

	ol.breadcrumb .breadcrumb-item {
		color: $body-color;

		a {
			color: $headings-color;
		}
	}
}

.category-search {
	form {
		border: 0.0625rem solid $border-color;
		@include border-radius(0.3125rem);
		padding: 0.125rem;

		input:focus {
			box-shadow: none;
		}

		.nice-select .nice-select-dropdown {
			margin-top: 1.4375rem;

			ul {
				margin: 0;
				padding: 0.3125rem 0;
			}

			li {
				font-size: 1rem;
				font-weight: 500;
			}

			.option:hover,
			.option.focus,
			.option.selected.focus {
				background-color: $primary;
				color: $white;
			}
		}
	}

	.search-group {
		@extend .overly;

		&::before {
			width: 0.125rem;
			background-color: $border-color;
			z-index: 1;
			height: 1.5625rem;
			@include position('vertical');
		}

		button {
			right: 0.125rem;
			@include position('vertical');
			top: 1.5625rem;
		}

		input {
			padding: 0.5625rem 0.625rem;
			max-width: 12.8125rem;

			@include media-breakpoint-only(xl) {
				max-width: 10.3125rem;
			}
		}

	}

	input,
	.nice-select {
		border: 0;
	}

	.nice-select {
		width: 8.25rem !important;

		span.current {
			display: block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.list {
			max-height: fit-content;
		}

	}
}

// Admin Menu
.admin-menu {
	position: absolute;
	top: 100%;
	@include position('horizontal');
	width: 14.0625rem;
	padding: 0.9375rem;
	@extend .shadow-sm;

	.dashboard-nav .nav li .nav-link {
		padding: 0.5rem 0.3125rem;
		font-weight: 400;
		font-size: 1rem;
		font-family: $font-family-base;

		&:hover {
			color: $primary;
		}

		i {
			font-size: 0.9375rem;
		}
	}
}

.promo-sec3 {
	padding: 5rem 0;

	&::before {
		background-color: $black;
		@include opacity(.75);
	}

	#countdown {
		max-width: 31.25rem;
		font-size: 3.125rem;
		margin-top: 1.875rem;

		span {
			background-color: rgba($color: #fff, $alpha: .2);
			margin-right: 2%;
			padding: 0.625rem 0rem 1.25rem;
			@include border-radius(0.3125rem);

			&:after {
				bottom: 0.9375rem;
			}
		}
	}
}

a.banner-ads {
	background: $white;
	padding: 1.875rem 0 2.5rem 4.375rem;
	position: relative;

	img.img-fluid.banner-img {
		position: absolute;
		left: -33%;
		bottom: -30%;
		width: 18.125rem;

		@include media-breakpoint-only(xl) {
			left: -45%;
		}
		@include media-breakpoint-down(xl) {
	
					position: absolute;
					left: 0 !important;
					bottom: 0;
					width: 59%;
				
		}
	}

	.badge-lg {
		font-weight: 400;
	}

	.b-content {
		text-align: right;
	}
}