/*=================================
Bootstrap Cusotmization
*/

// Typography
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600&family=Lexend:wght@500;600;700&display=swap');
$font-family-base: "Hind", sans-serif;
$font-size-base: 1.125rem;
$font-base-weight: 400;
$line-height-base: 1.5;
$headings-line-height: 1.4;
$headings-font-family: "Lexend", sans-serif;
$headings-font-weight: 600;
$btn-font-family: 'Hind', sans-serif;
$text-mute: rgba(255, 255, 255, .7);
$display-font-sizes: (
  1: 5.5rem,
  2: 3.5rem,
  3: 2.5rem,
  4: 2rem,
  5: 1.5rem,
  6: 1.25rem
);
$display-font-weight: 500;
$display-line-height: 1.5;
// Colors Variable
$primary: #6045FF;
$secondary: #FFA03A;
//$primary-dark: #B9D700;
$white: #fff;
$shade: #F4F5F8;
$success: #35CC6C;
$body-color: #727272;
$mute: rgba(114,114,114, .5);
$mute-alt: rgba(255, 255, 255, 0.6);
$black: #000;
$tertiary: #FE5900;
$headings-color: #040210;
$blue: #1D1354;
$border-color: #E5E7ED;
$light: rgba($primary, 0.1);
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $white,
  "shade": $shade,
  "light": $light,
  "danger": #f30d0d,
  "dark": $headings-color,
  "mute": $mute,
  "tertiary": $tertiary,
  "blue": $blue,
  "body": $body-color,
  "alt-blue": $headings-color,
);

// Custom.scss
$box-shadow: 20px 20px 70px -8px rgba(4, 2, 16, 0.1);
$card-border-width: 0rem;
$breadcrumb-divider: quote("||");
$btn-padding-y: 1.0625rem;
$btn-padding-x: 2rem;

:root {
  --bs-light-rgb: #{$primary}; 
  --bs-bg-opacity: 0.1; 
}

.bg-light {
  --bs-bg-opacity: 0.1 !important; 
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; 
}
// Custom styles for links inside heading tags
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: $headings-color; // Use Bootstrap's heading color
  text-decoration: none; // Optional: remove underline for links inside headings

  &:hover {
    color: $primary; // Use Bootstrap's primary color on hover
  }
}

// Option A: Include all of Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

