/* =====================================
Inner Pages Style */

.profile-wrap {
	padding: 3.75rem;
	background-color: $white;

	.footer-social a {
		width: 2.75rem;
		height: 2.75rem;
	}

	.award-badge {
		flex: 0 0 18.75rem;
		text-align: center;
		background: $light;
		display: table;
		margin-left: 4.375rem;

		.award-cell {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

.instructor-content {
	align-items: center;

	h6,
	p {
		margin-bottom: 1.875rem;
	}
}

.instruct-form {
	padding: 5rem;

	input,
	textarea {
		@extend .border;
		@include border-radius(0.3125rem);
	}

	label {
		margin-bottom: 0.3125rem;
		color: $mute;
		font-weight: 500;
	}
}

.account-nav a {
	display: block;
	width: 100%;
	padding: 0.9375rem 1.5625rem;
	font-weight: 500;
	border-bottom: 0.0625rem solid $border-color;
	@include transition();

	&.active,
	&:hover {
		background-color: $primary;
		color: $white;
	}

	&:last-child {
		border-bottom: 0;
	}
}

.my-account-sec {
	.tab-content {
		padding: 2.5rem;

		h3 {
			margin-bottom: 1.875rem;
			font-size: 1.5625rem;
		}
	}
}

.comming-wrap {
	.btn {
		right: -0.0625rem;
		top: 0rem;
	}

	p {
		color: $mute-alt;
	}

	.comming-title {
		font-size: 5.625rem;
		font-weight: 400;
		color: $white;
		font-family: $font-family-base;
		text-transform: uppercase;
		letter-spacing: 0.625rem;
		margin-top: 1.875rem;
	}

	form {
		max-width: 31.25rem;
		margin: 2.5rem auto 1.25rem;

		input {
			padding: 0.9375rem 1.25rem;
		}
	}
	#countdown{
		font-size: 3.75rem;
	}

	
}

#countdown {
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 33.125rem;
	margin: 0 auto;

	span {
		position: relative;
		text-align: center;
		width: 25%;
		color: $white;

		&:after {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			font-size: 0.9375rem;
			color: $mute-alt;
		}
	}

	.days:after {
		content: "Days";
	}

	.hours:after {
		content: "Hours";
	}

	.minutes:after {
		content: "Months";
	}

	.seconds:after {
		content: "Seconds";
	}
}
.comming-soon {
	&.overly::before {
		background-color: $black;
		@include opacity(.75)
	}
}