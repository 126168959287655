/* =====================================
Instructor Style */
.team-sec{
	overflow: hidden;

}
.teacher-entry {
	min-height: 32.5rem;
	background-size: cover;
	background-position: top center;
	@include transition();

	@include media-breakpoint-down(xl) {
		min-height: 25rem;
	}

	.teacher-info {
		bottom: 0rem;
		width: 100%;

		.teacher-intro {
			background-color: rgba($color: $headings-color, $alpha: 0.3);
			transition: background-color 0.4s ease-in;
			h3 a.text-reset,
			span {
				color: $white !important;
					@include transition(all, 0.3s, ease-in);
			}
		}
	}

	.social-action {
		right: 1.75rem;
		top: 0rem;
		cursor: pointer;
		font-size: 1.25rem;
		@include opacity(0);
		@include transition(all, .5s, ease)
	}

	h3.display-4 {
		@include media-breakpoint-down(xl) {
			font-size: 1.5625rem;
		}
	}

	.teacher-socials {
		right: 2.5rem;
		bottom: 100%;
		@include opacity(0);
		@include transition();

		li {
			display: block;
			margin-bottom: 0.625rem;

			a {
				@extend .icon;
				@extend .icon-xs;
				background-color: $white;
				color: $primary;
				@include border-radius(50%);
				@extend .shadow-lg;
			}
		}

		&.active {
			@include opacity(1);
		}
	}

	&:hover {
		transform: translateY(-0.625rem);
		.social-action{
			@include opacity(1);
			top: -0.625rem;
		}
		.teacher-info {
			.teacher-intro{
				background-color: $shade;
				h3 a.text-reset,span {
					color: $headings-color !important;
				}
			}
		}
	}
}

.instructor-sec-2 {
	overflow: hidden;
	@extend .overly;

	&::before {
		height: 34.375rem;
		background-color: $black;

		@include media-breakpoint-only(lg) {
			height: 27.5rem;
		}
	}

	.social-share {
		@include opacity(0);
		visibility: hidden;
		transform: translateY(3.125rem);
		@include transition();

		a {
			margin: 0 0.3125rem;

			@include media-breakpoint-only(lg) {
				margin: 0;
			}
		}
	}

	.instructor-card {
		overflow: hidden;

		h3 {
			font-size: 1.5rem;

			@include media-breakpoint-only(lg) {
				font-size: 1.1875rem
			}
		}

		p {
			@extend .small;
		}

		&:hover {
			.social-share {
				@include opacity(1);
				visibility: visible;
				transform: translateY(0rem);
			}
		}
	}
}

.contact-lists {
	ul li {
		margin-bottom: 1.25rem;
		max-width: 18.75rem;
		display: flex;

		span {
			margin-right: 0.9375rem;
		}
	}
}

.author-awards {
	flex: 0 0 45%;

	@include media-breakpoint-down(sm) {
		margin-top: 2.8125rem;
	}

	.award-stat {
		margin: 0.3125rem;
		flex: 0 0 45%;
		@include border-radius(0.3125rem);

		.display-3 {
			color: $headings-color;
		}

		p {
			margin: 0;
			@extend .small;
		}
	}
}

.ins-featured {
	.course-entry-2 {
		margin-bottom: 1.25rem;
	}
}