/* =====================================
Accordion, FAQ Style */
.faq-sec {
	.anim-img {
		top: auto;
		bottom: 15%;
	}

	.anim-right {
		top: 15%;
	}
}

.accordion-1 {
	.accordion-item {
		border: 0 none;
		margin-bottom: 1.875rem;
		@include border-radius(0.625rem);
		@include box-shadow(0rem 0.75rem 3.125rem -0.5rem rgba($black, .08));

		.accordion-body {
			p:last-child {
				margin: 0;
			}
		}

		.accordion-header {
			padding: 0rem;
			@include border-radius(0.625rem);
		}

		.accordion-button {
			border-bottom: none;
			background-color: transparent;
			padding: 1.375rem 1.875rem 1.375rem 4.375rem;
			background-color: $border-color;
			box-shadow: none;
			border-radius: 0.625rem !important;

			&:after {
				background-image: none;
				font-family: "feather-icons";
				position: absolute;
				left: 0.625rem;
				content: "\f1bf";
				font-size: 18.4px;
				color: $white;
				background-color: $primary;
				text-align: center;
				width: 3rem;
				height: 3rem;
				line-height: 3rem;
				@include border-radius(0.3125rem);
				transform: rotate(0);

			}

			&:focus {
				box-shadow: none;
			}
		}

		.accordion-body {
			@extend .overly;
			padding: 1.5625rem 4.375rem;
			background-color: $white;
			@include border-radius(0.625rem);

			&::before {
				height: 0.0625rem;
				background-color: $border-color;
				width: calc(100% - 17%);
				left: 50%;
				transform: translateX(-50%);
			}

			@include media-breakpoint-down(sm) {
				padding: 1.5625rem 1.875rem 1.5625rem 4.375rem;
			}
		}
	}

	.accordion-button:not(.collapsed) {
		background-color: $white;

		&:after {
			content: "\f1a2";
			background-color: $secondary;
		}
	}
}

.accordion {
	.accordion-item {
		margin-bottom: 1.25rem;
		border-radius: 0.625rem;
		border: 0.0625rem solid $border-color;
		@extend .shadow-1;
		.accordion-header{
			padding: 16px;
		}
	}
	.accordion-body{
		padding-top: 0;
	}

	.accordion-button{
		padding: 0;
		&:focus {
				box-shadow: none;
			}
	}

	.accordion-button:not(.collapsed) {
		background-color: transparent;
		box-shadow: none;
	}
		.accordion-item:last-of-type .accordion-button.collapsed{
			border-radius: 0rem;
		}
}


.faq-sec.bg-shade {
	.accordion-button {
		background-color: $white;
	}
}

.accordion-2 {
	.accordion-item {
		box-shadow: none;
		border: 0.0625rem solid $border-color;
		margin-bottom: 1.25rem;
		@include border-radius(0.625rem);

		button.accordion-button {
			background-color: transparent;
			padding: 1.25rem 1.875rem;
			font-size: 24px;
			border-bottom: 0.0625rem solid $border-color;
			color: $primary;

			@include media-breakpoint-down(sm) {
				padding: 0.9375rem;
				font-size: 19.2px;

				sub {
					display: none;
				}
			}

			&.collapsed {
				border-bottom: 0 none;
				color: $headings-color;
			}

			&:after {
				position: absolute;
				right: 1.25rem;
				content: "\f1bf";
				font-family: 'feather-icons';
			}

			sub {
				font-size: 0.875rem;
			}
		}

		.accordion-button:not(.collapsed) {
			&:after {
				content: "\f1a2";
			}
		}

		.accordion-body {
			padding: 0.5rem;

			@include media-breakpoint-down(sm) {
				ul {
					padding: 0.625rem 0.3125rem 0;
				}
			}
		}
	}
}

.lesson-items {
	li {
		img {
			width: 1.125rem;
			height: 1.125rem;
			margin: -0.25rem 0.1875rem 0;
		}
	}
}