/* =====================================
Why Us Style */

.choose-txt {
	blockquote{
		border-left: 0.1875rem solid $primary;
		font-size: 1.125rem;
		font-weight: 500;
		padding: 1.25rem 1.875rem;
		margin-top: 2.5rem;
	}
	ul {
		@include list-clear;

		li {
			line-height: 1.5;
			margin-bottom: 0.9375rem;
			padding-left: 0.9375rem;
			@extend .overly;

			&::before {
				width: 0.25rem;
				height: 0.25rem;
				background-color: $secondary;
				@include border-radius(3.125rem);
				top: 40%;
				transform: translateY(-50%);
			}
		}
	}
	h3.display-3{
		margin-bottom: 2.1875rem;
		@include media-breakpoint-only(xl){
			font-size: 1.875rem;
		}
	}
	@include media-breakpoint-down(lg){
		margin-top: 7.5rem;
	}
}

.choose-media {
	.choose-abs {
		position: absolute;
		right: 3.125rem;
		bottom: -6.25rem;
		@include media-breakpoint-down(xl){
			right: 0;
		}
	}
}

.ct-tab {
	display: flex;
	justify-content: space-around;
	border: 0 none;
	border-bottom: 0.0625rem solid $border-color;

	.nav-link {
		padding: 1.25rem 1.875rem;
		@extend .display-6;
		color: $dark;
		@extend .overly;

		&::before {
			height: 0.125rem;
			top: auto;
			bottom: -0.0625rem;
			width: 50%;
			@include opacity(0);
			@include transition();
		}

		img {
			margin-right: 0.3125rem;
		}

		&.active,
		&:hover {
			border: 0.0625rem solid transparent;
			background-color: transparent;
			color: $primary;

			&::before {
				width: 100%;
				@include opacity(1);
			}
		}
	}
	@include media-breakpoint-down(xl){
		.nav-link{
			padding: 0.9375rem 0.4375rem;
		}
	}
}
.course-sec-2{
	.sec-title{
		@include media-breakpoint-only(lg) {
			font-size: 40px;
		}
	}
}
.portfolio-menu .nav {
	.nav-link {
		color: $headings-color;
		position: relative;
		@include media-breakpoint-only(lg) {
			font-size: 1.0625rem;
			padding: 0 0.375rem;
		}

		&.active {
			color: $primary;
		}

		span.badge {
			background: $primary;
			top: -1.1875rem;
			position: absolute;
			right: 0.25rem;

			&::after {
				position: absolute;
				content: "";
				width: 0;
				height: 0;
				border-top: 0.625rem solid $primary;
				border-right: 0.625rem solid transparent;
				top: 100%;
				left: 0.5rem;

			}
		}
	}
}