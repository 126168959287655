/* =====================================
Process Style */

.process-card{
	.process-step{
		width: 11.875rem;
		height: 11.875rem;
		line-height: 11.875rem;
		@include box-shadow(0rem 1.875rem 7.5rem 0rem rgba($headings-color, .1));
		span{
			width: 2.5rem;
				right: 0.625rem;
				top: 0.625rem;
				font-weight: 600;
				height: 2.5rem;
				line-height: 2.5rem;
				font-size: 1.125rem;
				@include box-shadow(0rem 0.75rem 1.625rem 0rem rgba($primary, .5));
		}
	}
	h4{
		margin-top: 40px;
	}
}
.apply-card{
	padding: 2.5rem 3.125rem;
	h3{
		font-size: 3rem;
		color: $white;
		@include media-breakpoint-only(xl){
			font-size: 2.5rem;
		}
		@include media-breakpoint-down(xl){
			font-size: 2rem;
		}
	}
	.btn{
		margin-top: 1.875rem;
	}
	@include media-breakpoint-down(lg) {
		margin-bottom: 1.25rem;
	}
	@include media-breakpoint-down(sm) {
		padding: 1.875rem;
		h3{
			font-size: 1.65rem;
		}
	}
}