/* ==================================
 Cart Style
*/

.woocommerce {
	background-color: $mute;
	padding: 1.875rem;

	@include media-breakpoint-down(sm) {
		padding: 0.625rem;

		.woocommerce-cart-form {
			overflow-x: auto;
			margin-bottom: 1.875rem;
			overflow-y: hidden;
		}
	}


	.shop_table {
		text-align: left;
		width: 100%;
		border-collapse: separate;
		@include border-radius(0.625rem);
		border-spacing: 0 1.25rem;

		thead {
			tr {
				th {
					padding: 0.9375rem 1.25rem;
					background-color: $white;
					font-size: 1.375rem;
					font-weight: 600;
					color: $headings-color;

					@include media-breakpoint-down(sm) {
						font-size: 1.125rem;
						padding: 1.25rem;
					}

					&:first-child,
					&:last-child {
						@include border-radius(0.625rem);
					}
				}
			}
		}

		tr {
			background-color: $white;
			color: $secondary;

			td:first-child {
				border-left-style: solid;
				border-top-left-radius: 0.625rem;
				border-bottom-left-radius: 0.625rem;
			}

			td:last-child {
				border-right-style: solid;
				border-bottom-right-radius: 0.625rem;
				border-top-right-radius: 0.625rem;
			}
		}

		tbody {
			tr.woocommerce-cart-form__cart-item.cart_item {
				td {
					font-weight: 600;
					align-items: center;
					vertical-align: middle;

					@include media-breakpoint-down(sm) {
						font-size: 0.875rem;
					}

					&.product-thumbnail {
						width: 15%;
						padding: 0.9375rem 0 0.9375rem 0.9375rem;

						@include media-breakpoint-down(sm) {
							padding: 0.3125rem 0 0.3125rem 0.3125rem;

							img {
								max-width: 100%;
							}
						}
					}
				}
			}
		}
	}

	th {
		font-weight: 700;
		padding: 0.5625rem 0.75rem;
		line-height: 1.5em;
	}

	td.product-remove {

		a.remove {
			height: 1.5625rem;
			width: 1.5625rem;
			line-height: 1.5625rem;
			background: $light;
			display: inline-block;
			text-align: center;
			@include border-radius(3.125rem);

			i {
				color: $body-color;
				font-weight: 600;
				font-size: 0.9375rem;
				@include transition(all, .5s, ease)
			}
			&:hover{
				background-color: $primary;
				i{
					color: $white;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {

		.table.shop_table.cart>tbody>tr>td.product-price,
		.table.shop_table.cart>tbody>tr>th.product-price,
		.table.shop_table.cart>tfoot>tr>td.product-price,
		.table.shop_table.cart>tfoot>tr>th.product-price,
		.table.shop_table.cart>thead>tr>td.product-price,
		.table.shop_table.cart>thead>tr>th.product-price,
		table.shop_table.cart>tbody>tr>td.product-price,
		table.shop_table.cart>tbody>tr>th.product-price,
		table.shop_table.cart>tfoot>tr>td.product-price,
		table.shop_table.cart>tfoot>tr>th.product-price,
		table.shop_table.cart>thead>tr>td.product-price,
		table.shop_table.cart>thead>tr>th.product-price {
			display: none;
		}
	}

	.coupon {
		position: relative;
		flex: 0 0 50%;

		input {
			border: 0.0625rem solid $border-color;
			padding: 0.875rem;
			font-size: 0.9375rem;

			&:focus {
				outline: 0 none;
				box-shadow: none;
			}
		}

		.btn {
			position: absolute;
			right: -0.125rem;
			border-radius: 0.3125rem;
			top: -0.0625rem;
			font-size: 0.9375rem;
			height: 101%;
			line-height: 1;
		}

		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
		}
	}
}

.woocommerce .shop_table tr:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.cart-quanty {
	display: flex;
	justify-content: space-between;
	width: 6.25rem;
	padding: 0.25rem 0.8125rem;
	@include border-radius(0.3125rem);
	font-size: 0.9375rem;
	background-color: $shade;

	input {
		border: 0 none;
		padding: 0;
		color: $headings-color;
		font-weight: 600;
		background: transparent;
		text-align: center;
	}

	i {
		cursor: pointer;
	}
}

.cart-totals {
	padding: 1.25rem 1.875rem 1.875rem;
	@include media-breakpoint-down(sm) {
		margin-top: 1.875rem;
	}

	@include media-breakpoint-only(sm) {
		margin-top: 1.875rem;
	}

	table {
		width: 100%;
		border-spacing: 0 0.625rem;
		border-collapse: separate;

		th {
			color: $headings-color;
		}

		td {
			font-weight: 500;
		}

		tbody tr td {
			padding: 0.9375rem 0.625rem;
			text-align: right;
		}

		tr:first-child {
			font-weight: 700;
		}

		tr.product-list {
			background: $white;

			th,
			td {
				padding-left: 1.25rem;
				padding: 0.625rem 0.9375rem;
				color: $body-color;
			}


			th {
				border-top-left-radius: 0.625rem;
				border-bottom-left-radius: 0.625rem;
			}

			td {
				border-bottom-right-radius: 0.625rem;
				border-top-right-radius: 0.625rem;
				font-size: 1.25rem;
			}
		}

	}
}