/* =====================================
Blog Style */

.blog-sec {
	@include media-breakpoint-down(lg) {
		.sec-intro {
			margin-bottom: 1.25rem;
		}
		.single-entry{
			margin-bottom: 50px;
		}
	}

}

.entry-meta {

	span,
	a {
		color: $body-color;
		font-size: 1.0625rem;
	}

	span {
		margin-right: 1.25rem;

		@include media-breakpoint-down(sm) {
			margin-right: 0.3125rem;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	i {
		margin-right: 0.4375rem;
	}
}

.single-entry {
	height: 31.75rem;
	@include transition(all, .5s, ease-in-out);

	@include media-breakpoint-down(lg) {
		max-width: 37.5rem;
	}

	.entry-media {
		height: 100%;

		img {
			transform: scale(1);
			transition: transform .7s cubic-bezier(0, 0, 0.44, 1.18);
		}
	}

	.entry-content {
		position: absolute;
		background-color: $white;
		padding: 1.875rem 1.25rem 1.25rem 2.1875rem;
		max-width: calc(100% - 2.5rem);
		left: 0;
		bottom: -1.5625rem;
		overflow: hidden;
		z-index: 2;
		@include transition(all, .4s, ease-in-out);

		.content-header {
			@include transition(all, .5s, ease-in-out);
		}

		.entry-meta {
			max-width: 80%;
			i{
				color: $primary;
			}
		}

		.entry-hover {
			height: auto;
			max-height: 0;
			@include opacity(0);
			@include transition();

			p {
				margin-bottom: 1.25rem;
			}

			i {
				@include transition(all, .4s, ease)
			}

			a:hover i {
				padding-left: 0.5rem;
			}
		}

		@include media-breakpoint-down(xl) {
			padding: 1.25rem;

			h3 {
				font-size: 1.625rem;
			}

			.entry-meta {
				max-width: calc(100% - 1.25rem);
			}
		}

		@include media-breakpoint-between(sm, lg) {
			max-width: calc(100% - 15%);
		}

		@include media-breakpoint-down(sm) {
			max-width: 100%;

			.entry-meta span,
			.entry-meta span a {
				font-size: 0.8438rem;
			}

			h3 {
				font-size: 1.375rem;
			}

			height: 8.4375rem;
		}
	}

	&:hover {
		.entry-media {
			img {
				transform: scale(1.06);
			}
		}

		.entry-content {
			@include box-shadow(1.25rem 1.25rem 4.375rem -0.5rem rgba(#040210, .1));

			.entry-hover {
				max-height: 7.8125rem;
				padding-top: 0.625rem;
				transition-timing-function: cubic-bezier(1, 1, 1, 1);
				@include opacity(1);
			}

			@include media-breakpoint-only(xl) {
				height: 20.625rem;
			}

			@include media-breakpoint-down(xl) {
				height: 18.75rem;
			}

			@include media-breakpoint-down(sm) {
				height: 19.6875rem;
			}
		}
	}
}

.single-entry-2 {
	.entry-thumb {
		overflow: hidden;

		img {
			transform: scale(1);
			@include transition(all, ease, .5s);
		}
	}

	.entry-meta {
		span {
			margin-right: 1.25rem;

			@include media-breakpoint-only(md) {
				font-size: 1rem;
				margin-right: 0.25rem;
			}
		}
	}

	&:hover .entry-thumb img {
		transform: scale(1.1);
	}

	.entry-content {
		padding: 1.25rem 3.125rem 2.5rem;

		.entry-footer {
			margin-top: 3.125rem;

			.social-share a,
			.btn {
				color: $body-color;
				background-color: $shade;

				&:hover {
					background-color: $primary;
					color: $white;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding: 0.625rem 1.25rem 1.875rem;

			.entry-footer {
				margin-top: 2.5rem;
			}
		}

		@include media-breakpoint-only(lg) {
			padding: 0.9375rem 1.875rem 1.875rem;
		}
	}
}

.blog-standard {
	.single-entry-2 {
		margin-bottom: 2.8125rem;
	}
}

.blog-page {
	.single-entry {
		margin-bottom: 1.875rem;

		.entry-content {
			background-color: $shade;
			@include transition();
		}

		&:hover {
			.entry-content {
				background-color: $white;
			}
		}
	}

}

.single-article {
	.entry-meta span {
		margin-right: 2.1875rem;
		font-size: 1rem;

		i {
			margin-right: 0.3125rem;
			color: $primary;
		}
	}

	h2 {
		margin-bottom: 1.875rem;
	}

	p {
		line-height: 170%;
	}

	ul {
		padding: 1.25rem 0 1.25rem 1.875rem;

		li {
			margin-bottom: 0.9375rem;
		}
	}

	.social-share.white a {
		color: $body-color;
		box-shadow: none !important;

		&:hover {
			color: $white;
		}
	}

	.article-footer {
		.widget_tag_cloud a {
			margin: 0;
			background-color: $white;

			&:hover {
				background-color: $primary;
			}
		}
	}
}

.list-entry {
	margin-bottom: 1.875rem;

	.entry-media {
		flex: 0 0 23.75rem;
	}

	.entry-content {
		padding: 0.625rem 3.125rem 0.625rem 1.25rem;

		.entry-meta span {
			margin-right: 0.625rem;

		}
	}

	@include media-breakpoint-only(xl) {
		.entry-content {
			padding: 0.625rem 0.9375rem 0.625rem 0.625rem;

			h3 {
				font-size: 1.75rem;
			}
		}

		.entry-media {
			flex: 0 0 21.875rem;
		}
	}

	@include media-breakpoint-down(xl) {
		.entry-media {
			flex: 0 0 17.1875rem;
		}

		.entry-content {
			padding: 0.625rem;

			h3 {
				font-size: 1.5625rem;
			}

		}
	}

	@include media-breakpoint-down(lg) {
		.entry-media {
			flex: 0 0 20rem;
		}

		.entry-content {
			padding: 0.625rem 1.875rem 0.625rem 1.25rem;

			h3 {
				font-size: 1.5625rem;
			}

		}
	}

	@include media-breakpoint-down(md) {
		.entry-content {
			padding: 0.625rem 1.25rem 1.25rem 1.25rem;
		}
	}
}

.search-widget {
	padding: 1.25rem;
	border: 0.0625rem solid $border-color;
	@include border-radius(0.625rem);

	input {
		background-color: $shade;
		padding: 0.8125rem 1.25rem;
	}

	.btn {
		right: 0rem;
		padding: 0.8125rem 1.25rem;

		i {
			margin-left: 0;
		}
	}
}