/*=================================
Footer Style
*/

.newsletter {
	padding: 6.875rem 0 6.25rem;
	@extend .overly;

	&::before {
		background: url('../images/newsleft.png')no-repeat left top / contain;
		max-width: 31.875rem;
	}

	&::after {
		position: absolute;
		content: "";
		background: url('../images/newsright.png')no-repeat right bottom / contain;
		right: 0;
		bottom: 0;
		width: 100%;
		max-width: 18.75rem;
		height: 100%;
		z-index: -1;
	}

	.newsletter-form {
		max-width: 31.25rem;

		@include media-breakpoint-down(lg) {
			margin-top: 1.875rem;
		}

		button {
			color: $white;
		}
	}
}

.footer {
	padding: 8.125rem 0 1.875rem;

	.footer-bottom {
		border-top: 0.0625rem solid rgba(255, 255, 255, .2);
		padding: 2.1875rem 0 0;
		margin-top: 5rem;

		@include media-breakpoint-down(lg) {
			margin-top: 2rem;
		}

		p {
			font-size: 1rem;
		}

	}

	.social-share-alt img {
		@include transition();

		&:hover {
			@include opacity(.85);
		}
	}

	.social-share-alt {
		a {
			&::before {
				display: none
			}
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 1.25rem;

			a {
				margin: 0 0.9375rem 1.25rem 0;

				&::before {
					display: none
				}
			}
		}
	}
}

.footer-widget {
	.cta-link {
		margin: 2.5rem 0 0.3125rem;
		display: inline-block;
		@extend .overly;

		&::before {
			height: 2px;
			background-color: $secondary;
			top: 90%;
		}

		@include media-breakpoint-only(xl) {
			font-size: 2.1rem;
		}

		@include media-breakpoint-only(lg) {
			font-size: 1.8rem;
		}
	}

	.widget-title {
		margin-bottom: 2.5rem;
	}

	ul {
		@include list-clear;

		li {
			margin-bottom: 1.25rem;
			color: $white;

			@include media-breakpoint-down(xl) {
				font-size: 1rem;
			}

			span {


				i {
					margin-right: 0.625rem;
				}
			}

			a {
				color: rgba($color: $white, $alpha: .70);

			}

			&:hover a {
				color: $secondary;
			}
		}
	}

	p {
		color: rgba(255, 255, 255, .6);
	}

	&.post-widget {
		.recent-entry {
			margin-bottom: 1.875rem;

			.entry-thumb {
				@extend .rounded-2;
				overflow: hidden;
				flex: 0 0 6.5625rem;

				img {
					@extend .rounded-2;
				}
			}

			.entry-content {
				h4 a {
					color: rgba($color: $white, $alpha: .7);

					&:hover {
						color: $secondary;
					}
				}

				span {
					margin-right: 0.625rem;
					font-size: 1.0625rem;

					@include media-breakpoint-down(xl) {
						font-size: 1rem;
					}

					i {
						margin-right: 0.3125rem;
					}
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 2.5rem;
	}
}

.about-footer {
	ul {

		li a,
		li {
			color: rgba($color: $white, $alpha: .7);
			&:hover{
				color: $secondary;
			}
		}
	}
}

.footer-social {
	a {
		background-color: $shade;
		display: inline-table;
		width: 3rem;
		height: 3rem;
		text-align: center;
		@include border-radius(0.3125rem);
		border: 0.0625rem solid#dedede;
		margin-right: 0.625rem;

		i {
			display: table-cell;
			vertical-align: middle;
		}

		&:hover {
			background-color: $primary;
			color: $white;
			border: 0.0625rem solid $primary;
		}
	}

	.socials-2 {
		a {
			width: 3.5rem;
			height: 3.5rem;
			font-size: 1.25rem;

			&:hover {
				background-color: $secondary;
				border: 0.0625rem solid $secondary;
			}
		}
	}

}

.footer-2 {
	.footer-widget {
		p {
			color: $body-color;
		}

		ul li a {
			color: $body-color;

			&:hover {
				color: $primary;
			}
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: 2.8125rem;
		}
	}

	.copy-right {
		p {
			color: rgba($color: $white, $alpha: .70);
		}
	}
}

.about-footer {
	p {
		max-width: 18.75rem
	}
}

.subs-form {
	.form-group {
		position: relative;

		img {
			@include position('center');
			left: 1.5625rem;
		}
	}

	.btn i {
		margin-left: 0.625rem;
	}

	input {
		padding-left: 2.8125rem;
	}
}