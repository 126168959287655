/* ==================================
Checkout Style
*/
.order-summery {
	@include media-breakpoint-down(sm) {
		margin-top: 1.875rem;
	}

	@include media-breakpoint-only(sm) {
		margin-top: 1.875rem;
	}
}

.coupon-wrap {
	input {
		flex: 0 0 65%;
	}

	@include media-breakpoint-down(lg) {
		margin-bottom: 1.25rem;

		input {
			flex: 0 0 55%;
		}
	}
}

.checkout-coupon {
	h6 {
		margin-bottom: 0;

		button {
			border: 0;
			color: $secondary;
			background: transparent;
		}

	}

	form {
		max-width: 37.5rem;

		.btn {
			border-radius: 0.3125rem;
		}
	}
}

form.form.checkout-form {
	label {
		margin-bottom: 0.3125rem;
		font-weight: 500;
		color: $headings-color;
		font-size: 1.0625rem;
	}

		.nice-select {

		display: block !important;
		width: 100%;
		border: 0.0625rem solid $border-color;
		@extend .rounded-2;
		background-color: $white;
		font-size: 1rem;
			height: 3.5rem;
				line-height: 3.5rem;
	}
		
	input {
		font-size: 1rem;
		margin-bottom: 1.875rem;
	}

	input[type="checkbox"] {
		width: auto;
	}
}


.payment-option {
	margin-bottom: 1.875rem;

	.accordion-button {
		@extend .h6;

		&:after {
			right: 0rem;
			background: transparent;
			border-radius: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
			box-shadow: none;
		}
	}

	.accordion-button:not(.collapsed):after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
		background-color: transparent;
		transform: rotate(0deg);
	}
}