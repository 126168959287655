/* =====================================
Brand Style */

.brand-sec{
	padding: 5rem 0;
	.brand-item{
		a{
			background-color: rgba($color: $white, $alpha: .1);
			display: inline-table;
			width: 15rem;
			height: 5.3125rem;
			line-height: 5.3125rem;
			@include transition();
			@include border-radius(3.125rem);
			border: 0.0625rem solid rgba($color: $white, $alpha: .15);
			&:hover{
				background-color: rgba($color: $white, $alpha: .2);
			}
			@include media-breakpoint-between(md,xl) {
				width: 13.75rem;
			}
		}
	}
}