/* =====================================
Dashboard Style */

.dash-cover-bg {
	min-height: 18.75rem;
	position: relative;
	@extend .bg-cover;
}

.dashbaord-promo {
	@extend .promo-sec2;
	padding: 6.25rem 0;
}

.dashbaord-cover {
	.dash-cover-bg {
		margin-top: -16.875rem;
	}
}

.dash-cover-info {
	position: absolute;
	bottom: -1.5625rem;
	z-index: 2;
	width: 100%;
	padding: 0 3.125rem;
	@include position('horizontal');

	@include media-breakpoint-only(sm) {
		padding: 0 1.5625rem;
	}
	@include media-breakpoint-down(sm) {
		padding: 0 2.1875rem;
	}


	.ava-wrap {
		.avatar {
			border: 0.4375rem solid $white;
		}

		a.icon-xs {
			position: absolute;
			right: 0;
			bottom: 0.5rem;
			border: 0.0625rem solid $border-color;
			color: $headings-color;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 1.25rem;

			.avatar {
				img {
					width: 5.625rem !important;
				}
			}
		}
	}

	.ava-meta {
		span {
			margin-right: 0.625rem;
			font-size: 1rem;

			i,
			img {
				color: $white;
				margin-right: 0.3125rem;
			}

			img {
				margin-top: -0.25rem;
			}
		}
	}

	.btn-sm {
		font-size: 0.9375rem;
		padding: 0.625rem 1.375rem;
	}
}

.dashboard-sidebar {
	padding: 1.875rem;
	background-color: $white;
	@include media-breakpoint-only(lg) {
		padding: 1.875rem 0.9375rem;
	}
	@include media-breakpoint-down(lg) {
		margin-bottom: 1.875rem;
	}

	.grettings {
		@extend .small;
		text-transform: uppercase;
		letter-spacing: 0.0625rem;
		font-weight: 600;
	}
}




.dashboard-nav .nav {
	display: block;

	li {
		display: block;

		.nav-link {
			padding: 0.75rem 0.3125rem;
			display: block;
			font-weight: 500;
			font-size: 1rem;
			font-family: $headings-font-family;
			color: $body-color;

			&.active {
				color: $primary;
			}

			i {
				font-size: 1.125rem;
			}

			span {
				margin-left: 0.625rem;
			}
		}
				&.border-bottom {
					height: 0.5625rem;
					margin-bottom: 0.4375rem;
				}
	}
}

.stat-counter {
	padding: 0.9375rem 1.25rem;
	background-color: $white;
	border: 0.0625rem solid $border-color;

	img {
		max-width: 2.5rem;
		max-height: 2.3125rem;
	}

	p {
		margin: 0;
		font-size: 0.9375rem;
	}
}

.dashboard-sec {
	background-color: $white;
	@extend .shadow-1;
	padding: 1.875rem;
	border: 0.0625rem solid $border-color;
	@include border-radius(8px);
	&.course-dash {
		background-color: $shade;
		box-shadow: none;
		border: 0 none;
		padding: 0;
	}

	.pager a {
		background-color: $white;
		&:hover{
			background-color: $primary;;
		}
	}

	.course-entry-3 {
		h3.sub-title {
			font-size: 1.375rem;
		}

		.ratings img {
			width: 1.0625rem;
		}

		.btn.btn-sm {
			padding: 0.5625rem 0.3125rem;
		}

		.course-footer:before {
			left: 54%;
		}
	}

	.table>:not(caption)>*>* {
		padding: 11.2px 16px;
	}

	.table {

		.display-6 {
			font-size: 17.6px;
		}

		thead {
			tr {
				@extend .rounded-2;

				th {
					color: $headings-color;
					border: 0 none;
					background: $light;
				}
			}
		}

		tbody {
			tr {
				td {
					vertical-align: middle;
					font-size: 1rem;

					p {
						margin: 0;
						font-size: 0.875rem;
						font-weight: 400;
					}
				}
			}
		}
	}
		.course-entry-3 {
			.single-progress {
				.progress {
					height: 0.3125rem;
	
					.progress-bar {
						background-color: rgba($color: $primary, $alpha: .75);
					}
	
					.progress-number {
						position: absolute;
						font-size: 0.875rem;
						top: 0;
						line-height: 1.25;
						right: 0;
						z-index: 1;
					}
				}
	
				h6,
				span {
					@extend .text-mute;
					font-size: 0.75rem;
					text-transform: uppercase;
				}
			}
	
			.course-footer {
				a {
					font-size: 0.9375rem;
				}
			}
		}
}
.profile-cover-wrap {
	.profile-cover-bg {
		min-height: 15.625rem;
	}

	.dash-cover-info {
		padding: 0 1.875rem;
	}
}

.row.announce-filter .nice-select {
	background: #fff;
	width: 100%;
	line-height: 2.8125rem;
	height: 2.8125rem;
	@extend .shadow-xs;
}

.course-tab {
	background: $white;
	padding: 0.625rem 0 0;
	margin-bottom: 1.875rem;
	border-radius: 0.3125rem;

	ul.nav {
		border-bottom: 0.0625rem solid $border-color;
		margin-bottom: 1.5625rem;

		li {
			margin-right: 2.5rem;

			button {
				padding: 0.9375rem 0;
				@extend .h6;
				color: $headings-color;
				margin: 0rem;
				@extend .overly;

				&::before {
					top: 98%;
					height: 0.1875rem;
					width: 50%;
					@include position('horizontal');
					background-color: $primary;
					@include opacity(0);
					@include transition();
				}

				&.active {
					color: $primary;

					&::before {
						@include opacity(1);
											width: 100%;
					}
				}
			}
		}
	}

}
.profile-form{
	@extend .contact-form;
	label{
		margin-bottom: 0.3125rem;
	}
	input{
		padding: 1rem 1.25rem;
	}
}
.profile-info {
	@extend .overly;

	&::before {
		background-color: #f5f3ff;
		width: 15.625rem;
	}

	ul {
		@include list-clear;

		li {
			display: flex;
			border-bottom: 0.0625rem solid $border-color;
			padding: 0.8125rem 1.25rem;

			&:last-child {
				border-bottom: 0 none;
			}

			span {
				flex: 0 0 14.0625rem;
				display: inline-block;
				margin-right: 1.875rem;
				font-weight: 500;
				font-size: 1rem;
				font-family: $headings-font-family;
			}
		}
	}
}