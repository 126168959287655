/* =====================================
Counter/Stat Style */

.stat-sec {
	@include media-breakpoint-down(sm) {
		.col {
			flex: auto;
		}
		.stat-info .display-3{
			font-size: 2.5rem;
		}
	}

	.stat-wrap {
		margin-top: -7.1875rem;
		padding: 4.0625rem;

		@include media-breakpoint-down(md) {
			padding: 2.8125rem 0.9375rem 1.875rem;
		}
	}

	.stat-item {
		@include media-breakpoint-down(xl) {
			margin-bottom: 1.875rem;
		}

		.icon-lg {
			background-color: rgba($color: $white, $alpha: .2);
		}

		p {
			margin: 0;
		}
	}
}