/* =====================================
Event Style */

.event-entry {
	transform: translateY(0);
	@include transition();

	.event-meta {
		span {
			font-size: 1rem;

			i {
				margin-right: 0.3125rem;
			}
		}
	}

	.btn {
		padding: 0.625rem 1.5625rem;
		font-size: 0.9375rem;
		font-weight: 500;
	}

	.event-footer {
		margin-top: 1.875rem;

		img {
			margin-right: 0.3125rem;
		}
	}

	&:hover {
		transform: translateY(-0.3125rem);
	}

	&.list-entry {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.event-thumb {
			flex: 0 0 16.875rem;
			min-height: 15.625rem;
		}

		.event-content {
			padding: 0.625rem 2.5rem 0.625rem 1.875rem;
		}

		.event-meta span:first-child {
			margin-right: 1.25rem;
		}

		@include media-breakpoint-only(xl) {
			.event-thumb {
				flex: 0 0 15rem;
			}

			.event-content {
				padding: 0.625rem 0 0.625rem 1.25rem
			}
		}

		@include media-breakpoint-down(xl) {
			display: block;
			width: 100%;

			.event-thumb {
				display: block;
				min-height: 14.375rem;
			}

			.event-content {
				padding: 1.25rem 1.25rem 1.875rem;
			}
		}
	}
}
.event-details-sec .widget-top {
	margin-top: -23.125rem;
}

.event-main-2 {
	.list-entry {
		@include media-breakpoint-only(lg) {
			display: flex;
		}

		.event-thumb {
			min-height: 20rem;
			flex: 0 0 18.75rem;

			@include media-breakpoint-only(xl) {
				flex: 0 0 15.625rem;
			}

			@include media-breakpoint-only(md) {
				flex: 0 0 16.25rem;
			}

			@include media-breakpoint-down(md) {
				min-height: 15.625rem;

			}
		}

		.entry-content {
			padding: 0.625rem 3.125rem 0.625rem 1.875rem;

			@include media-breakpoint-down(md) {
				padding: 1.25rem;

			}

			h3 {
				margin-bottom: 1.25rem;
			}
		}
	}
}

.single-event {
	p {
		line-height: 1.7;
	}

	ul li:not(:last-of-type) {
		margin-bottom: 0.625rem;
	}
}

.speaker-entry {
	@include transition();
	h5{
		margin-bottom: 0;
	}
	.footer-social a {
		width: 2.625rem;
		height: 2.625rem;
		margin-right: 0.3125rem;
		font-size: 1rem;
		@include border-radius(3.125rem);
	}
	&:hover{
		@extend .shadow-sm;
	}
}