/* =====================================
Pricing Table Style */

.pricing-card{
	padding: 3.125rem 1.5625rem 2.5rem;
	.card-head{
		h2{
			line-height: 1;
			font-weight: 600;
			@include media-breakpoint-down(xl){
				font-size: 2.5rem;
			}
			
			sub{
				font-size: 0.875rem;
				left: -1.25rem;
				color: $body-color;
			}
		}
		.badge-lg{
			font-weight: 500;
		}
	}
	.card-body{
		padding: 1.875rem 1.5625rem;
		margin: 1.25rem 0 1.875rem;
		@include media-breakpoint-only(lg) {
			padding: 1.875rem 0.9375rem;
				font-size: 1rem;
			}
		ul{
			@include list-clear;
			li{
				margin-bottom: 1.25rem;
				padding-left: 1.5625rem;
				
				&:last-child{
					margin-bottom: 0;
				}
				@extend .overly;
				&::before{
					content: "\f12d";
					font-family: "feather-icons";
					background-color: transparent;
					top: -0.125rem;
				}
			}
		}
	
	}
		.btn {
			color: $dark;
			background-color: $white;
		}
	&:hover, &.active{
		.btn{
			@extend .shadow;
			background-color: $primary;
			color: $white;
		}
	}
}