/* =====================================
About Us Style */
.about-sec {
	.about-txt {
		@include media-breakpoint-only(xl) {
			margin-left: 2.5rem;

			h2 {
				font-size: 2.8rem;
			}
		}

		@include media-breakpoint-down(xl) {
			margin-top: 2.5rem;
		}
	}

	.about-content {
		max-width: 43.75rem;
	}
}

.about-media {
	&::before {
		background: url('../../images/icons/stat-bg.png')no-repeat top left;
		left: -15%;
		top: -8%;
	}

	.category-entry {
		width: 17.8125rem;
		position: absolute;
		right: 1.5625rem;
		background-color: $white;
		top: 2.5rem;
		.icon-lg{
			margin-left: 0.3125rem;
		}
		.cat-info h3{
			margin-bottom: 0;
		}
	}


}

.about-item {
	margin-bottom: 1.25rem;
}

.about-media-2 {
	max-width: 41.25rem;

	img.ab-media-1 {
		border-radius: 11.25rem 11.25rem 0rem 11.25rem;

		@include media-breakpoint-only(xl) {
			width: 55%;
		}

		@include media-breakpoint-down(md) {
			width: 55%;
		}
	}

	img.ab-media-2 {
		@include border-radius(50%);
		right: 0;
		top: 0;

		@include media-breakpoint-only(xl) {
			width: 43%;
		}

		@include media-breakpoint-down(md) {
			width: 42%;
		}
	}

	img.ab-media-3 {
		@include border-radius(7.25rem 10rem 10rem 0rem);
		right: 0;
		bottom: 0;

		@include media-breakpoint-only(xl) {
			width: 43%;
		}

		@include media-breakpoint-down(md) {
			width: 42%;
		}
	}
}

.play-btn {
	span {
		@extend .overly;
		display: inline-block;

		&::before,
		&::after {
			background-color: transparent;
			border: 0.0625rem solid $secondary;
			width: 120%;
			height: 120%;
			left: 50%;
			transform: translate(-50%, -50%);
			top: 50%;
			@include border-radius(50%);
		}

		&::after {
			position: absolute;
			content: "";
			width: 145%;
			height: 145%;
		}
	}
}

.about-sec2 {
	padding-bottom: 6.25rem;

	@include media-breakpoint-down(sm) {
		padding-bottom: 1.875rem;
	}

	.sec-title {
		font-size: 3.75rem;

		@include media-breakpoint-only(sm) {
			font-size: 2.8125rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.1875rem;
		}
	}

	.about-info {
		padding: 1.25rem 0 2.1875rem;
	}

	.about-media {
		margin-left: -6.25rem;

		@include media-breakpoint-only(xl) {
			margin-left: -7.1875rem;
		}

		@include media-breakpoint-down(xl) {
			margin-left: auto;
			margin-bottom: 3.75rem;
		}
	}

	@include media-breakpoint-only(xl) {
		.about-item h3 {
			font-size: 18px;
		}
	}

	.about-lists {
		padding: 2.1875rem 0;
		border-top: 0.0625rem solid $border-color;
		border-bottom: 0.0625rem solid $border-color;
		@extend .overly;

		&::before {
			width: 0.0625rem;
			background-color: $border-color;
			left: 50%;
			transform: translateX(-50%);

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}
}

.about-mission {
	@include media-breakpoint-down(md) {
		&:first-child {
			margin-bottom: 1.875rem;
		}
	}

	ul {
		@include list-clear;

		li {
			position: relative;
			padding-left: 1.875rem;
			line-height: 2.375rem;

			&::before {
				position: absolute;
				content: "\f12d";
				font-family: feather-icons;
				color: $primary;
				left: 0rem;
			}
		}
	}
}

.admission-media {
	.video-block {
		@include position('center');
		.waves {
			left: -3.75rem;
		}
	}
}
.apply-process{
	.card{
		span{
			font-size: 1.5625rem;
		}
		p{
			margin: 0;
		}
	}
	.apply-stat{
		ul {
			@include list-clear;
			li{
				margin-bottom: 1.25rem;
				padding-left: 1.875rem;
				@extend .overly;
				&::before{
					content: "\f12e";
					font-family: "feather-icons";
					color: $secondary;
					background-color: transparent;
					font-size: 1.25rem;
				}
			}
		}
	}
}