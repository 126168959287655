/* =====================================
Course Style */

.course-sec {
	@extend .overly;
	padding-bottom: 0;

	.icon-sm {
		@include media-breakpoint-down(lg) {
			width: 3rem;
			height: 3rem;
			flex: 0 0 3rem;
			line-height: 3rem;
		}
	}

	&::before {
		height: 36.8125rem;
		background-color: $shade;
	}

	.sec-intro {
		margin-bottom: 0rem;
	}

	.custom-nav {
		margin-top: -0.625rem;
	}

	.swiper-slide {
		width: 23.75rem; // Set a fixed width for the slides, adjust as necessary
	}

	.swiper-slide-active {
		.course-entry-3 {
			box-shadow: 0.625rem 1.25rem 3.125rem -0.5rem rgba($color: #040210, $alpha: .08);

			.sub-title a,
			.course-footer a {
				color: $primary;
			}
		}
	}
}

.course-entry-3 {
	transform-origin: 50% 100%;
	overflow: hidden;
	@include transition();

	.action-wishlist {
		right: 0.625rem;
		top: 0.625rem;
		background: rgba($color: #000000, $alpha: .3);
	}

	.course-cat {
		left: 0.625rem;
		top: 1rem;
		position: absolute;
		font-size: 1rem;
		font-weight: 400;
	}

	.card-body {
		padding: 0.9375rem 1.5625rem 1.25rem;
	}

	.course-meta {
		@extend .small;

		img {
			width: 1.0625rem;
			margin-top: -0.3125rem;
		}

		strong {
			margin: 0 0.3125rem;
		}
	}

	.course-footer {
		border-top: 0.0625rem solid $border-color;

		&::before {
			background-color: $border-color;
		}

		.price,
		a {
			color: $headings-color;
		}
	}

	&:hover {
		@extend .shadow-sm;

		.sub-title a,
		.course-footer a {
			color: $primary;
		}
	}

	&.card-list {
		display: flex;
		align-items: center;

		.card-media {
			flex: 0 0 46%;
		}

		.card-body {
			padding: 1.25rem 1.875rem;

			@include media-breakpoint-only(xl) {
				padding: 0.9375rem 1.25rem;
			}

			@include media-breakpoint-only(lg) {
				padding: 0.5rem 1.25rem;
			}

			@include media-breakpoint-only(md) {
				padding: 0.5rem 1.875rem;
			}
		}

		@include media-breakpoint-down(md) {
			display: block;
		}

		&:hover {
			@include box-shadow(0.125rem 0.625rem 1.25rem 0.125rem rgba($black, .05));
		}
	}
}

.categories-sec {
	.anim-img {
		z-index: -1;
	}
}

.category-entry {
	border: 0.0625rem solid $border-color;
	@include transition();

	.icon {
		background-color: rgba(96, 96, 255, .10);
		@include transition();

		svg {
			fill: $primary;
			@include transition();

			&:hover {
				fill: $white;
			}
		}
	}

	small {
		color: $body-color;
	}

	&:hover,
	&.active {
		box-shadow: $box-shadow;
		border: 0.0625rem solid transparent;

		.icon {
			background-color: $secondary;
			box-shadow: 0rem 1.25rem 3.125rem 0rem rgba(255, 160, 58, .4);

			svg {
				fill: $white;
			}
		}
	}

	.cat-info {
		margin-left: 1.25rem;

		@include media-breakpoint-between(md, xl) {
			margin-left: 0.9375rem;

			h4 {
				font-size: 17.6px;
			}
		}

		h4 {
			@include media-breakpoint-only(xl) {
				font-size: 22.4px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 17.6px;
			}
		}

	}

}

.course-entry {
	background-color: rgba($color: $white, $alpha: .2);
	border: 0.0625rem solid rgba($color: $border-color, $alpha: .15);
	transform-origin: 50% 100%;
	@include transition();

	.action-wishlist {
		right: 0.625rem;
		top: 0.625rem;
	}

	.sub-title {
		margin: 0.9375rem 0 1.875rem;
		line-height: 1.5;

		a {
			color: $white;
		}

		@include media-breakpoint-only(xl) {
			font-size: 22.4px;
		}

		@include media-breakpoint-only(md) {
			font-size: 20.8px;
		}

		@include media-breakpoint-down(md) {
			font-size: 25.6px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 22.4px;
		}

	}

	.card-body span,
	.author-meta a {
		color: $mute-alt;
		@include transition();

		@include media-breakpoint-down(sm) {
			font-size: 1rem;
		}

		&:hover {
			color: $primary;
		}
	}



	&:hover {
		background-color: $white;
		transform: scale(1.01, 1.01, .6) translate3d(0.32px, -1.6px, 80px);

		.sub-title a {
			color: $primary;
		}

		.card-body span,
		.author-meta a {
			color: $body-color;
		}

		.course-footer {
			border-top: 0.0625rem solid rgba($color: $border-color, $alpha: .85);

			&::before {
				background-color: rgba($color: $border-color, $alpha: .85);
			}

			a,
			.price {
				color: $headings-color;
			}
		}
	}
}

.course-footer {
	border-top: 0.0625rem solid rgba($color: $border-color, $alpha: .15);
	@extend .overly;
	@include transition();

	&::before {
		background-color: rgba($color: $border-color, $alpha: .15);
		width: 0.0625rem;
		height: 1.875rem;
		left: 50%;
		top: 60%;
		transform: translate(-50%, -50%);
		@include transition();
	}

	.price {
		font-size: 1.375rem;
		font-weight: 600;
		color: $mute-alt;
		@include transition();

		del {
			font-size: 0.9375rem;
			margin-left: 0.3125rem;
			font-weight: 300;
		}
	}

	a {
		color: $mute-alt;
		font-weight: 600;

	}

}



// Course Style 2
.course-sec-2 {
	padding-bottom: 13.75rem;

	.grid-item {
		margin-bottom: 2.8125rem;
	}
}

.course-entry-2 {
	.card-media {
		img {
			@include transition(all, 0.5s, linear);
			transition: transform 1.5s cubic-bezier(0, 0, 0.44, 1.18);
		}

		.course-cat {
			left: 0.625rem;
			top: 0.625rem;
			font-size: 0.9375rem;
			padding: 0.625rem 0.8125rem;

			&:hover {
				color: $white;
			}
		}
	}

	.card-body {
		bottom: -2.5rem;
		left: 0;
		max-width: calc(100% - 2.5rem);
		padding: 1.5625rem 1.25rem 0.9375rem;
		overflow: hidden;
		@include transition();

		@include media-breakpoint-only(md) {
			max-width: calc(100% - 1.25rem);
		}

		@include media-breakpoint-down(sm) {
			max-width: calc(100% - 0.625rem);
		}

		.sub-title {
			margin: 0;

			@include media-breakpoint-only(xl) {
				font-size: 19.2px;
			}

			@include media-breakpoint-only(md) {
				font-size: 19.2px;
			}
		}

		.course-meta {
			span:first-child {
				margin-right: 2.5rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 1rem
			}
		}

		.course-hover {
			height: 0;
			@include opacity(0);
			visibility: hidden;
			@include transition();
		}

		.rating-wrap {
			margin-top: 0.9375rem;
			@include transition();
		}

		.course-footer {
			@include transition();

			.price {
				@extend .h5;
				font-family: $font-family-base;
				font-weight: 500;
				margin: 0;

				del {
					margin-right: 0.625rem;
					font-weight: 400;
					font-size: 1.125rem;
					color: $body-color;
				}
			}
		}
	}

	&:hover .card-media img {
		transform: scale(1.06);
	}

	&:hover .card-body {
		.course-hover {
			height: 6.25rem;
			@include opacity(1);
			visibility: visible;
			@include transition();
		}
	}
}

.category-entry2 {
	span.cat-icon {
		width: 11.875rem;
		height: 11.875rem;
		line-height: 11.875rem;
		display: block;
		text-align: center;
		background-color: $white;
		@include transition();
		margin: 0 auto;

		svg {
			fill: $primary;
			@include transition();
		}

	}

	&:hover {
		span.cat-icon {
			background-color: $primary;
			@extend .shadow;

			svg {
				fill: $white;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		span.cat-icon {
			width: 10.9375rem;
			height: 10.9375rem;
			line-height: 10.9375rem;
		}

		h4 {
			font-size: 16px;
		}
	}
}


.course-filters {
	.nice-select {
		min-width: 11.5625rem;
		height: 3.5rem;
		line-height: 3.5rem;
	}

	.result {
		p {
			@include media-breakpoint-down(sm) {
				font-size: 1rem;
			}
		}
	}
}

.courses-sec {
	.course-entry-3 {
		.sub-title {
			@include media-breakpoint-only(xl) {
				font-size: 22.4px;
			}

			@include media-breakpoint-only(lg) {
				font-size: 19.2px;
			}

			@include media-breakpoint-only(md) {
				font-size: 22.4px;
			}
		}
	}
}

.course-nav nav.sticky-top,
.sidebar.sticky-top {
	z-index: 5;

	@include media-breakpoint-down(md) {
		position: static;
	}
}

.course-nav {
	.nav {
		justify-content: space-between;
		margin-bottom: 1.875rem;
		flex-wrap: nowrap;

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
		}

		li .nav-link {
			background: $shade;
			padding: 1.25rem 2.8125rem;
			color: $headings-color;
			font-weight: 500;
			line-height: 1;
			@extend .rounded-1;

			@include media-breakpoint-only(lg) {
				padding: 0.9375rem 1.25rem;
			}

			@include media-breakpoint-only(md) {
				padding: 0.9375rem 1.875rem;
			}

			@include media-breakpoint-down(md) {
				padding: 0.9375rem 0.625rem;
				font-size: 0.9375rem;
				margin-bottom: 0.625rem;
			}

			i {
				font-size: 1.25rem;
				position: relative;
				top: 0.125rem;
				margin-right: 0.3125rem;

				@include media-breakpoint-down(sm) {
					font-size: 0.875rem;
					margin-right: 0.125rem;
				}
			}

			&.active,
			&:hover {
				background-color: $primary;
				color: $white;
			}
		}
	}

	.inner-sec {
		margin-top: 2.5rem;

		h2.display-4 {
			margin-bottom: 1.875rem;
		}

		ul {
			padding: 0.625rem 1.25rem 0;

			li {
				margin-bottom: 0.8125rem;
			}
		}

		p {
			line-height: 170%;
		}
	}

	nav.sticky-top {
		top: 6.25rem;
		background-color: $white;
		padding: 0.625rem 0rem;

		ul {
			margin: 0;
		}
	}
}

.author-card {
	.author-img {
		flex: 0 0 15rem;
		margin-right: 1.875rem;
	}

	.author-text {
		h4 {
			margin: 0;
		}

		p {
			margin: 0.9375rem 0 1.25rem;
		}
	}

}

.course-intro {
	h1 {
		@include media-breakpoint-down(xl) {
			font-size: 36.8px;
		}
	}

	@include media-breakpoint-down(sm) {
		h1 {
			font-size: 28.8px;
		}

		span {
			font-size: 0.9375rem;
		}
	}

	.d-flex span {
		margin-right: 0.9375rem;
	}

	#countdown {
		margin-left: 0;
	}
}

.course-details-sec {
	padding: 5rem 0;

	.course-details-meta {
		padding: 1.875rem 0;

	}

	.course-reviews {
		@include media-breakpoint-down(sm) {
			margin: 0.625rem 0
		}
	}
}

.course-preview {
	@extend .overly;
	@include transition(all, ease, .7s);

	&::before {
		background-color: $dark;
		z-index: 1;
		@include opacity(.4);
	}

}