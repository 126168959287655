/* =====================================
Lesson Style */

.lession-sidebar {
	height: 100%;
}

.lesson-container {
	overflow-x: hidden;
	overflow-y: auto;
	width: 27rem;
	height: 100vh;

	.accordion-2 {
		.accordion-item {
			button.accordion-button {
				font-size: 1.2rem;
				padding: 0.75rem;

				&:after {
					right: 0.625rem;
				}
			}
		}
	}

	.lesson-items {
		li {
			margin-bottom: 0.625rem;
		}
	}
}

.lession-content .nav {
	margin-bottom: 1.875rem;

	li {
		margin-right: 1.25rem;

		button {
			background-color: $shade;
			color: #4c4343;
			padding: 0.625rem 1.875rem;
			font-size: 1.25rem;
			font-weight: 600;
			border-radius: 2.5rem;

			&.active {
				background-color: $primary;
				color: $white;
			}
		}
	}
}

.ratings-details {
	flex: 0 0 70%;
	.d-flex{
		margin-bottom: 1.25rem;
	}
	span{
		img{
			width: 1.5rem;
		}
	}
	.progress {
		flex: 0 0 80%;
		margin-left: 1.25rem;
	}
}