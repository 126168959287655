/* =====================================
Widgets Style */

.sidebar-spacing {
	padding: 2.5rem 3.125rem 0 0;

	@include media-breakpoint-down(xl) {
		padding: 2.5rem 0.625rem 0 0;
	}
}

.widget-title {
	@extend .display-5;
}

.widget {
	margin-bottom: 2.8125rem;

	.widget-inner {
		border: 0.0625rem solid $border-color;
		@include border-radius(0.5rem);
		padding: 1.25rem;
		margin-top: 1.5625rem;
		@include media-breakpoint-only(lg){
			padding: 0.9375rem;
		}
		ul {
			@include list-clear;

			li {
				margin-bottom: 0.5rem;

				a {
					padding: 0.625rem 1.25rem;
					display: block;
					background: $shade;
					@include border-radius(0.3125rem);
					color: $body-color;

					&:hover {
						background: $primary;
						color: $white;
					}
				}

				span.count {
					float: right;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

	}

	&.category-widget {
		ul {
			li {
				a {
					display: flex;
					justify-content: space-between;
					padding: 1.125rem 0.6875rem 0.8125rem 1.25rem;
					align-items: center;

				}
			}
		}
	}
}
.search-widget {
	padding: 1.25rem;
	border: 0.0625rem solid $border-color;
	@include border-radius(0.625rem);
	@include media-breakpoint-only(lg) {
		padding: 0.9375rem;
	}
	input {
		background-color: $shade;
		padding: 0.8125rem 1.875rem;
	}

	.btn {
		right: 0rem;
		padding: 0.875rem 1.375rem;

		i {
			margin-left: 0;
		}
	}
}
.widget_author{
	.footer-social a {
			@include media-breakpoint-only(lg){
				width: 2.75rem;
					height: 2.75rem;
			}
		}
}
.recent-entry {
	.rc-entry {
		margin-bottom: 1.5625rem;
		padding-bottom: 1.25rem;
		@extend .overly;

		&::before {
			background-color: $border-color;
			width: calc(100% - 6.25rem);
			left: auto;
			right: 0;
			height: 0.0625rem;
			top: 100%;
		}

		.course-info {
			flex: 0 0 70%;
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;

			&::before {
				display: none;
			}
		}
	}
}



.instagram-widget {
	.widget-inner {
		.insta-wrap {
			a {
				@extend .overly;
				overflow: hidden;
				@include border-radius(0.3125rem);
				display: block;

				&::before {
					background-color: $black;
					z-index: 1;
					@include transition();
					@include opacity(0);
				}

				span {
					@include opacity(0);
					position: absolute;
					left: 50%;
					top: 30%;
					transform: translate(-50%, -50%);
					z-index: 2;
					font-size: 2.5rem;
					color: $white;
				}

				&:hover {
					&::before {
						@include opacity(.7);
					}

					span {
						@include opacity(1);
						top: 50%;
					}
				}
			}
		}

	}
}

.widget_tag_cloud a {
	display: inline-block;
	font-size: 1rem !important;
	line-height: 1;
	color: $body-color;
	padding: 0.5rem 0.9375rem;
	margin: 2% 1%;
	text-transform: capitalize;
	@include border-radius(0.3125rem);
	background: $shade;

	&:hover {
		background-color: $primary;
		color: $white;
	}
}

.widget-top {
	margin-top: -28.125rem;
	&.sticky-top {
		top: 6.25rem;
	}
	@include media-breakpoint-down(lg) {
		margin-top: 2.8125rem;
	}
}

.price-widget {

	h3 del {
		font-size: 0.9375rem;
		font-weight: 400;
		color: $body-color;
	}

	.price-widget-inner {
		margin-top: 1.875rem;

		ul {
			@include list-clear;

			li {
				font-weight: 500;
				margin-bottom: 0.9375rem;
				border-bottom: 0.0625rem solid #eeeff3;
				padding-bottom: 0.9375rem;
				color: $body-color;
				font-size: 1rem;
				i {
					margin-right: 0.4375rem;
					color: $body-color;
				}

				span {
					float: right;
					font-weight: 400;
				}

				&:last-child {
					border: 0 none;
				}
			}
		}
	}

	.social-share.white a {
		width: 3rem;
		height: 3rem;
		@extend .shadow-xs;
	}
}
.ticket-card{
	h3 span{
		font-size: 0.875rem;
		font-weight: 400;
		color: $mute;
	}
}
.checkbox-custom {
	opacity: 0;
	position: absolute;
}

.checkbox-custom,
.checkbox-custom-label {
	display: inline-block;
	vertical-align: middle;
	margin: 0.3125rem;
	cursor: pointer;
	width: auto;
}

.checkbox-custom-label {
	position: relative;
}

.checkbox-custom+.checkbox-custom-label:before {
	content: '';
	background: $shade;
	display: inline-block;
	vertical-align: middle;
	width: 1.25rem;
	height: 1.25rem;
	line-height: 1.25rem;
	margin-right: 0.625rem;
	text-align: center;
	font-size: 0.9375rem;
	margin-top: -0.3125rem;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
	content: "\f12e";
	font-family: 'feather-icons';
	background: $primary;
	color: #fff;
}

.form-check-input[type=radio] {
	border-radius: 50%;
	padding: 0;
}

.ls-entry {
	text-align: left;
	margin-bottom: 1.25rem;

	.ls-info {
		flex: 0 0 65%;

		h6 {
			font-size: 1.25rem;
		}

		a {
			font-weight: 500;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}
