/*=================================
Typography
*/

body {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@include media-breakpoint-up(xxl) {
    .container {
        max-width: 80.625rem;
    }
}

a {
    color: $headings-color;
    @include transition;
    text-decoration: none;

    &:focus,
    &:hover {
        text-decoration: none;
        @include transition;
        color: $primary;
    }
}



.lead-sm {
    @extend .lead;
    font-size: 1.25rem;
}

.fw-bold {
    font-weight: 600 !important;
}

input,
textarea,
.form-control {
    width: 100%;
    color: $mute;
    padding: 1rem 1.25rem;
    border: 0.0625rem solid $border-color;
    @include border-radius(0.3125rem);

    &.btn {
        cursor: auto;
    }

    &:focus {
        outline: 0 none;
        box-shadow: 0 0 0 0.8px rgba($primary, .2);
    }

    &::placeholder {
        opacity: 1;
        color: $mute;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $mute;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $mute;
    }

}

input[type="checkbox"] {
    padding: 0rem;
}