/* =====================================
CTA Style */

.cta-1 {
	padding: 7.5rem 0;

	&::before {
		background: rgb(4, 2, 16);
		background: linear-gradient(90deg, rgb(4, 2, 16) 0%, rgba(4, 2, 16, 0) 100%);
	}


	@include media-breakpoint-down(xl) {
		.sec-title {
			font-size: 3rem;
		}
	}
	@include media-breakpoint-down(md) {
		.sec-title {
			font-size: 2.5rem;
		}
	}
	@include media-breakpoint-down(sm) {
		.consul-video.text-center {
			margin-top: 2.1875rem;
		}

		.sec-title {
			font-size: 1.875rem;
		}
	}
}

.video-block .cover-video {
	height: 5rem;
	width: 5rem;
	line-height: 5rem;
	text-align: center;
	border-radius: 100%;
	font-size: 1.875rem;
	display: inline-block;
	color: $secondary;
	background: $white;
	@extend .overly;

	&::before {
		width: 110%;
		height: 110%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: transparent;
		border: 3.375rem solid rgba(255, 255, 255, 0.5);
		outline: 0.875rem solid hsla(0, 0%, 100%, .3);
		@include border-radius(50%);
	}
}

.cta-wrap{
	padding: 80px;
	.cta-bg{
		bottom: -10px;
		right: -10px;
		//width: 511px;
		width: 40%;
		height: 362px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right bottom;
	}
	p{
		color: $mute-alt;
		@include media-breakpoint-down(xl) {
			max-width: 390px;
		}
	}
}