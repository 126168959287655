/*=================================
Theme Style
*/
.sec-intro {
    margin-bottom: 4.375rem;

    @include media-breakpoint-down(sm) {
        margin-bottom: 3.125rem;
        overflow: hidden;
    }
}

.sec-padding {
    padding: 7.5rem 0;

    @include media-breakpoint-only(lg) {
        padding: 6.25rem 0;
    }

    @include media-breakpoint-only(md) {
        padding: 5.625rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 5rem 0;
    }
}

.sm-padding {
    padding: 5rem 0;
}

.overly {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $primary;
    }
}


.bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.shadow-1 {
    box-shadow: 0 0.0625rem 0.0625rem 0 rgba(106, 87, 231, 0.05),
        0 0.0625rem 0.125rem 0.0625rem rgba(145, 145, 145, 0.06)
}

.shadow-xs {
    box-shadow: 0 1.25rem 2.5rem -0.8125rem rgba(0, 0, 0, .065) !important;
}

.shadow-sm {
    box-shadow: 0 0.9375rem 3.75rem -0.75rem rgba(0, 0, 0, .08) !important;
}

.shadow {
    box-shadow: 0 1.25rem 3.125rem rgba(96, 69, 255, 0.3) !important;
}

.shadow-alt {
    box-shadow: 0 1.25rem 3.125rem 0 rgba(255, 160, 58, .4);
}


.text-nowrap {
    text-overflow: ellipsis;
    overflow: hidden;
}

.sec-title {
    @extend .display-2;
    line-height: 1.2;
    margin-bottom: 4.375rem;
    font-weight: 700;

    @include media-breakpoint-only(xl) {
        font-size: 48px
    }
}

.sub-title {
    @extend .display-5;
    font-family: $font-family-base;
    font-weight: 600;
}

.color {
    color: $secondary;
    @extend .overly;
    display: inline-block;

    &::before {
        background: url('../images/icons/line-bg.png')no-repeat left bottom / contain;
        max-width: 24.9375rem;
        height: 0.875rem;
        top: 100%;
        width: 115%;
    }
}

.color-info {
    color: $white;
    @extend .overly;

    &::before {
        background: url('../images/icons/line-bg-white.png')no-repeat center bottom / contain;
    }
}

ol.breadcrumb {
    display: block;

    .breadcrumb-item {
        display: inline-block;
        color: $white;
        font-weight: 600;
        font-size: 1rem;
        text-transform: capitalize;

        &::before {
            color: $primary;
            font-style: italic;
        }

        a {
            color: $white;
        }
    }
}


.btn {
    font-weight: 600;
    font-size: 1.125rem;
    padding: 1.0625rem 1.875rem;
    text-transform: capitalize;
    overflow: hidden;
    isolation: isolate;
    transform: translateZ(0);
    @include transition();
    @extend .overly;

    &::before {
        background-color: #1b01b5;
        height: 200%;
        @include border-radius(100%);
        @include opacity(.1);
        transform: scale(.7) translateY(55%);
        transition: all 0.4s cubic-bezier(0, 0, .31, .99);
    }

    &:focus,
    &:visited {
        box-shadow: none;
        color: $white;
    }

    &.btn-sm {
        padding: 0.75rem 1.5625rem 0.625rem;
    }

    &:hover::before {
        transform: scaleX(2) scaleY(1) translateY(-15%);
    }

    &.btn-secondary::before {
        background-color: #874600;
    }
    &.btn-info{
        color: $headings-color;
    }
    &.btn-info::before,
    &.btn-dark::before {
        background-color: #b5b5b5;
    }

    &.btn-shade::before {
        background-color: transparent;
    }
}


.icon {
    height: 3.5rem;
    width: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    display: table;
    flex: 0 0 3.5rem;

    i {
        display: table-cell;
        vertical-align: middle;
    }

}

.icon-sm {
    @extend .icon;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    flex: 0 0 4rem;
}

.icon-lg {
    @extend .icon;
    width: 4.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    flex: 0 0 4.5rem;
}

.icon-xs {
    @extend .icon;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    flex: 0 0 2.5rem;
}

.text-reset {
    &:hover {
        color: $primary !important;
    }
}

.full-screen {
    min-height: 100vh;
    position: relative;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.125rem 0;
}

.ratings {
    position: relative;
    line-height: 1;
    margin-top: -0.1875rem;
    letter-spacing: -0.25rem;

    img {
        width: 1.25rem;

        @include media-breakpoint-down(xl) {
            width: 0.9375rem;
        }
    }
}

.badge-lg {
    @extend .badge;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0.5625rem 1.125rem;
    margin-bottom: 0.9375rem;
}

.coupon-form {
    .btn {
        right: 0;
        top: 0;
    }
}

.video-block {
    max-width: 250px;
    margin-left: auto;
    margin-right: 0;

    @include media-breakpoint-down(sm) {
        margin: 0 auto;
    }

    .waves {
        position: absolute;
        width: 200px;
        height: 200px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 100%;
        top: -60px;
        left: 10%;
        z-index: -1;
        -webkit-animation: waves 3s ease-in-out infinite;
        animation: waves 3s ease-in-out infinite;

        @include media-breakpoint-between(md, xl) {
            left: 4%;
        }

        @include media-breakpoint-only(sm) {
            left: -13%;
        }
    }

    span {
        font-size: 30px;
        font-weight: 500;
        color: #fff
    }
}

@keyframes waves {
    0% {
        -webkit-transform: scale(.2, .2);
        transform: scale(.2, .2);
        opacity: 0;
        -ms-filter: "alpha(opacity=0)";
    }

    50% {
        opacity: .9;
        -ms-filter: "alpha(opacity=90)";
    }

    100% {
        -webkit-transform: scale(.9, .9);
        transform: scale(.9, .9);
        opacity: 0;
        -ms-filter: "alpha(opacity=0)";
    }
}

.video-block-sm {
    @extend .video-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    .waves {
        width: 7.1875rem;
        height: 7.1875rem;
        left: -47%;
        top: -1.75rem;
    }

    a.cover-video {
        width: 3.75rem;
        height: 3.75rem;
        line-height: 3.75rem;

        &::before {
            width: 4.25rem;
            height: 4.25rem;
            outline: 0.3125rem solid rgba($color: $white, $alpha: .3);
            border: 0.3125rem solid rgba($color: $white, $alpha: .6);
        }
    }
}

.anim-img {
    position: absolute;
    left: 1.875rem;
    top: 5rem;
    backface-visibility: hidden;
    animation: jumping 5s ease-in-out 4s infinite alternate;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 4s ease 3s;
    user-select: none;

    &.anim-right {
        left: auto;
        right: 1.875rem;
        animation: jumping2 5s ease-in-out 4s infinite alternate;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

@keyframes jumping {
    0% {
        transform: translateY(0rem) translateX(0) rotate(0) scale(.5);
        opacity: .2
    }

    50% {
        transform: translateY(-3.125rem) translateX(-3.125rem) rotate(15deg) scale(.7);
        opacity: .7
    }

    100% {
        transform: translateY(-4.6875rem) translateX(-4.6875rem) rotate(0) scale(1);
        opacity: 1
    }
}

@keyframes jumping2 {
    0% {
        transform: translateY(-9.375rem) translateX(-0.625rem) rotate(0) scale(1);
        opacity: .2
    }

    50% {
        transform: translateY(-3.125rem) translateX(-0.625rem) rotate(5deg) scale(.8);
        opacity: .5
    }

    100% {
        transform: translateY(0rem) translateX(0) rotate(0) scale(.7)
    }
}

.social-share {
    a {
        background-color: $primary;
        @include border-radius(0.3125rem);
        text-align: center;
        display: inline-table;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        margin-right: 0.625rem;

        i {
            display: table-cell;
            vertical-align: middle;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.white {
        a {
            background-color: $white;
            @extend .shadow-lg;
            color: $primary;

            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
    }
}

.social-share-alt {
    a {
        margin-left: 0.625rem;
        padding: 5px;
        color: $mute;
        @extend .overly;

        &::before {
            width: 0rem;
            background-color: transparent;
            border-right: 0.0625rem solid rgba($color: $white, $alpha: .3);
            height: 1.25rem;
            @include position('vertical');
            left: 1.9375rem;
        }

        &:hover {
            color: $secondary;
        }

    }

    span:last-child a::before {
        opacity: 0;
    }
}

.back-top {
    cursor: pointer;
    position: fixed !important;
    bottom: 2.5rem;
    right: 1.875rem;
    display: block;
    z-index: 99;
    visibility: hidden;
    @include opacity(0);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    @include transition();
    background: $primary;
    color: $white;
    border-radius: 10%;
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
    text-align: center;
    font-size: 1.5rem;

    &.back-top-show {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

select {
    display: none;
}

.nice-select {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: transparent;
    border-radius: 0.3125rem;
    border: none;
    font-size: 1.125rem;

    @include media-breakpoint-down(md) {
        font-size: 1rem;
    }

    &:after {
        border-color: $mute;
        height: 0.5rem;
        width: 0.5rem;
        margin-top: -0.375rem;
        right: 0.875rem;
    }

    .option.selected {
        font-weight: normal;
    }

    .nice-select-dropdown {
        margin-top: 0rem;
    }

    &:active,
    &.open,
    &:focus {
        border-color: $border-color;
    }
}

.nice-select {
    border: 0.0625rem solid $border-color;
    height: 2.9375rem;
    line-height: 2.9375rem;
}

.pager {

    a,
    span {
        width: 3.375rem;
        height: 3.375rem;
        line-height: 3.4375rem;
        display: inline-block;
        border: 0.0625rem solid $border-color;
        font-size: 1.125rem;
        @include border-radius(0.3125rem);
        text-align: center;
        margin-right: 0.3125rem;
        @include transition();

        &:hover {
            background-color: $primary;
            color: $white;
        }

        @include media-breakpoint-down(md) {
            margin: 0.3125rem;
            width: 2.8125rem;
            height: 2.8125rem;
            line-height: 2.8125rem;
        }

        @include media-breakpoint-down(sm) {
            margin: 0.125rem;
        }
    }

    .current {
        background-color: $primary;
        color: $white;
    }
}

.btn-outline-primary {
    color: $headings-color;

    &:focus,&:visited {
        color: $headings-color;
    }
    &:hover {
        color: $white;
    }
}

blockquote {
    border-left: 0.5rem solid #432ebd;
    line-height: 1.8 !important;
    padding: 1.875rem 1.5625rem 1.875rem;
    font-weight: 400;
    font-size: 24px;
    @extend .overly;

    &::before {
        left: auto;
        top: auto;
        right: 1.25rem;
        bottom: 0.625rem;
        height: 3rem;
        width: 4rem;
        background: url('../images/icons/quote-right.png')no-repeat;
    }
}

a#scrollUp {
    right: 2.5rem;
    bottom: 3.75rem;
    font-size: 3.125rem;
    width: 2.5rem;
    height: 4.375rem;
    color: $white;
    @include transition();

    &::before {
        position: absolute;
        content: "\f119";
        font-family: flaticon;
        -webkit-animation: 1.5s ease-in-out 0s normal none infinite running updown;
        animation: 1.5s ease-in-out 0s normal none infinite running updown;
        left: 65%;
        transform: translateX(-50%);
    }

    &::after {
        position: absolute;
        left: -0.3125rem;
        top: -1.375rem;
        width: 3.75rem;
        height: 3.75rem;
        background: $primary;
        content: "";
        border-radius: 50%;
        z-index: -1;
    }
}


.form-group.half-form {
    width: 48%;
    float: left;
    margin-bottom: 1.25rem;

    @include media-breakpoint-down(md) {
        width: 100%;
        float: none;
    }

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    &:nth-child(2n) {
        float: right;
    }
}

.google-map {
    iframe {
        height: 100%;

        @include media-breakpoint-only(md) {
            height: 28.125rem;
        }

        @include media-breakpoint-down(md) {
            height: 21.875rem;
        }
    }
}

/*
    Annimation
*/
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-20deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes updown {
    0% {
        transform: translateY(0rem);
    }

    50% {
        transform: translateY(1.875rem);
    }

    100% {
        transform: translateY(0rem);
    }
}

#preloader.done {
    visibility: hidden;
    opacity: 0;
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f7f7;
    z-index: 999999;
}
.preloader {
    width: 3.125rem;
    height: 3.125rem;
    display: inline-block;
    padding: 0rem;
    text-align: left;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1.5625rem;
    margin-top: -1.5625rem;

    span {
        position: absolute;
        display: inline-block;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 100%;
        background: #dbff00;
        -webkit-animation: preloader 1.3s linear infinite;
        animation: preloader 1.3s linear infinite;

        &:last-child {
            animation-delay: -0.8s;
            -webkit-animation-delay: -0.8s;
        }
    }

}

@keyframes preloader {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes preloader {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}