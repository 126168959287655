/* =====================================
Banner Style */
.banner-content {
	padding: 90px 0;
	.banner-info {
		max-width: 400px;

		@include media-breakpoint-between(md, lg) {
			margin-left: 40px;
		}
	}

	h1 {
		line-height: 1.2;

		@include media-breakpoint-only(xl) {
			font-size: 72px;
		}
	}

	p {
		font-weight: 400;
	}

	.img-meta {
		left: 30px;
		top: 10px;
	}

	@include media-breakpoint-down(xl) {
		h1 {
			font-size: 60px;
			margin-bottom: 30px;
		}

	}

	@include media-breakpoint-down(md) {
		.banner-info {
			max-width: 100%;
		}
	}

	@include media-breakpoint-down(sm) {
		padding: 50px 0;

		h1 {
			font-size: 36px;
			margin-bottom: 20px;
		}
	}
}

.banner-bottom {
	@extend .overly;

	&::before {
		left: auto;
		right: -30px;
		top: -35%;
		width: 270px;
		background: url('../images/icons/stat-bg.png') no-repeat;
		background-position: right center;
		background-size: contain;

	}
}

.banner-bg {
	flex: 0 0 82%;
	overflow: hidden;
}

.banner-stat {
	right: 10%;
	top: 50%;
	transform: translateY(-50%);
	width: 330px;
	position: absolute;
	padding: 50px 40px !important;
	@include media-breakpoint-down(sm) {
		padding: 40px 30px !important;
	}
	&::after {
		background: url('../images/icons/line-shape.png')no-repeat right bottom;
		content: "";
		position: absolute;
		width: 63px;
		height: 38px;
		right: -33px;
		bottom: -35px;
	}

	.single-stat {
		padding-bottom: 19px;
		margin-bottom: 19px;
		@extend .overly;

		&::before {
			width: 62%;
			left: auto;
			right: 0px;
			background: transparent;
			border-bottom: 1px solid rgba(4, 2, 16, .1);
			@include position('vertical')
		}
		p{
			margin-bottom: 0;
		}
		.stat-info .display-3 {
			line-height: 1.2;
			font-weight: 600;
		}

		.icon {
			background-color: #ffad56;
			border: 1px dashed $white;
		}

		&:last-child{
			padding-bottom: 0;
			margin-bottom: 0;
			&::before {
					border: 0 none;
				}
		}
		@include media-breakpoint-down(sm){
			padding-bottom: 11px;
		}
	}

	@include media-breakpoint-down(md) {
		transform: translateY(-50px);
		right: 0;
	}

	@include media-breakpoint-down(sm) {
		background-color: rgba(255, 173, 86, .99) !important;
	}
}

.banner-sec2 {
	.banner2-bg {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 50%;
		height: 100%;
		background-position: right bottom;
		background-size: contain;
		background-repeat: no-repeat;
		z-index: -1;

		@include media-breakpoint-only(lg) {
			width: 55%;
		}

		@include media-breakpoint-between(md, lg) {
			right: -8%;
			width: 70%;
		}

	}

	h1 {
		font-size: 76px;
		margin-bottom: 40px;

		@include media-breakpoint-only(xl) {
			font-size: 66px;
		}

		@include media-breakpoint-only(lg) {
			font-size: 55px;
		}

		@include media-breakpoint-between(md, lg) {
			font-size: 40px;
		}

		@include media-breakpoint-only(sm) {
			font-size: 55px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 40px;
		}
	}


}

.profile-ratings {
	a {
		position: relative;
		margin-left: -10px;
	}

	img {
		width: 74px;
		@include border-radius(50%);
	}

	.s-rating {
		position: relative;
		line-height: 55px;
		flex: 0 0 75px;
		height: 75px;
		text-align: center;
		border: 3px solid $white;

		i {
			position: absolute;
			left: 27px;
			bottom: 16px;
			font-size: 17px;
		}
	}
}

.subscribe-form {
	margin: 40px 0 60px;
	@extend .overly;

	&::before {
		background: url('../images/icons/message-dark.png')no-repeat center left;
		left: 30px;
		z-index: 1;
		top: -1px;
		width: 30px;
	}

	input {
		background-color: $shade;
		@include border-radius(50px);
		padding: 21px 20px 21px 60px;
	}

	.btn {
		position: absolute;
		right: 9px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		padding: 13px 28px;
		box-shadow: none;
	}

	@include media-breakpoint-down(sm) {
		&::before {
			left: 15px;
		}

		.btn {
			padding: 11px 14px;
			font-size: 14px;
		}

		input {
			padding: 15px 10px 15px 40px;
		}
	}
}

.banner-3 {
	.banner-content {
		max-width: 630px;

		h1 {
			font-size: 88px;
			margin-left: -5px;

			@include media-breakpoint-only(xl) {
				font-size: 75px;
			}

			@include media-breakpoint-down(xl) {
				font-size: 65px;
			}

			@include media-breakpoint-only(md) {
				font-size: 55px;
			}

			@include media-breakpoint-down(md) {
				font-size: 45px;
			}
		}

		.banner-meta {
			display: inline-block;
			height: 40px;
			background-color: rgba(255, 173, 86, .1);
			padding-right: 20px;

			span {
				width: 40px;
				line-height: 40px;
				text-align: center;
				display: inline-block;
				margin-right: 10px;
				background-color: $secondary;
			}
		}
	}

	.banner-media {
		.anim-img {
			left: -5%;
			top: -10%;

			&.anim-right {
				left: auto;
				right: -10%;
			}
		}
	}
}