/* =====================================
Contact Style */

.contact-sec {
	background-color: $blue;

	.contact-bg {
		width: 50%;
		left: 0;
		height: 100%;
		top: 0;
		background-repeat: no-repeat;
		@extend .bg-cover;

	}

	.contact-img {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 18%;
	}

	.contact-txt {
		max-width: 24.0625rem;
		margin-left: auto;
		margin-right: 0;

		p {
			color: $mute-alt;
		}
	}

	@include media-breakpoint-down(md) {
		.contact-bg {
			display: none;
		}
		.contact-txt{
			margin: 0 0 40px;
			max-width: 100%;
			.sec-title{
				margin-bottom: 40px;
			}
		}
	}
}

.contact-card-sec {
	.card {
		padding: 2.8125rem;

		span {
			font-size: 1.375rem;
		}

		p {
			margin: 0
		}

		ul {
			@include list-clear;
		}
	}
}

.google-map {
	height: 31.25rem;
	width: 100%;
	margin-bottom: 0;
}

.contact-form {

	input,
	textarea {
		@include border-radius(0.3125rem);
		padding: 1rem 1.875rem;
		@extend .shadow-1;
	}

	input,
	textarea {
		padding-left: 2.6875rem;
	}

	textarea {
		padding-left: 1.25rem;
	}

	.form-group {
		margin-bottom: 1.25rem;
		font-size: 1.0625rem;
		position: relative;

		i {
			position: absolute;
			top: 1.1875rem;
			left: 1.5625rem;
			font-size: 1.125rem;
			color: $mute;
		}
	}

	.nice-select {
		background-color: $white;
		width: 100%;
		height: 3.5625rem;
		line-height: 3.5625rem;
		color: $mute;
	}
}