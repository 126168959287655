/*=================================
Swiper Slider Style
*/

.swiper-pagination {
	text-align: left;
	display: flex;
	align-items: center;

	span {
		width: 0.625rem;
		height: 0.625rem;
		background-color: $white;
		@include opacity(1);

		&.swiper-pagination-bullet-active {
			width: 1.375rem;
			height: 1.375rem;
			border: 0.0625rem solid $black;
			background-color: transparent;
			@extend .overly;

			&::before {
				width: 0.625rem;
				height: 0.625rem;
				background-color: $black;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				@include border-radius(50%);
			}
		}
	}
}

.swiper-button-next,
.swiper-button-prev {
	background: $primary;
	display: inline-table;
	width: 4.125rem;
	height: 4.125rem;
	text-align: center;
	z-index: 9;
	@include border-radius(50%);
	color: $white;
	left: 0;
}

.swiper-button-next {
	left: auto;
	right: 0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	content: "\f114";
	font-family: "feather-icons" !important;
	font-size: 1.5625rem;
	display: table-cell;
	vertical-align: middle;
}

.swiper-button-prev:after {
	content: "\f112";
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	@include opacity(1);
}

.custom-nav {
	>div {
		background-color: $primary;
		@include border-radius(50%);
		font-size: 1.375rem;
	}
}

// Product Slider
.product-slider {
	height: 80%;
	width: 100%;
}

.product-thumbs {
	height: 20%;
	box-sizing: border-box;
	padding: 0.625rem 0;

	.swiper-slide {
		width: 25%;
		height: 100%;
		opacity: 0.9;
		cursor: pointer;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include border-radius(0.625rem);
		}
	}

	.swiper-slide-thumb-active {
		opacity: 1;
	}
}